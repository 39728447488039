import { ValueLabelOption } from "../../types/commonTypes";
import { RadioCmpContainer, RadioOptionsContainer, RadioOptionsLabel } from "../styled/Radio.style";
import RadioItemCmp from "./RadioItemCmp";

type RadioOptionsCmpProps<T> = {
    options: ValueLabelOption<T>[];
    value: ValueLabelOption<T> | undefined;
    onChange: Function
    className?: string;
    otpionsLabel?: string;
    horizontal?: boolean;
    itemClassName?: string;
}

const RadioOptionsCmp = <T extends unknown>(props: RadioOptionsCmpProps<T>) => {
    const { options, value, className, onChange, otpionsLabel, horizontal, itemClassName } = props

    return (
        <>
            <RadioCmpContainer className={className}>
                {otpionsLabel && <RadioOptionsLabel>{otpionsLabel}</RadioOptionsLabel>}
                <RadioOptionsContainer className={className}
                    horizontal={horizontal}>
                    {options?.map((opt: ValueLabelOption<T>) => {
                        return <RadioItemCmp
                            horizontal={horizontal}
                            key={opt.label.toString()}
                            item={opt}
                            onSelect={onChange}
                            isActive={opt.value === value?.value}
                            itemClassName={itemClassName}
                        />
                    })}
                </RadioOptionsContainer>
            </RadioCmpContainer>
        </>
    )
}

export default RadioOptionsCmp