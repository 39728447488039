import styled from 'styled-components'
import { TextDefault } from './Text.style'

export const ValidationContainer = styled.div`
    width: 100%;
    padding: 0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FDEDED;
    border: 1px solid red;
`

export const ValidationItem = styled(TextDefault)`
    font-family: "Medium";
    color: red;
    font-size: 13px;
    text-align: center;
    line-height: 16px; 
    margin: 5px 8px;
`
