import { EventValueEnum } from "../types/communication/communicationDataType";
import { faFileSignature } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlainInfoCard } from "../components/styled/Card.style";
import { Section } from "../components/styled/Containers.style";
import { IconContainer } from "../components/styled/Icon.style";
import { Vspace } from "../components/styled/Separators.style";
import { BigText, TextDefault } from "../components/styled/Text.style";
import { PATHS } from "../routes/CaiExperienceRouter";
import PageContainerCmp from "./PageContainerCmp";
import { useAppSelector } from "../store/hooks";
import { selectCommunicationData } from "../store";
import { isCounterProposal } from "../utils/utils";

  /* istanbul ignore next */
const EndSignatureForwardSuccessPage = () => {
    const communicationData = useAppSelector(selectCommunicationData);
    const { communication } = communicationData
    const _isCounterProposal = isCounterProposal(communication)

    return (
        <>
            <PageContainerCmp
                routePath={PATHS.END_SIGNATURE_FORWARD_SUCCESS}
                removeNextBtn={true}
                isForceLastPathname={true}
                eventsOnLoad={[EventValueEnum.CONFIRMED]}>
                <Section>
                    <PlainInfoCard>
                        <IconContainer>
                            <FontAwesomeIcon icon={faFileSignature} />
                        </IconContainer>
                        <Vspace></Vspace>
                        <Vspace></Vspace>
                        <BigText>Ben fatto, grazie!</BigText>
                    </PlainInfoCard>

                    <Vspace qty={3}></Vspace>
                    <TextDefault intL={true} centered={true}>Entro 24 ore riceverai un messaggio per  firmare la <strong>Constatazione Amichevole digitale.</strong></TextDefault>
                    <Vspace qty={2}></Vspace>
                    {!_isCounterProposal &&
                        <>
                            < TextDefault intL={true} centered={true}>Procediamo contattando la <strong>controparte</strong> per sottoporle la Constatazione Amichevole che, eventualmente, potrebbe firmare a sua volta.</TextDefault>
                            <Vspace qty={4}></Vspace>
                        </>
                    }

                </Section>
            </PageContainerCmp >
        </>
    )
}

export default EndSignatureForwardSuccessPage