import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { selectCommunicationData } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { composeUrl } from "../utils/utils";
import { PATHS } from "../routes/CaiExperienceRouter";
import { saveCommunicationAct } from "../store/communication-data/actions";
import { CommunicationType } from "../types/communication/communicationDataType";
import PageContainerCmp from "./PageContainerCmp";
import { Section } from "../components/styled/Containers.style";
import { PageTitle } from "../components/styled/Text.style";
import { Vspace } from "../components/styled/Separators.style";
import { MarkedLabel, SubLabel } from "../components/styled/Labels.style";
import BaremeSelectorCmp from "../components/bareme/BaremeSelectorCmp";
import { NextButton } from "../components/styled/Button.style";

  /* istanbul ignore next */
const BaremeFreeSelezionaCircostanzaPage = () => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let { token } = useParams();

    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData

    const [freeBaremeId, setFreeBaremeId] = useState<string | undefined>(communication.baremeInfo.idNs)
    const [freeCtpBaremeId, setFreeCtpBaremeId] = useState<string | undefined>(communication.baremeInfo.idCtp)

    const shouldDisableButton = () => {
        const nextStepDisabled = !freeBaremeId && !freeCtpBaremeId;
        return nextStepDisabled;
    }

    const goToNextStepHandler = () => /* istanbul ignore next */{

        const updCommunication = new CommunicationType({
            ...communication
        })
        updCommunication.baremeInfo.idNs = freeBaremeId
        updCommunication.baremeInfo.idCtp = freeCtpBaremeId
        dispatch(saveCommunicationAct({
            data: updCommunication,
            callback: () => navigate(composeUrl(token, PATHS.ZONE_URTO_E_FOTO))
        }))
    }

    return (
        <>
            <PageContainerCmp
                nextStepHandler={goToNextStepHandler}
                routePath={PATHS.FREE_SELEZIONA_CIRCOSTANZA}
                removeNextBtn
            >
                <>
                    <Section>
                        <PageTitle>Seleziona le circostanze dell’incidente</PageTitle>
                        <Vspace></Vspace>
                    </Section>

                    <Section hAlign={'left'}>
                        <MarkedLabel>Qual è la circostanza dell’incidente? (*)</MarkedLabel>
                        <SubLabel>(Compilare almeno una delle seguenti risposte)</SubLabel>
                    </Section>

                    <Section hAlign={'left'}>
                        <BaremeSelectorCmp
                            labelTitle={"Quale manovra stavi eseguendo?"}
                            value={freeBaremeId}
                            OnChange={setFreeBaremeId} />
                    </Section>

                    <Section hAlign={'left'}>
                        <BaremeSelectorCmp
                            labelTitle={"Quale manovra eseguiva la controparte?"}
                            value={freeCtpBaremeId}
                            OnChange={setFreeCtpBaremeId} />
                    </Section>

                    <Section>
                        <Vspace></Vspace>
                        <NextButton
                            disabled={shouldDisableButton()}
                            onClick={goToNextStepHandler}>Procedi</NextButton>
                        <Vspace></Vspace>
                    </Section>
                </>
            </PageContainerCmp>
        </>
    )
}

export default BaremeFreeSelezionaCircostanzaPage