import { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { HCenteredContainer, devices } from './Common.style'

export const InfoCard = styled(HCenteredContainer)`
  position: relative;
  width: 100%;
  background-color: #e4eef8;
  border: 1px solid #3079c9;
  border-radius: 4px;
  text-align: center;
  
  strong {
    font-family: 'Bold';
    font-weight: bold;
  }
  
  padding: 18px 16px;
  @media ${devices.mobileL} {
    padding: 22px 16px;
  }
`

export const PlainInfoCard = styled(HCenteredContainer)`
  position: relative;
  width: 95%;
  background-color: #e4eef8;
  border-radius: 0px;
  text-align: center;
  padding: 1rem;
  
  @media ${devices.mobileL} {
    padding: 2rem;
    display: block;
  }
`

export const AdditionalCard = styled.div`
  position: relative;
  width: 100%;
  padding: 20px 16px;
  background-color: #e4eef8;
  border-radius: 0px;
  text-align: center;
`


interface AdditionalCardTextProps extends HTMLAttributes<HTMLParagraphElement> {
  link?: boolean;
}
export const AdditionalCardText = styled.div<AdditionalCardTextProps>`
    font-family: "Medium";
    color: #3079c9;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    padding: 0;

    ${({ link }: AdditionalCardTextProps) => link && `
      font-family: "Bold";
      text-decoration: underline;
      cursor: pointer;
    `}

`

export const SquaredCard = styled(HCenteredContainer)`
  position: relative;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 0px;
  border: 1px solid #c3c3c3;
  text-align: center;
  padding: 1.8rem 1rem;
`



