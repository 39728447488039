import { LoaderContainer, LoaderItem } from '../styled/Loader.style'

interface LoaderCmpProps {
  className?: string;
}

const LoaderCmp = (props: LoaderCmpProps) => {
  const { className } = props;
  return (
    <LoaderContainer
      className={className}>
        <LoaderItem />
      </LoaderContainer>
  )
}

export default LoaderCmp