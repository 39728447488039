import { EventValueEnum } from "../../types/communication/communicationDataType";
import PageContainerCmp from "../PageContainerCmp";
import EndProcessSuccessCmp from "../../components/end-process-success/EndProcessSuccessCmp";
import { AUTH_CONFIRM_PATHS } from "../../routes/AuthConfirmRouter";

const AuthConfirmEndProcessSuccessPage = () => {

    return (
        <>
            <PageContainerCmp
                routePath={AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_END_PROCESS_SUCCESS}
                removeNextBtn={true}
                isForceLastPathname={true}
                eventsOnLoad={[EventValueEnum.CONFIRMED, EventValueEnum.AUTH_CONFIRM_END_SUCCESS]}>
                <EndProcessSuccessCmp />
            </PageContainerCmp>
        </>
    )
}

export default AuthConfirmEndProcessSuccessPage