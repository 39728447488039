import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { saveCommunicationAct } from "../store/communication-data/actions";
import { EventValueEnum, EventCommType } from "../types/communication/communicationDataType";
import { faClock, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InfoCard, PlainInfoCard } from "../components/styled/Card.style";
import { Section } from "../components/styled/Containers.style";
import { CardIconContainer, IconContainer } from "../components/styled/Icon.style";
import { Vspace } from "../components/styled/Separators.style";
import { BigText, TextDefault, TextDefaultSmall, TextLightB } from "../components/styled/Text.style";
import { PATHS } from "../routes/CaiExperienceRouter";
import PageContainerCmp from "./PageContainerCmp";
import { caiSignatureFeedbackExpireDate } from "../utils/time.utils";
import { selectCommunicationData } from "../store";

const EndProcessPageCtpAccepted = () => {
    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData;

    const dispatch = useAppDispatch();
    useEffect(() => {
        const newEvent = new EventCommType({ value: EventValueEnum.CONFIRMED });
        dispatch(saveCommunicationAct({
            events: [newEvent],
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <PageContainerCmp
                routePath={PATHS.END_PROCESS_PAGE_CTP_ACCEPTED}
                removeNextBtn={true}>
                <Section>
                    <PlainInfoCard>
                        <IconContainer>
                            <FontAwesomeIcon icon={faThumbsUp} />
                        </IconContainer>
                        <Vspace></Vspace>
                        <Vspace></Vspace>
                        <BigText>Il processo è concluso.</BigText>
                    </PlainInfoCard>

                    <Vspace qty={2}></Vspace>
                    <TextDefault centered={true}><strong>La tua pratica di denuncia è andata a buon fine.</strong></TextDefault>
                    <Vspace qty={1}></Vspace>
                    <TextDefaultSmall>Puoi procedere con la chiusura della pagina.</TextDefaultSmall>
                    <Vspace qty={8}></Vspace>

                    <InfoCard>
                        <CardIconContainer>
                            <FontAwesomeIcon icon={faClock} />
                        </CardIconContainer>
                        <TextLightB>
                        Ti comunicheremo l’esito del processo di firma entro il {caiSignatureFeedbackExpireDate(communication.cai_digital_ending_date || communication.creation_date)}
                        </TextLightB>
                    </InfoCard>
                </Section>
            </PageContainerCmp>
        </>
    )
}

export default EndProcessPageCtpAccepted