import { ModalCloseBtn, ModalHeader, ModalTitle } from "../styled/Modal.style";
import 'reactjs-popup/dist/index.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export interface ModalHeaderCmpProps {
  hTitle?: string;
  hCloseButton?: boolean;
  close?: any;
}

export const ModalHeaderCmp = (props: ModalHeaderCmpProps) => {
  const { hTitle, hCloseButton, close } = props

  return (
    <>
      <ModalHeader>
        {hTitle && <ModalTitle>{hTitle}</ModalTitle>}
        {hCloseButton &&
          <ModalCloseBtn
            className="modal-close-btn"
            onClick={close}>
            <FontAwesomeIcon icon={faTimes} />
          </ModalCloseBtn>
        }
      </ModalHeader>
    </>
  )
}
