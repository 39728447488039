import styled from 'styled-components'

export const InputContainer = styled.div `
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;

    &.mb {
       margin-bottom: 1.2rem;
    }
`

export const InputErrorMessage = styled.p`
    display: block;
    font-size: 14px;
    font-family: "Medium";
    color: #e70000;
    margin-top: 5px;
`

export const InputCmpContainer = styled.div `
    position: relative;
    max-width: 1280px;
    width: 100%;
    height: 50px;
    background-color: #f5f5f5;
    border: 1px solid #cecece;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0px;
    box-shadow: none;
`
export const InputIcon = styled.button `
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    svg {
        color: #0f3250;
        font-size: 23px;
        border-right: 1px solid #cecece;
        width: 42px;
    }
`
export const Input = styled.input `
    width: 100%;
    flex: 1;
    height: 100%;
    font-family: "medium";
    font-size: 19px;
    text-align: center;
    padding: 6px 10px;
    background-color: transparent;
    border: none;
    border-radius: 0px;
    box-shadow: none;

    &.big-and-spaced {
        text-align: center;
        font-family: "Bold";
        font-size: 24px;
        color: #0f3250;
        letter-spacing: 8px;
    }

    &.left-align {
        text-align: left;
    }

    &.small {
        font-family: "Medium";
        color: #0f3250;
        font-size: 15px;
    }

    &.ellipsed {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &.pr-big {
        padding-right: 2rem;
    }

    &.upper {
        text-transform: uppercase;
    }

    &.error {
        border: solid red 1px;
    }

    &.error:focus {
        border: solid red 1px;
        outline: none;
    }
`
export const Label = styled.label `
    font-family: "Medium";
    font-size: 15px;
    text-align: left;
    color: #3079c9;
    line-height: 30px;
    margin-bottom: 2px;

    &.marked {
        font-family: "Bold";
        line-height: 22px;
        font-size: 16px;
    }

    &.first-capitalize::first-letter {
        text-transform: capitalize;
    }
`

export const ContactItemContainer = styled.div`
    width: 100%;
    margin-bottom: 1rem;
`