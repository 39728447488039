import { InputContainer, InputErrorMessage, Label } from "../styled/Input.style";
import { AniaItemType } from "../../types/communication/communicationDataType";
import Select from 'react-select';

interface SelectCmpProps {
    defaultValue?: AniaItemType,
    options?: AniaItemType[];
    name: any;
    label?: string;
    setValue: any;
    className?: string;
    labelClassName?: string;
    errors?: any,
    containerClassName?: string,
    placeholder?: string
}

const SelectCmp = (props: SelectCmpProps) => {
    const { options, setValue, name, errors, containerClassName, label, labelClassName, placeholder, defaultValue } = props;

    const baseStyle = {
        boxShadow: "none",
        textAlign: "center",
        fontFamily: "Bold",
        fontSize: "15px",
        color: "#0f3250"
    }
    const customStyle = {
        control: (styles: any) => ({
            ...styles, ...baseStyle,
            height: "50px",
            borderRadius: 0,
            border: "1px solid #cecece",
            backgroundColor: '#f5f5f5',
            position: "relative",
            padding: "2px 30px",
        }),
        dropdownIndicator: (styles: any) => ({
            ...styles, ...baseStyle,
            position: "absolute",
            right: "5px",
        }),
        input: (styles: any) => ({ ...styles, ...baseStyle }),
        placeholder: (styles: any) => ({
            ...styles, ...baseStyle,
            opacity: 0.5
        }),
        singleValue: (styles: any) => ({
            ...styles, ...baseStyle,
            marginTop: "3px"
        })
    };

    return (
        <InputContainer className={containerClassName}>
            {label && <Label className={labelClassName}>{label}</Label>}
            {<InputErrorMessage>{errors && errors[name]?.message?.toString()}</InputErrorMessage>}
            {options && <div>
                <Select
                    options={options}
                    onChange={setValue}
                    value={defaultValue}
                    name={name}
                    placeholder={placeholder}
                    styles={customStyle}
                    components={{ IndicatorSeparator: () => null }}
                    noOptionsMessage={() => 'Nessuna Opzione'}
                />
            </div>}
        </InputContainer>
    )
}

export default SelectCmp