import styled from 'styled-components'
import { HCenteredContainer } from './Common.style';

export const CarUrtoContainer = styled.div`
    position: relative;
    display: table;
`

export const UrtoBkImg = styled.img`
    height: 260px;
    width: 100%;
`

export const UrtoTable = styled.div`
    height: 260px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    flex: 1;
    border-spacing: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
`

interface UrtoTableCellProps {
    isSelected?: boolean;
}

export const UrtoTableCell = styled.div<UrtoTableCellProps>`
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    border: 1px dashed #777;
    padding: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.isSelected ? 'rgba(255, 247, 0, 0.3)' : 'transparent'};

    li{
        width: unset;
        height: unset;
    }
`

export const UrtoFotoModalContainer = styled(HCenteredContainer)`
    min-height: 230px;
`

export const ModalImportantText = styled.p`
    cursor: pointer;
    font-family: "bold";
    font-size: 15px;
    text-transform: uppercase;
    text-decoration: underline;
    color: #0f3250;`
