import Popup from 'reactjs-popup';
import styled from 'styled-components'
import { Button } from './Button.style';
import { CenteredContainer } from './Common.style';

export const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  &-overlay {
}
// use your custom style for ".popup-content"
&-content {
    height: fit-content;
    padding: 0px;
    line-height: 22px;
    overflow: scroll;
    font-family: "Roboto", sans-serif;
    width: 90%;
    border-radius: 0px;
    min-height: 72px;
    border: 4px solid #3079c9;
    border-top: 0;
  }
`;

export const ModalHeader = styled(CenteredContainer)`
    font-family: "Bold";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 42px;
    background-color: #3079c9;
    justify-content: space-between;
    z-index: 2;
`

export const ModalTitle = styled.p`
    text-transform: uppercase;
    color: white;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    padding-left: 20px;
`

export const ModalCloseBtn = styled.button`
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
    color: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    svg {
        font-size: 22px;
    }
`

export const ModalBody = styled(CenteredContainer)`
    padding: 18px;
    padding-top: 50px;
    overflow: scroll;
    max-height: 70vh;
    flex-direction: column;
    justify-content: flex-start;
    &.with-container {
        padding: 0;
        padding-top: 0;
        overflow: none;
        max-height: unset;
    }
`

export const ModalBodyContainer = styled(CenteredContainer)`
    padding: 18px;
    padding-top: 50px;
    overflow: scroll;
    max-height: 70vh;
    flex-direction: column;
    justify-content: flex-start;

    &.has-footer {
        padding-bottom: 86px;
    }
`

export const ModalFooter = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 68px;
    background-color: #fff;
    border-top: 1px solid #cecece;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ModalConfirmButton = styled(Button)`
    font-family: "Medium";
    font-size: 16px;
    background-color: #3079c9;
    font-size: 15px;
    text-transform: uppercase;
    padding: 10px 25px;
    min-width: 125px;
    height: 44px;

    &.with-m {
        margin: 1rem;
    }
`

export const ContactModalInfoTitle = styled(CenteredContainer)`
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #0f3250;

    p {
        font-family: bold;
        font-size: 15px;
        text-align: center;
        z-index: 1;
        padding-right: 0.8rem;
        padding-left: 0.8rem;
        background-color: #fff;
    }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #cecece;
        top: 50%;
        transform: translateY(-50%);
    }
`
