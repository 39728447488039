import { useEffect } from "react";
import FileUploadCmp from "../file/FileUploadCmp";
import { LoadingOverlay } from "../styled/Common.style";
import { Vspace } from "../styled/Separators.style";
import { ModalImportantText, UrtoFotoModalContainer } from "../styled/ZonaUrto.style";
import { tqPageView } from "../../utils/utils";

interface UrtoFotoModalCmpProps {
    isLoading?: boolean
    fileType: string;
    onFileAdded: any
    removeZone: any
}

const UrtoFotoModalCmp = (props: UrtoFotoModalCmpProps) => {
    const { fileType, onFileAdded, removeZone, isLoading } = props
    
    useEffect(() => {
        // Analytics
        tqPageView('cai:foto evento:popup zona')
      }, [])
    return (
        <>
            <UrtoFotoModalContainer>
                <FileUploadCmp
                    onFileAdded={onFileAdded}
                    onFileRemoved={() => { }}
                    className={"foto-urto-upload"}
                    fileType={fileType} 
                    textLabel={"Aggiungi foto"}
                />

                <Vspace qty={1.5}></Vspace>
                <ModalImportantText onClick={removeZone}>ELIMINA ZONA D'URTO</ModalImportantText>
                {isLoading && <LoadingOverlay className="loading-overlay" />}
            </UrtoFotoModalContainer>
        </>
    )
}

export default UrtoFotoModalCmp