import { useEffect, useState } from "react"

const useMinimumLoading = (initState: boolean, minLoadingTime = 700) => {
    const [isLoading, setIsLoading] = useState<boolean>(initState)
    const [minTimeLoading, setMinTimeLoading] = useState<boolean>(false)

    const [timeoutRef, setTimeoutRef] = useState<NodeJS.Timeout | null>(null)


    useEffect(() => {
        setMinTimeLoading(true);
        const _timeoutRef = setTimeout(() => {
            setMinTimeLoading(false);
        }, minLoadingTime);

        setTimeoutRef(_timeoutRef)

        return () => {
            if (timeoutRef) {
                clearTimeout(timeoutRef)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [minLoadingTime])

    return {
        isLoading: minTimeLoading || isLoading,
        setIsLoading
    }
}

export default useMinimumLoading