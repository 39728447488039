import styled from 'styled-components'

export const MarkedLabel = styled.p`
    font-family: "Bold";
    line-height: 22px;
    color: #3079c9;
    font-size: 16px;
`
export const SubLabel = styled.p`
    font-family: "Book";
    font-size: 13px;
    color: #0f3250;
    font-style: italic;
    line-height: 30px;
`
