const LOCAL_STORAGE_ROOT = 'realtimeAppState'

export const getObjProp = (obj: any, pathArr: string[]): any => {
    if (!pathArr || !pathArr.length || !obj) {
        return obj
    }

    const nestedObj = obj[pathArr.shift() || '']
    return getObjProp(nestedObj, pathArr)
}

export function getLocalStorageObj(path: string) {
    let LOappStateJson = getJsonLocalStorageAppState();
    if (LOappStateJson) {
        if (!path.length) {
            return LOappStateJson;
        }
        const pathArr = path.split('.')
        return getObjProp(LOappStateJson, pathArr)
    }
}

export const getJsonLocalStorageAppState = () => {
    let appState: any = localStorage.getItem(LOCAL_STORAGE_ROOT);
    if (appState) {

        let LOappStateJson: any = JSON.parse(appState)
        return LOappStateJson
    }
}

export function setLocalStorage_forwardState(isForwarded: boolean, forceLastPathname?: string | undefined) {
    let LOappStateJson = getJsonLocalStorageAppState();
    if (!LOappStateJson) {
        LOappStateJson = { ...LOappStateJson }
    }
    LOappStateJson = { isForwarded, forceLastPathname: forceLastPathname }
    localStorage.setItem(LOCAL_STORAGE_ROOT, JSON.stringify(LOappStateJson));
}


