import { EventValueEnum } from "../types/communication/communicationDataType";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlainInfoCard } from "../components/styled/Card.style";
import { Section } from "../components/styled/Containers.style";
import { IconContainer } from "../components/styled/Icon.style";
import { Vspace } from "../components/styled/Separators.style";
import { BigText, TextDefault } from "../components/styled/Text.style";
import { PATHS } from "../routes/CaiExperienceRouter";
import { selectCommunicationData } from "../store";
import { useAppSelector } from "../store/hooks";
import PageContainerCmp from "./PageContainerCmp";

const EndSignatureManyVehiclesPage = () => {
    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData

    return (
        <>
            <PageContainerCmp
                routePath={PATHS.END_SIGNATURE_MANY_VEHICLES}
                removeNextBtn={true}
                isForceLastPathname={true}
                eventsOnLoad={[EventValueEnum.CONFIRMED, EventValueEnum.OPEN_CLAIM]}>
                <Section>
                    <PlainInfoCard>
                        <IconContainer>
                            <FontAwesomeIcon icon={faThumbsUp} />
                        </IconContainer>
                        <Vspace></Vspace>
                        <Vspace></Vspace>
                        <BigText>Grazie, abbiamo avviato la tua procedura.</BigText>
                    </PlainInfoCard>
                    <Vspace></Vspace>
                    <TextDefault centered={true}>La tua richiesta di denuncia (<strong>id crash: {communication.crash_id}</strong>) del <strong>{communication.inc_data}</strong> è andata a buon fine.</TextDefault>
                    <Vspace qty={2}></Vspace>
                    <TextDefault centered={true}>Verrai ricontattato dalla Compagnia nel minor tempo possibile.</TextDefault>
                    <Vspace qty={2}></Vspace>
                    <TextDefault centered={true}>Puoi velocizzare le prossime fasi della denuncia rivolgendoti alla tua agenzia al {communication.tel_o_mail}</TextDefault>
                </Section>
            </PageContainerCmp>
        </>
    )
}

export default EndSignatureManyVehiclesPage