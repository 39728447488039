import { PageActionButton } from "../styled/Button.style";
import { ConfirmContainer } from "../styled/Containers.style";

interface ConfirmCancelBtnProps {
    cancelPresent: boolean;
    cancelTxt?: string;
    cancelCallback?: any;
    confirmPresent: boolean;
    confirmTxt?: string;
    confirmCallback?: any;
    className?: string;
}

const ConfirmCancelBtn = (props: ConfirmCancelBtnProps) => {
    const { cancelPresent, cancelTxt, cancelCallback, confirmPresent, confirmTxt,
        confirmCallback, className
    } = props;

    return (
        <>
        <ConfirmContainer className={className}>
            { cancelPresent && <PageActionButton
            onClick={cancelCallback}>{cancelTxt || 'ANNULLA'}</PageActionButton>}
            { confirmPresent && <PageActionButton
            onClick={confirmCallback}>{confirmTxt || 'CONFERMA'}</PageActionButton>}
        </ConfirmContainer>
        </>
            
    )
}

export default ConfirmCancelBtn;