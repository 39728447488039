import {
  createBrowserRouter
} from "react-router-dom";
import './App.scss';
import AppWrapper from "./pages/AppWrapper";
import ErrorPage from "./pages/ErrorPage";
import { ROUTES } from "./routes/CaiExperienceRouter";
import { AUTH_CONFIRM_BASE_ROUTH, AUTH_CONFIRM_ROUTES } from "./routes/AuthConfirmRouter";

export const router = createBrowserRouter([
  {
    path: `/${AUTH_CONFIRM_BASE_ROUTH}/:token`,
    element: <AppWrapper />,
    children: AUTH_CONFIRM_ROUTES
  },
  {
    path: ":token",
    element: <AppWrapper />,
    children: ROUTES
  }, {
    path: "*",
    element: <ErrorPage />
  }
]);
