import axios from 'axios'
import { useParams } from 'react-router-dom';
import { FileActionFunction, FileItemType } from '../../types/communication/communicationDataType';
import { FileList, FileListTitle } from '../styled/File.style';
import { Vspace } from '../styled/Separators.style';
import FileItemCmp from './FileItemCmp';

interface FileListCmpProps {
    files: FileItemType[];
    onFileRemoved: FileActionFunction;
    className?: string;
    children?: JSX.Element
}
const FileListCmp = (props: FileListCmpProps) => {
    let { token } = useParams();
    const { files, onFileRemoved, className, children } = props

    const deleteFileHandler = (_file?: FileItemType) => {
        if (!_file) {
            return _file
        }
        let requestURL = `${window.REACT_APP_VTV_API_ENDPOINT}/api/realtime/communication/${token}/attachment/${_file._id}`;
        axios.delete(requestURL)
            .then((res) => onFileRemoved(_file))
            .catch((err) => console.error(err));
    }

    return (
        <>
            {files.length ? <FileListTitle>Le tue foto</FileListTitle> : null}
            <Vspace></Vspace>

            <FileList className={className}>
                {children}
                {
                    files &&
                    files.map((f, ind) => (<FileItemCmp
                        fullPageCanDelete={true}
                        key={`${f.name}_${ind}`}
                        file={f}
                        deleteFile={(f?: FileItemType) => deleteFileHandler(f)} />))
                }
            </FileList>
        </>
    )
}

export default FileListCmp
