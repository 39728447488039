
import { useNavigate, useParams } from "react-router-dom";
import { selectCommunicationData } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { composeUrl } from "../utils/utils";
import { PATHS } from "../routes/CaiExperienceRouter";
import { saveCommunicationAct } from "../store/communication-data/actions";
import { CommunicationType, EventCommType, EventValueEnum } from "../types/communication/communicationDataType";
import PageContainerCmp from "./PageContainerCmp";
import { MapAddressFinder } from "../components/map/MapAddressFinder";
import { AddressType } from "../types/commonTypes";

const ModificaLuogoIncidentePage = () => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let { token } = useParams();

    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData

    const onModifyAddress = (addressObj: AddressType) => {
        const newEvent = new EventCommType({ value: EventValueEnum.POSITION_MODIFIED })
        dispatch(saveCommunicationAct({
            data: new CommunicationType({
                ...communication,
                inc_luogo: addressObj.luogo,
                inc_latitude: addressObj.latitude,
                inc_longitude: addressObj.longitude,
            }),
            events: [newEvent],
            callback: () => navigate(composeUrl(token, PATHS.ORA_E_LUOGO))
        }))
    }

    const onCancelHandler = () => {
        navigate(composeUrl(token, PATHS.ORA_E_LUOGO))
    }

    return (
        <>
            <PageContainerCmp
                routePath={PATHS.MODIFICA_LUOGO_INCIDENTE}
                removeNextBtn={true}
            >
                <MapAddressFinder
                    fixedMarkerDropMap={true}
                    withSearch={true}
                    maxDistance={1500}
                    hasProximityWarning={true}
                    displayOnly={false}
                    classes={"modify-position-map"}
                    manageBtnPresent={true}
                    onConfirmHandler={onModifyAddress}
                    onCancelHandler={onCancelHandler}
                />
            </PageContainerCmp>
        </>

    )
}

export default ModificaLuogoIncidentePage