import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Section } from "../components/styled/Containers.style";
import { selectCommunicationData } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { PageTitle, TextDefault } from "../components/styled/Text.style";
import { Vspace } from '../components/styled/Separators.style'
import { PATHS } from "../routes/CaiExperienceRouter";
import { saveCommunicationAct } from "../store/communication-data/actions";
import { CommunicationType, ConducenteInfo, RimorchioInfo } from "../types/communication/communicationDataType";
import PageContainerCmp from "./PageContainerCmp";
import CaiConducenteCmp from "../components/driver/CaiConducenteCmp";
import CaiRimorchioCmp from "../components/rimorchio/CaiRimorchioCmp";
import { BoolStringEnum } from "../types/commonTypes";
import { composeUrl } from "../utils/utils";
import { CaiWizardPointBadge } from "../components/styled/page-custom/Cai.style";
import { RealtimeModal } from "../components/modal/RealtimeModal";
import { CaiInfoModal } from "../components/cai-info-modal/CaiInfoModal";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { InfoIcon } from "../components/styled/Icon.style";

const CaiFillingConducentePage = () => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let { token } = useParams();

    const communicationData = useAppSelector(selectCommunicationData)
    const { communication, caiValidationInfo } = communicationData

    const [conducenteInfo, setConducenteInfo] = useState<ConducenteInfo>(communication.conducenteInfo)
    const [rimorchioInfo, setRimorchioInfo] = useState<RimorchioInfo>(communication.rimorchioInfo)
    const [openCaiModal, setOpenCaiModal] = useState(false)

    const isCaiStepValid = (
        (conducenteInfo?.alsoOwner === BoolStringEnum.Y && caiValidationInfo.y_contactInfo === true) ||
        (conducenteInfo?.alsoOwner === BoolStringEnum.N && caiValidationInfo.n_contactInfo === true)
    )

    const goToNextStepHandler = () => {
        const updCommunication = new CommunicationType({
            ...communication,
            conducenteInfo: new ConducenteInfo(conducenteInfo),
            rimorchioInfo: new RimorchioInfo(rimorchioInfo)
        })
        dispatch(saveCommunicationAct({
            data: updCommunication,
            callback: () => navigate(composeUrl(token, PATHS.CAI_FERITI_E_TESTIMONI))
        })
        )
    }

    return (
        <>
            <RealtimeModal
                modalOpened={openCaiModal}
                hTitle="Constatazione amichevole"
                hCloseButton={true}
                /* istanbul ignore next */
                onCloseHandler={() => setOpenCaiModal(false)}>
                <CaiInfoModal />
            </RealtimeModal>

            <PageContainerCmp
                nextStepHandler={goToNextStepHandler}
                routePath={PATHS.CAI_FILLING_CONDUCENTE}
                nextDisabled={!isCaiStepValid}
                hasPaddingX={true}
            >
                <>
                    <Section>
                        <PageTitle>Constatazione amichevole <InfoIcon icon={faCircleInfo} onClick={() => setOpenCaiModal(true)} /></PageTitle>
                        <CaiWizardPointBadge>1/3</CaiWizardPointBadge>
                        <Vspace></Vspace>
                        <TextDefault centered sm>Ci siamo quasi. Prima di procedere ti chiediamo di indicare alcuni dettagli ulteriori.</TextDefault>
                        <Vspace></Vspace>
                        <TextDefault centered xsm>Alcuni campi sono obbligatori (*).</TextDefault>
                    </Section>


                    <CaiConducenteCmp
                        initValues={conducenteInfo}
                        onChange={(newInfo) => setConducenteInfo(currInfo => ({
                            ...currInfo,
                            ...newInfo
                        }))}
                    />

                    <CaiRimorchioCmp
                        initValues={rimorchioInfo}
                        onChange={(newInfo) => setRimorchioInfo(currInfo => ({
                            ...currInfo,
                            ...newInfo
                        }))}
                    />
                </>
            </PageContainerCmp>
        </>

    )
}

export default CaiFillingConducentePage