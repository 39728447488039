import { useNavigate, useParams } from "react-router-dom";
import { Section } from "../../components/styled/Containers.style";
import { PageTitle, TextDefault } from "../../components/styled/Text.style";
import { Vspace } from '../../components/styled/Separators.style'
import PageContainerCmp from "../PageContainerCmp";
import { composeUrl } from "../../utils/utils";
import { NextButton, SecondChoiseButton } from "../../components/styled/Button.style";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useState } from "react";
import { CaiInfoModal } from "../../components/cai-info-modal/CaiInfoModal";
import { RealtimeModal } from "../../components/modal/RealtimeModal";
import { selectCommunicationData } from "../../store";
import { AUTH_CONFIRM_BASE_ROUTH, AUTH_CONFIRM_PATHS } from "../../routes/AuthConfirmRouter";
import { CommunicationType, EventCommType, EventValueEnum, FileItemType } from "../../types/communication/communicationDataType";
import FilesUploadListCmp from "../../components/file/FilesUploadListCmp";
import { invokeSignatureAct, saveCommunicationAct } from "../../store/communication-data/actions";
import LoaderCmp from "../../components/loader/LoaderCmp";
import AuthConfirmCancelProcedureModal from "../../components/cancel-authorize-confirm-claim-modal/AuthConfirmCancelProcedureModal";

const AuthConfirmUploadDocPage = () => {
    const dispatch = useAppDispatch();
    const communicationData = useAppSelector(selectCommunicationData)
    const { invokeSignatureMetadata, communication } = communicationData
    let navigate = useNavigate();
    let { token } = useParams();

    const [openCaiModal, setOpenCaiModal] = useState(false)
    const [cancelModalOpened, setCancelModalOpened] = useState<boolean>(false)
    const [authConfirmDocsList, setAuthConfirmDocsList] = useState<FileItemType[]>(communication.authConfirmDocsList)

    const shouldDisableButton = () => {
        return !(authConfirmDocsList?.length);
    }

    const goToNextStepHandler = () => {
        /* istanbul ignore next */
        const nextPath = composeUrl(token, AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_END_PROCESS_SUCCESS, AUTH_CONFIRM_BASE_ROUTH)
        const newEvent = new EventCommType({ value: EventValueEnum.AUTH_CONFIRM_STEPS_COMPLETED })
        /* istanbul ignore next */
        const data = new CommunicationType({
            ...communication,
            authConfirmDocsList
        })
        /* istanbul ignore next */
        dispatch(saveCommunicationAct({
            data,
            events: [newEvent]
        }))
        /* istanbul ignore next */
        dispatch(invokeSignatureAct({
            data: null,
            callback: () => navigate(nextPath)
        }))
    }

    return (
        <>

            <AuthConfirmCancelProcedureModal
                modalOpened={cancelModalOpened}
                onCloseHandler={() => setCancelModalOpened(false)} />

            <RealtimeModal
                modalOpened={openCaiModal}
                hTitle="Constatazione amichevole"
                hCloseButton={true}
                onCloseHandler={() => setOpenCaiModal(false)}>
                <CaiInfoModal />
            </RealtimeModal>

            <PageContainerCmp
                nextStepHandler={goToNextStepHandler}
                routePath={AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_UPLOAD_DOC}
                removeNextBtn
            >
                <>
                    {invokeSignatureMetadata?.isLoading && <LoaderCmp className="cover cai-preview" />}
                    <Section>
                        <PageTitle>Documento di riconoscimento</PageTitle>
                        <Vspace></Vspace>
                        <TextDefault centered sm>Infine ti chiediamo di caricare una foto di un tuo documento di riconoscimento in corso di validità, <strong>preferibilmente la patente</strong>.</TextDefault>
                    </Section>

                    <Section>
                        <FilesUploadListCmp
                            files={authConfirmDocsList}
                            setFiles={setAuthConfirmDocsList}
                            fileType={"AUTH-CONFIRM-DOCS"}
                            arrayDbPropSync={"authConfirmDocsList"}
                            textLabel={"Carica documento"}
                        />
                    </Section>

                    <Section>
                        <Vspace></Vspace>
                        <NextButton
                            disabled={shouldDisableButton()}
                            onClick={goToNextStepHandler}>Procedi</NextButton>
                        <Vspace></Vspace>
                        <SecondChoiseButton onClick={() => setCancelModalOpened(true)}>Non posso caricare il documento</SecondChoiseButton>
                    </Section>
                </>
            </PageContainerCmp>
        </>

    )
}

export default AuthConfirmUploadDocPage