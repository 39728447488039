import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import { InteractionType } from '../../types/communication/communicationDataType'
import { FileFullPageImage, FileFullPageImageContainer, FullPageCloseBtn, FullPageDeleteBtn } from '../styled/File.style';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { tqPageView } from '../../utils/utils';

interface FileImgFullPageCmpProps {
    file: InteractionType;
    close: any
    deleteHandler?: any
}

const FileImgFullPageCmp = ({ file, close, deleteHandler }: FileImgFullPageCmpProps) => {
    let { token } = useParams();
    let previewURL = `${window.REACT_APP_VTV_API_ENDPOINT}/api/realtime/communication/${token}/download-attachment/${file._id}`;

    useEffect(() => {
        // Analytics
        tqPageView('cai:foto evento:foto')
    }, [])

    return (
        <>
            <FileFullPageImageContainer>
                {deleteHandler && <FullPageDeleteBtn onClick={deleteHandler} >
                    <FontAwesomeIcon icon={faTrash} />
                </FullPageDeleteBtn>}

                <FileFullPageImage src={previewURL} alt="Foto contesto full page" />
                <FullPageCloseBtn onClick={close} >
                    <FontAwesomeIcon icon={faTimes} />
                </FullPageCloseBtn>
            </FileFullPageImageContainer>
        </>
    )
}

export default FileImgFullPageCmp
