
import { useNavigate, useParams } from "react-router-dom";
import { Section } from "../components/styled/Containers.style";
import { selectCommunicationData } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { PageTitle } from "../components/styled/Text.style";
import { PATHS } from "../routes/CaiExperienceRouter";
import PageContainerCmp from "./PageContainerCmp";
import RadioOptionsCmp from "../components/radio/RadioOptionsCmp";
import { getValueLabelOptions, ValueLabelOption } from "../types/commonTypes";
import { BaremeResponses, CaiAnswersEnum, CAI_QUESTIONS, Q2_AnswersEnum } from "../types/communication/caiType";
import { useEffect, useState } from "react";
import { composeUrl } from "../utils/utils";
import { saveCommunicationAct } from "../store/communication-data/actions";
import { BaremeUserChoiseEnum, CommunicationType } from "../types/communication/communicationDataType";

const Q2Page = () => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let { token } = useParams();

    const currQ = CAI_QUESTIONS[CaiAnswersEnum.Q2]

    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData

    const [q2Resp, setQ2Resp] = useState<Q2_AnswersEnum | undefined>()
    useEffect(() => {
        setQ2Resp(communication.baremeResponses.Q2_Answer)
    }, [communication.baremeResponses.Q2_Answer])

    const goToNextStepHandler = () => {
        const updtBaremeResp = new BaremeResponses({
            Q1_Answer: communication.baremeResponses.Q1_Answer,
            Q2_Answer: q2Resp
        })

        let _pathname = composeUrl(token, PATHS.Q3_container)
        if (q2Resp === Q2_AnswersEnum.NESSUNA_CTP) {
            // go to dynamic image
            _pathname = composeUrl(token, PATHS.FREE_INCIDENT_DESCRIPTION)
        }

        dispatch(saveCommunicationAct({
            data: new CommunicationType({
                ...communication,
                baremeUserChoise: BaremeUserChoiseEnum.BAREME_QUESTIONS_FILLED_ACCEPTED,
                baremeResponses: updtBaremeResp
            }),
            callback: () => navigate(_pathname),
            computeBareme: true
        })
        )
    }
    return (
        <>
            <PageContainerCmp
                nextStepHandler={goToNextStepHandler}
                routePath={PATHS.Q2}
                nextDisabled={!q2Resp}
            >
                <>
                    <Section>
                        <PageTitle>{currQ.title}</PageTitle>
                    </Section>
                    <Section>
                        <RadioOptionsCmp
                            options={currQ.possibleAnswers}
                            value={getValueLabelOptions(currQ.possibleAnswers, q2Resp)}
                            className={'bareme-q2'}
                            onChange={(newChoise: ValueLabelOption<Q2_AnswersEnum>) => setQ2Resp(newChoise.value)}
                        />
                    </Section>

                </>
            </PageContainerCmp>
        </>
    )
}

export default Q2Page