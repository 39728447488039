import styled from 'styled-components'
import { devices } from './Common.style';

interface VspaceProps {
  qty?: number;
}

export const Vspace = styled.div<VspaceProps>`
  position: relative;
  
    margin-top: ${props => ((props.qty) || 1) / 1.3}rem;
    @media ${devices.mobileL} {
      margin-top: ${props => props.qty || 1}rem;
  }
`

export const Vseparator = styled.div`
  position: relative;
  width: 100vw;
  margin: 1rem -1rem;
  height: 1px;
  background-color: rgb(195, 195, 195);
`

export const VPercentageSeparator = styled(Vseparator)`
  width: 100%;
  margin: 1rem 0;
  background-color: #e6e6e6;
`

export const SeparatorLight = styled.div`
  position: relative;
  width: 85px;
  height: 1px;
  background-color: #3079c961;
  margin: 0.8rem auto;
`

export const SeparatorStandard = styled.div`
  background-color: #3079c961;
  position: relative;
  margin: auto;
  height: 1.5px;
  width: 90%;
`