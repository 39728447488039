import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"
import { v4 as uuidv4 } from 'uuid';
import { ContactModalContentProps } from "../../types/commonTypes"
import { OtherContactInfo, ContactInfo } from "../../types/communication/communicationDataType"
import ContactInfoCmp from "../contact/ContactInfoCmp"
import { DivW } from "../styled/Containers.style";
import { ContactModalInfoTitle, ModalConfirmButton } from "../styled/Modal.style"
import { ModalContactActionBtn } from "../styled/page-custom/Cai.style";
import { contactCompleteInfoSchema } from "../../types/communication/communicationDataType";

const OtherContactModalCmp = (props: ContactModalContentProps<Partial<OtherContactInfo>>) => {
    const { onConfirmAndCloseHandler, initValues, contactListPropItems, contactLabel } = props

    const [contactList, setOtherPeopleList] = useState<ContactInfo[]>(initValues || [])

    const addNewFerito = () => {
        setOtherPeopleList((currList: ContactInfo[]) => [
            ...currList,
            new ContactInfo({ _id: uuidv4() })
        ])
    }

    const removeLastFerito = () => {
        setOtherPeopleList((currList: ContactInfo[]) => {
            const newList = [...currList]
            newList.pop()
            return newList;
        })
    }

    useEffect(() => {
        if (contactList?.length === 0) {
            addNewFerito()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {
                contactList?.map((fItem: ContactInfo, index: number) => (
                    <DivW key={fItem._id}>
                        <ContactModalInfoTitle><p>{contactLabel} ({index + 1})</p></ContactModalInfoTitle>
                        <ContactInfoCmp
                            initValues={fItem}
                            contactItems={contactListPropItems}
                            onContactChange={(newContactInfo: Partial<ContactInfo>) =>
                                setOtherPeopleList((currList: ContactInfo[]) => {
                                    const newList: ContactInfo[] = currList.map((f: ContactInfo) => {
                                        if ((f._id === newContactInfo._id)) {
                                            return new ContactInfo(newContactInfo)
                                        } else {
                                            return f;
                                        }
                                    })
                                    return newList
                                })
                            }
                            validationSchema={contactCompleteInfoSchema}
                        />
                    </DivW>
                ))
            }

            <ModalContactActionBtn
            onClick={removeLastFerito}>
                <FontAwesomeIcon icon={faTrash} />
                Elimina questo elemento
            </ModalContactActionBtn>
            <ModalContactActionBtn
            onClick={addNewFerito}> + Aggiungi un altro elemento</ModalContactActionBtn>

            <ModalConfirmButton
                className="with-m"
                onClick={() => onConfirmAndCloseHandler &&
                    onConfirmAndCloseHandler({ contactList: contactList })}
            > Conferma</ModalConfirmButton>

        </>
    )
}

export default OtherContactModalCmp