import { useNavigate, useParams } from "react-router-dom";
import { PATHS } from "../routes/CaiExperienceRouter";
import { Section } from "../components/styled/Containers.style";
import { PageTitle, TextDefault, TextLightB } from "../components/styled/Text.style";
import { Vspace } from '../components/styled/Separators.style'
import PageContainerCmp from "./PageContainerCmp";
import { composeUrl } from "../utils/utils";
import { NextButton, SecondChoiseButton } from "../components/styled/Button.style";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { useState } from "react";
import { selectCommunicationData } from "../store";
import { CommunicationType, EventCommType, EventValueEnum, FileItemType } from "../types/communication/communicationDataType";
import FilesUploadListCmp from "../components/file/FilesUploadListCmp";
import { saveCommunicationAct } from "../store/communication-data/actions";
import { CardIconContainer, InfoIcon } from "../components/styled/Icon.style";
import { RealtimeModal } from "../components/modal/RealtimeModal";
import { CaiInfoModal } from "../components/cai-info-modal/CaiInfoModal";
import { faCircleInfo, faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { InfoCard } from "../components/styled/Card.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* istanbul ignore next */
const CaiPaperUploadPage = () => {
    const dispatch = useAppDispatch();
    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData
    let navigate = useNavigate();
    let { token } = useParams();

    const [caiPaperList, setCaiPaperList] = useState<FileItemType[]>(communication.caiPaperList)

    const [openCaiModal, setOpenCaiModal] = useState(false)

    const shouldDisableButton = () => {
        return !(caiPaperList?.length);
    }

    const goToNextStepHandler = () => {
        const nextPath = composeUrl(token, PATHS.END_PROCESS_CAI_PAPER_PAGE)
        const newEvent = new EventCommType({ value: EventValueEnum.CAI_PAPER_UPLOADED })
        const toCaiPaperEvent = new EventCommType({ value: EventValueEnum.FREE_CTP_TOWARD_CAI_PAPER })
        const data = new CommunicationType({
            ...communication,
            caiPaperList
        })
        /* istanbul ignore next */
        dispatch(saveCommunicationAct({
            data,
            events: [newEvent, toCaiPaperEvent],
            callback: () => navigate(nextPath)
        }))
    }

    const cancelProcessHandler = () => {
        /* istanbul ignore next */
        const nextPath = composeUrl(token, PATHS.PROCEDURE_CANCELLED)
        /* istanbul ignore next */
        const newEvent = new EventCommType({ value: EventValueEnum.PROCEDURE_CANCELED })
        const caiPaperEvent = new EventCommType({ value: EventValueEnum.FREE_CTP_TOWARD_CAI_PAPER })
        /* istanbul ignore next */
        dispatch(saveCommunicationAct({
            events: [newEvent, caiPaperEvent],
        }))
        // Go in any case to the Cancel path
        navigate(nextPath)
    }

    return (
        <>

            <RealtimeModal
                modalOpened={openCaiModal}
                hTitle="Constatazione amichevole"
                hCloseButton={true}
                onCloseHandler={() => setOpenCaiModal(false)}>
                <CaiInfoModal />
            </RealtimeModal>


            <PageContainerCmp
                nextStepHandler={goToNextStepHandler}
                routePath={PATHS.CAI_PAPER}
                removeNextBtn
            >
                <>
                    <Section>
                        <PageTitle>Constatazione amichevole <InfoIcon data-testid="info-icon" icon={faCircleInfo} onClick={() => setOpenCaiModal(true)} /></PageTitle>
                        <Vspace></Vspace>
                        <TextDefault centered sm>Dopo aver <strong>compilato</strong> la constatazione amichevole scatta e carica qui la foto.</TextDefault>
                    </Section>

                    <Section>
                        <FilesUploadListCmp
                            files={caiPaperList}
                            setFiles={setCaiPaperList}
                            fileType={"CAI-PAPER-DOCS"}
                            arrayDbPropSync={"caiPaperList"}
                            textLabel={"Carica"}
                        />
                    </Section>

                    <Vspace qty={2} />

                    <Section>
                        <InfoCard>
                            <CardIconContainer>
                                <FontAwesomeIcon icon={faUserFriends} />
                            </CardIconContainer>
                            <TextLightB>Puoi procedere <strong>anche se non trovi un accordo</strong> con la controparte: è sufficiente la tua firma.</TextLightB>
                        </InfoCard>
                    </Section>

                    <Section>
                        <NextButton
                            disabled={shouldDisableButton()}
                            onClick={goToNextStepHandler}>Procedi</NextButton>
                        <Vspace></Vspace>
                        <SecondChoiseButton onClick={cancelProcessHandler}>Non posso caricare il documento</SecondChoiseButton>
                    </Section>


                </>
            </PageContainerCmp>
        </>

    )
}

export default CaiPaperUploadPage