
import { useNavigate, useParams } from "react-router-dom";
import { Section } from "../components/styled/Containers.style";
import { selectCommunicationData } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { PageTitle } from "../components/styled/Text.style";
import { useEffect, useState } from "react";
import { PATHS } from "../routes/CaiExperienceRouter";
import { saveCommunicationAct } from "../store/communication-data/actions";
import { CommunicationType } from "../types/communication/communicationDataType";
import PageContainerCmp from "./PageContainerCmp";
import RadioOptionsCmp from "../components/radio/RadioOptionsCmp";
import { ValueLabelOption } from "../types/commonTypes";
import { composeUrl } from "../utils/utils";

const noSinistroOptions: ValueLabelOption<string>[] = [{
    value: 'braking',
    label: 'Frenata brusca'
}, {
    value: 'hole',
    label: 'Buca'
}, {
    value: 'bump',
    label: 'Dosso'
}, {
    value: 'other',
    label: 'Altro'
}, {
    value: 'nothing',
    label: 'Nulla'
}]

const NoSinistroPage = () => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let { token } = useParams();

    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData

    const [noClaimWhatHappened, setNoClaimWhatHappened] = useState<ValueLabelOption<string> | undefined>()
    useEffect(() => {
        setNoClaimWhatHappened(communication.noClaimWhatHappened)
    }, [communication.noClaimWhatHappened])

    const goToNextStepHandler = () => {
        const _pathname = composeUrl(token, PATHS.END_SORRY_TO_DISTURB)
        
        dispatch(saveCommunicationAct({
            data: new CommunicationType({
                ...communication,
                noClaimWhatHappened,
            }),
            callback: () => navigate(_pathname)
        })
        )
    }
    return (
        <>
            <PageContainerCmp
                nextStepHandler={goToNextStepHandler}
                routePath={PATHS.NO_INCIDENT_PAGE}
                nextDisabled={!noClaimWhatHappened}
                nextTitle="Conferma"
            >
                <>
                    <Section>
                        <PageTitle>Puoi indicarci cosa è successo?</PageTitle>
                    </Section>
                    <Section>
                        <RadioOptionsCmp
                            options={noSinistroOptions}
                            value={noClaimWhatHappened}
                            className={'no-sinistro-choises'}
                            onChange={(newChoise: ValueLabelOption<string>) => setNoClaimWhatHappened(newChoise)}
                        />
                    </Section>

                </>
            </PageContainerCmp>
        </>
    )
}

export default NoSinistroPage