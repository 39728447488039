import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare, faClock, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Section } from "../components/styled/Containers.style";
import { selectCommunicationData } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { NextButton, SecondChoiseButton, ShareButton } from './../components/styled/Button.style'
import { PageTitle, TextLightB } from "../components/styled/Text.style";
import { ObliqueSectionWithCta } from "../components/styled/page-custom/WelcomePage.style";
import { Vspace } from '../components/styled/Separators.style'
import { List, ListItem } from "../components/styled/List.style";
import { InfoCard } from "../components/styled/Card.style";
import { CardIconContainer, InfoIcon } from "../components/styled/Icon.style";
import { caiExpireLocalDate, localTimeHour } from "../utils/time.utils";
import { composeUrl, tqPageView } from "../utils/utils";
import { PATHS } from "../routes/CaiExperienceRouter";
import PageContainerCmp from "./PageContainerCmp";
import { ShareCommunicationModal } from "../components/share-communication/ShareCommunicationModal";
import { RealtimeModal } from "../components/modal/RealtimeModal";
import { CaiInfoModal } from "../components/cai-info-modal/CaiInfoModal";
import { saveCommunicationAct } from "../store/communication-data/actions";
import { CaiCaseEnum, ClaimConfirmedEnum, CommunicationType, EventCommType, EventValueEnum } from "../types/communication/communicationDataType";

/* istanbul ignore next */
const WelcomePage = () => {
    let navigate = useNavigate();
    let { token } = useParams();
    const dispatch = useAppDispatch();
    const [openShareCommunicationModal, setOpenShareCommunicationModal] = useState(false)
    const [openCaiModal, setOpenCaiModal] = useState(false)
    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData

    const goToNextStepHandler = () => {

        const newEvent = new EventCommType({ value: EventValueEnum.IS_CLAIM_CONFIRMED })

        dispatch(saveCommunicationAct({
            data: new CommunicationType({
                ...communication,
                isClaimConfirmed: ClaimConfirmedEnum.CLAIM_CONFIRMED,
                caiCaseEnum: CaiCaseEnum.CASE_1A
            }),
            events: [newEvent],
            callback: () => navigate(composeUrl(token, PATHS.COSA_TI_CHIEDIAMO))
        }))

    }

    const goToNoIncident = () => {
        const newEvent = new EventCommType({ value: EventValueEnum.IS_CLAIM_NOT_CONFIRMED })

        dispatch(saveCommunicationAct({
            events: [newEvent],
            data: new CommunicationType({
                ...communication,
                isClaimConfirmed: ClaimConfirmedEnum.CLAIM_NOT_CONFIRMED,
                caiCaseEnum: CaiCaseEnum.CASE_1A
            }),
            callback: () => navigate(composeUrl(token, PATHS.NO_INCIDENT_PAGE))
        }))
    }

    useEffect(() => {
        const newEvent = new EventCommType({ value: EventValueEnum.FIRST_ACCESS });
        dispatch(saveCommunicationAct({
            events: [newEvent],
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const openShareCommunicationModalHandler = () => {
        // Analytics
        tqPageView('cai:inoltra comunicazione')
        setOpenShareCommunicationModal(true)
    }

    return (
        <>
            <RealtimeModal
                modalOpened={openShareCommunicationModal}
                hTitle="Inoltra comunicazione"
                hCloseButton={true}
                /* istanbul ignore next */
                onCloseHandler={() => setOpenShareCommunicationModal(false)}>
                <ShareCommunicationModal />
            </RealtimeModal>

            <RealtimeModal
                modalOpened={openCaiModal}
                hTitle="Constatazione amichevole"
                hCloseButton={true}
                /* istanbul ignore next */
                onCloseHandler={() => setOpenCaiModal(false)}>
                <CaiInfoModal />
            </RealtimeModal>

            <PageContainerCmp
                nextStepHandler={goToNextStepHandler}
                routePath={PATHS.WELCOME_FIRST_PAGE}
                removeNextBtn
            >
                <ObliqueSectionWithCta>
                    <TextLightB>Non eri alla guida del tuo veicolo?</TextLightB>
                    <TextLightB>Inoltra questo link al conducente.</TextLightB>
                    <ShareButton
                        className="share-btn"
                        onClick={openShareCommunicationModalHandler}>
                        <FontAwesomeIcon icon={faShare} />
                    </ShareButton>
                </ObliqueSectionWithCta>

                <Section>
                    <PageTitle>Come ti aiuteremo</PageTitle>
                    <List>
                        <ListItem>Ti guidiamo passo dopo passo durante la compilazione della tua <span className="hilighted">Constatazione Amichevole (CAI) digitale</span> <InfoIcon icon={faCircleInfo} onClick={() => setOpenCaiModal(true)} /></ListItem>
                        <ListItem>Anticipiamo i dati della tua denuncia per velocizzare i tempi di liquidazione</ListItem>
                    </List>
                </Section>

                <Vspace qty={2}></Vspace>

                <Section>
                    <InfoCard>
                        <CardIconContainer>
                            <FontAwesomeIcon icon={faClock} />
                        </CardIconContainer>
                        <TextLightB centered>
                            Hai tempo fino alle {localTimeHour(communication.creation_date)} del {caiExpireLocalDate(communication.creation_date)} per procedere: dopodiché il link non sarà disponibile e sarai ricontattato dalla tua agenzia nel minor tempo possibile.
                        </TextLightB>
                    </InfoCard>
                </Section>

                <Section>
                    <NextButton
                        onClick={goToNextStepHandler}>Procedi</NextButton>
                    <Vspace></Vspace>
                    <SecondChoiseButton
                        onClick={goToNoIncident}
                    >Non ho fatto nessun incidente</SecondChoiseButton>
                </Section>

            </PageContainerCmp>
        </>

    )
}

export default WelcomePage