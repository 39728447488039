import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Section } from "../components/styled/Containers.style";
import { selectCommunicationData } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { PageTitle } from "../components/styled/Text.style";
import { VPercentageSeparator } from '../components/styled/Separators.style'
import { PATHS } from "../routes/CaiExperienceRouter";
import { saveCommunicationAct } from "../store/communication-data/actions";
import { CommunicationType, OtherContactInfo, otherPeople_contactInfoItems } from "../types/communication/communicationDataType";
import PageContainerCmp from "./PageContainerCmp";
import { composeUrl } from "../utils/utils";
import { CaiWizardPointBadge } from "../components/styled/page-custom/Cai.style";
import OtherContactCmp from "../components/other-people/OtherContactCmp";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { CaiInfoModal } from "../components/cai-info-modal/CaiInfoModal";
import { RealtimeModal } from "../components/modal/RealtimeModal";
import { InfoIcon } from "../components/styled/Icon.style";

  /* istanbul ignore next */
const CaiFeritiETestimoniPage = () => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let { token } = useParams();

    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData

    const [feritiInfo, setFeritiInfo] = useState<OtherContactInfo>(communication.feritiInfo)
    const [testimoniInfo, setTestimoniInfo] = useState<OtherContactInfo>(communication.testimoniInfo)
    const [openCaiModal, setOpenCaiModal] = useState(false)
    const isCaiStepValid = true

    const goToNextStepHandler = () => {
        const updCommunication = new CommunicationType({
            ...communication,
            feritiInfo: new OtherContactInfo(feritiInfo),
            testimoniInfo: new OtherContactInfo(testimoniInfo),
        })
        dispatch(saveCommunicationAct({
            data: updCommunication,
            callback: () => navigate(composeUrl(token, PATHS.CAI_DATI_CONTROPARTE))
        })
        )
    }

    return (
        <>
            <RealtimeModal
                modalOpened={openCaiModal}
                hTitle="Constatazione amichevole"
                hCloseButton={true}
                /* istanbul ignore next */
                onCloseHandler={() => setOpenCaiModal(false)}>
                <CaiInfoModal />
            </RealtimeModal>

            <PageContainerCmp
                nextStepHandler={goToNextStepHandler}
                routePath={PATHS.CAI_FERITI_E_TESTIMONI}
                nextDisabled={!isCaiStepValid}
                hasPaddingX
            >
                <>
                    <Section>
                        <PageTitle>Constatazione amichevole <InfoIcon icon={faCircleInfo} onClick={() => setOpenCaiModal(true)} /></PageTitle>
                        <CaiWizardPointBadge>2/3</CaiWizardPointBadge>
                    </Section>

                    <OtherContactCmp
                        questionLabel={"Ci sono feriti? (Anche se lievi)"}
                        initValues={feritiInfo}
                        onChange={(newInfo) => setFeritiInfo(currInfo => ({
                            ...currInfo,
                            ...newInfo
                        }))}
                        contactListPropItems={otherPeople_contactInfoItems}
                        contactLabel={"Ferito"}
                    />

                    <VPercentageSeparator></VPercentageSeparator>

                    <OtherContactCmp
                        questionLabel={"Ci sono testimoni?"}
                        initValues={testimoniInfo}
                        onChange={(newInfo) => setTestimoniInfo(currInfo => ({
                            ...currInfo,
                            ...newInfo
                        }))}
                        contactListPropItems={otherPeople_contactInfoItems}
                        contactLabel={"Testimone"}
                    />

                </>
            </PageContainerCmp>
        </>

    )
}

export default CaiFeritiETestimoniPage