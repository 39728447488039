import styled from 'styled-components'

export const ObliqueSectionWithCta = styled.div `
    position: relative;
    width: 100vw;
    margin: -1rem -1rem 0;
    padding: 2rem 1.2rem;

    &:before {
        content: "";
        position: absolute;
        top: -75px;
        left: 0;
        width: 100%;
        height: 170px;
        background-color: #e4eef8;
        transform: skew(0deg, 354deg);
        border-bottom: 1px solid #3079c9;
    }
`