import styled from 'styled-components'

export const Header = styled.div`
  border: none;
  background-color: #003b5c;
  width: 100%;
  height: 50px;
  position: fixed;
  top: 0px;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  &.in-app {
    display: none;
  }
`

export const HeaderInfo = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  color: #fff;
  font-size: 10px;
  text-align: right;
  font-family: "Bold";
  text-transform: uppercase;
  letter-spacing: 1px;

  .info-value {
    font-size: 14px;
    margin-top: 4px;
  }
`