import styled from 'styled-components'
import { Button } from './Button.style'
import { CenteredContainer } from './Common.style'
import { Img } from './Img.style'

export const FileCard = styled(CenteredContainer)`
    margin-top: 20px;
`

export const FileInputs = styled(CenteredContainer)`
    padding: 15px 32px;
`

export const FileInput = styled.input`
    position: relative;
    text-align: right;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
    //height: 46px;
    max-width: 200px;
`

export const ButtonFileInput = styled.button`
    font-family: "Medium";
    cursor: pointer;
    color: #fff;
    background-color: #3079c9;
    font-size: 17px;
    text-transform: uppercase;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;            
    border: none;
    outline: none;
    text-align: center;
    text-decoration: none;
    border-radius: 0px;
    line-height: 49px;
    svg {
        color: #fff;
        font-size: 20px
    }
`
export const FileListTitle = styled.p`
    font-family: "Bold";
    color: #3079c9;
    text-transform: uppercase;
    font-size: 1rem;
`
export const FileList = styled.ul`
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    flex-wrap: wrap;
`

export const FileListItem = styled.li`
    list-style: none;
    width: 100px;
    height: 124px;
    border: 2px solid #3079c9;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const FileItemContent = styled(CenteredContainer)`
    height: 100%;
`

export const FileActionsContainer = styled(CenteredContainer)`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height:32px;
    background-color: #3079c9;
    color: #fff;
`

export const FileActionButton = styled(Button)`
    cursor: pointer;
    background-color: transparent;
    height: 32px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
`

export const FilePreviewImg = styled(Img)`
    height: 100%;
    width: 100%;
    object-fit: cover;
`

export const FileFullPageImageContainer = styled(CenteredContainer)`
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.8);
`

export const FileFullPageImage = styled(FilePreviewImg)`
    position: absolute;
    top: 50%;
    transform: translatey(-50%);
    width: 90%;
    height: auto;
`

export const FullPageCloseBtn = styled(Button) `
    cursor: pointer;
    position: fixed;
    top: 4rem;
    right: 2rem;
    color:#fff;
    font-size: 2.2rem;
    background-color: transparent;
    width: 48px;
    height: 48px;
`

export const FullPageDeleteBtn = styled(FullPageCloseBtn)`
    left: 2rem;
    z-index: 99;
`

