import { EventValueEnum } from "../types/communication/communicationDataType";
import { faFaceFrown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlainInfoCard } from "../components/styled/Card.style";
import { Section } from "../components/styled/Containers.style";
import { IconContainer } from "../components/styled/Icon.style";
import { Vspace } from "../components/styled/Separators.style";
import { BigText, TextDefault, TextDefaultSmall } from "../components/styled/Text.style";
import { PATHS } from "../routes/CaiExperienceRouter";
import PageContainerCmp from "./PageContainerCmp";

const EndProcedureCancelledPage = () => {

    return (
        <>
            <PageContainerCmp
                routePath={PATHS.PROCEDURE_CANCELLED}
                removeNextBtn={true}
                isForceLastPathname={true}
                eventsOnLoad={[EventValueEnum.CONFIRMED, EventValueEnum.OPEN_CLAIM]}>
                {/*<Section>
                    <PlainInfoCard>
                        <IconContainer>
                            <FontAwesomeIcon icon={faFaceFrown} />
                        </IconContainer>
                        <Vspace></Vspace>
                        <Vspace></Vspace>
                        <BigText>Procedura annullata.</BigText>
                    </PlainInfoCard>

                    <Vspace qty={2}></Vspace>

                    <TextDefaultSmall>Puoi procedere con la chiusura della pagina.</TextDefaultSmall>
                </Section*/}
                <Section>
                    <PlainInfoCard>
                        <IconContainer>
                            <FontAwesomeIcon icon={faFaceFrown} />
                        </IconContainer>
                        <Vspace></Vspace>
                        <Vspace></Vspace>
                        <BigText>Procedura interrotta</BigText>
                    </PlainInfoCard>

                    <Vspace qty={2}></Vspace>
                    <TextDefault centered={true}><strong>Siamo spiacenti ma non è stato possibile portare a termine la procedura.</strong></TextDefault>
                    <Vspace qty={1}></Vspace>
                    <TextDefaultSmall>Contatta la tua Compagnia per proseguire. Grazie.</TextDefaultSmall>
                    <Vspace qty={8}></Vspace>


                </Section>
            </PageContainerCmp>
        </>
    )
}

export default EndProcedureCancelledPage