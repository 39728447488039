import { useNavigate, useParams } from "react-router-dom";
import { composeUrl } from "../utils/utils";
import { PATHS } from "../routes/CaiExperienceRouter";
import PageContainerCmp from "./PageContainerCmp";
import { Section } from "../components/styled/Containers.style";
import { PageTitle, TextDefault } from "../components/styled/Text.style";
import { Vspace } from "../components/styled/Separators.style";
import { NextButton, SecondChoiseButton } from "../components/styled/Button.style";

/* istanbul ignore next */
const CtpProposePaperCaiPage = () => {
    let navigate = useNavigate();
    let { token } = useParams();

    const goToNextStepHandler = () => {
        const nextPath = composeUrl(token, PATHS.CAI_PAPER)
        navigate(nextPath)
    }

    const goToCancelStepHandler = () => {
        const nextPath = composeUrl(token, PATHS.PROCEDURE_CANCELLED)
        navigate(nextPath)
    }

    return (
        <>
            <PageContainerCmp
                nextStepHandler={goToNextStepHandler}
                routePath={PATHS.CTP_PROPOSE_PAPER_CAI}
                removeNextBtn
            >
                <>
                    <Section>
                        <PageTitle>Constatazione amichevole</PageTitle>
                        <Vspace></Vspace>
                        <TextDefault centered sm>La controparte ha interrotto il processo di Constatazione Amichevole digitale.</TextDefault>
                        <Vspace></Vspace>
                        <TextDefault centered sm>Se lo desideri puoi proseguire con la procedura della Constatazione Amichevole cartacea.</TextDefault>
                    </Section>

                    <Section>
                        <Vspace></Vspace>
                        <Vspace></Vspace>
                        <Vspace></Vspace>
                        <NextButton
                            onClick={goToNextStepHandler}>OK</NextButton>
                        <Vspace></Vspace>
                        <SecondChoiseButton onClick={goToCancelStepHandler}>Non voglio procedere</SecondChoiseButton>
                    </Section>
                </>
            </PageContainerCmp>
        </>

    )
}

export default CtpProposePaperCaiPage