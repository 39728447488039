
import React from 'react';
import ReactDOM from 'react-dom/client';
import { store } from './store/configureStore'
import App from './App';

/* 
  For different environment, to be compatible with Drakkar version, we need to copy process.env variables to window object
*/
/* istanbul ignore next */
(() => { 
  /* istanbul ignore next */
  if (window.location.href.indexOf('localhost') >= 0 && window.location.href.indexOf('localhost:80') < 0) {
    window.REACT_APP_CAPTCHA_SITEKEY = process.env.REACT_APP_CAPTCHA_SITEKEY
    window.REACT_APP_VTV_API_ENDPOINT = process.env.REACT_APP_VTV_API_ENDPOINT
    window.REACT_APP_MAP_APP_ID = process.env.REACT_APP_MAP_APP_ID
    window.REACT_APP_MAP_APP_CODE = process.env.REACT_APP_MAP_APP_CODE
    window.REACT_APP_CAI_COMMUNICATION_EXPIRATION_IN = process.env.REACT_APP_CAI_COMMUNICATION_EXPIRATION_IN
    window.REACT_APP_CAI_SIGNATURE_EXPIRATION_IN = process.env.REACT_APP_CAI_SIGNATURE_EXPIRATION_IN
    window.REACT_APP_SKIP_CAPTCHA = process.env.REACT_APP_SKIP_CAPTCHA
    window.REACT_APP_POLLING_INTERVAL_IN_MS = process.env.REACT_APP_POLLING_INTERVAL_IN_MS
    window.REACT_APP_TEALIUM_ENV = process.env.REACT_APP_TEALIUM_ENV
  }
})() 

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <App  store={store} />
  </>
);

declare const window: any;
