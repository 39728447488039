import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValueLabelOption } from "../../types/commonTypes";
import { CheckboxCheck, CheckboxItem, CheckboxLabel, CheckboxLabelContainer, CheckboxSubLabel } from "../styled/Checkbox.style";

interface CheckboxItemCmpProps<T> {
    item: ValueLabelOption<T>;
    itemClassName?: string;
    onSelect: any;
    isActive?: boolean;
    horizontal?: boolean;
}

/* istanbul ignore next */
const CheckboxItemCmp = <T extends unknown>(props: CheckboxItemCmpProps<T>) => {
    const { item, itemClassName, onSelect, isActive, horizontal } = props
    const isActiveClass = isActive ? 'active' : ''
    return (
        <CheckboxItem
            className={itemClassName}
            horizontal={horizontal}>
            <CheckboxCheck
                className={`${isActiveClass} ${itemClassName}`}
                onClick={() => onSelect(item)}>
                {<FontAwesomeIcon icon={faCheck} />}
            </CheckboxCheck>
            <CheckboxLabelContainer>
                <CheckboxLabel
                    light={true}
                    horizontal={horizontal}
                    className={itemClassName}>{item.label}
                </CheckboxLabel>
                <CheckboxSubLabel>{item.subLabel}</CheckboxSubLabel>
            </CheckboxLabelContainer>
        </CheckboxItem>
    )
}

export default CheckboxItemCmp