import { useState } from "react";
import { FileItemType, ZoneUrtoType } from "../../types/communication/communicationDataType";
import FileItemCmp from "../file/FileItemCmp";
import { RealtimeModal } from "../modal/RealtimeModal";
import { UrtoTableCell } from "../styled/ZonaUrto.style"
import UrtoFotoModalCmp from "./UrtoFotoModalCmp";

interface UrtoTableCellCmpProps {
    preFileType?: string
    zoneItem: ZoneUrtoType
    zoneItemSelected: () => void
    zoneFotoAdded: (file: FileItemType) => void
    zoneFotoRemove: () => void
    removeZone: (zoneItem: ZoneUrtoType) => void
}

const UrtoTableCellCmp = (props: UrtoTableCellCmpProps) => {
    const { zoneItem, zoneItemSelected, removeZone, preFileType, zoneFotoAdded,
        zoneFotoRemove } = props;
    const [showUrtoModal, setShowUrtoModal] = useState<boolean>(false)
    const [fileUploadLoading, setFileUploadLoading] = useState<boolean>(false)

    const isSelected = !!zoneItem.isSelected
    const fileIsPresent = !!zoneItem.file?._id
    const fileType = `${preFileType || ''}${zoneItem.position}`

    const cellClickHandler = () => {
        if (fileIsPresent) {
            return
        } else if (isSelected) {
            setShowUrtoModal(true)
        } else {
            zoneItemSelected()
        }
    }

    const onFileAddedHandler = (addedFile: any) => {
        if (addedFile && addedFile._id) {
            zoneFotoAdded(addedFile)
            closeModal()
        } else {
            setFileUploadLoading(true)
        }
    }

    const closeModal = () => {
        setFileUploadLoading(false)
        setShowUrtoModal(false)
    }

    const removeZoneHandler = () => {
        removeZone(zoneItem);
        closeModal();
    }

    const deleteFileHandler = () => {
        zoneFotoRemove()
    }

    return (
        <UrtoTableCell
            isSelected={isSelected}
            onClick={cellClickHandler}>

            <RealtimeModal
                modalOpened={showUrtoModal}
                hTitle="Foto dell'incidente"
                hCloseButton={true}
                // onConfirmHandler={() => {})}
                onCloseHandler={closeModal}>
                <>
                    <UrtoFotoModalCmp
                        isLoading={fileUploadLoading}
                        fileType={fileType}
                        onFileAdded={onFileAddedHandler}
                        removeZone={removeZoneHandler} />
                </>
            </RealtimeModal>

            {fileIsPresent && <FileItemCmp
                canDelete={false}
                fullPageCanDelete={true}
                file={zoneItem.file}
                deleteFile={deleteFileHandler} />
            }

        </UrtoTableCell>
    )
}

export default UrtoTableCellCmp