import { useMemo, useState } from "react"
import { getValueLabelOptions, ModalContentProps, ValueLabelOption } from "../../types/commonTypes"
import { mapBaremeIdLabel } from "../../utils/bareme.utils"
import RadioOptionsCmp from "../radio/RadioOptionsCmp"
import { ModalBodyContainer, ModalConfirmButton, ModalFooter } from "../styled/Modal.style"

export const BaremeSelectionModal = ({ onConfirmAndCloseHandler, initValue }: ModalContentProps<Partial<string | undefined>>) => {
    const [selectedBareme, setSelectedBareme] = useState<string>(initValue || '')

    const baremeOptions = useMemo(() => {
        const _baremeOptions: ValueLabelOption<string>[] = []

        for (const property in mapBaremeIdLabel) {
            _baremeOptions.push({ value: property, label: mapBaremeIdLabel[property] })
        }
        return _baremeOptions;

    }, [])
    return (
        <>
            <ModalBodyContainer
            className="has-footer">
                <RadioOptionsCmp
                    options={baremeOptions}
                    value={getValueLabelOptions(baremeOptions, selectedBareme)}
                    className={'no-sign-reason-choise'}
                    onChange={(newChoise: ValueLabelOption<string>) => setSelectedBareme(newChoise.value)}
                />
            </ModalBodyContainer>
            <ModalFooter
                className="fixed modal-footer">
                <ModalConfirmButton
                    className="with-m"
                    onClick={() => onConfirmAndCloseHandler && onConfirmAndCloseHandler(selectedBareme)}
                > Ok</ModalConfirmButton>
            </ModalFooter>
        </>
    )
}
