import { useNavigate, useParams } from "react-router-dom";
import { Section } from "../components/styled/Containers.style";
import { PageTitle, TextDefault } from "../components/styled/Text.style";
import { Vspace } from '../components/styled/Separators.style'
import { PATHS } from "../routes/CaiExperienceRouter";
import PageContainerCmp from "./PageContainerCmp";
import LoaderCmp from "../components/loader/LoaderCmp";
import { composeUrl, hasCaiSecondPageData, isInApp } from "../utils/utils";
import { NextButton, SecondChoiseButton } from "../components/styled/Button.style";
import CaiPreviewsCmp from "../components/cai-preview/CaiPreviewsCmp";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { invokeSignatureAct, saveCommunicationAct } from "../store/communication-data/actions";
import { CommunicationType, EventCommType, EventValueEnum } from "../types/communication/communicationDataType";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { CaiInfoModal } from "../components/cai-info-modal/CaiInfoModal";
import { RealtimeModal } from "../components/modal/RealtimeModal";
import { InfoIcon } from "../components/styled/Icon.style";
import { selectCommunicationData } from "../store";
import { usePrivacyCheckCmp } from "../utils/hooks/usePrivacyCheckCmp";
import CancelConfirmIdentityModal from "../components/cancel-confirm-identity/CancelConfirmIdentityModal";

/* istanbul ignore next */
const CaiPreviewPage = () => {
    const communicationData = useAppSelector(selectCommunicationData)
    const { invokeSignatureMetadata, communication } = communicationData
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let { token } = useParams();
    const previewUrlPage_1 = `${window.REACT_APP_VTV_API_ENDPOINT}/api/realtime/communication/${token}/dinamica-attachment/full-page`;
    const previewUrlPage_2 = `${window.REACT_APP_VTV_API_ENDPOINT}/api/realtime/communication/${token}/dinamica-attachment/second-full-page`;
    const isCaiSecondPageVisible = hasCaiSecondPageData(communication)
    const { privChecked, PrivCheckedCmp } = usePrivacyCheckCmp(communication.authConfirmPrivacyChecked)

    const [openCaiModal, setOpenCaiModal] = useState(false)
    const [cancelModalOpened, setCancelModalOpened] = useState<boolean>(false)

    const shouldDisableButton = () => {
        return !(privChecked?.value);
    }

    const goToNextStepHandler = () => {
        const _pathname = composeUrl(token, PATHS.END_SIGNATURE_FORWARD_SUCCESS)
        const newEvent = new EventCommType({ value: EventValueEnum.CAI_FILLING_END })
        const data = new CommunicationType({
            ...communication,
            authConfirmPrivacyChecked: privChecked.value
        })

        // if it is in app, then directly invoke the signature process
        // If it is web app, then pass from confirm identity step
        const _inApp = isInApp()

        if (_inApp) {
            dispatch(saveCommunicationAct({
                data,
                events: [newEvent]
            }))

            dispatch(invokeSignatureAct({
                data: null,
                callback: () => navigate(_pathname)
            }))
        } else {
            const _confirmIdentityPath = composeUrl(token, PATHS.CONFIRM_IDENTITY)
            dispatch(saveCommunicationAct({
                data,
                events: [],
                callback: () => navigate(_confirmIdentityPath)
            }))
        }
    }

    return (
        <>

            <CancelConfirmIdentityModal
                modalOpened={cancelModalOpened}
                onCloseHandler={() => setCancelModalOpened(false)} />

            <RealtimeModal
                modalOpened={openCaiModal}
                hTitle="Constatazione amichevole"
                hCloseButton={true}
                /* istanbul ignore next */
                onCloseHandler={() => setOpenCaiModal(false)}>
                <CaiInfoModal />
            </RealtimeModal>

            <PageContainerCmp
                nextStepHandler={goToNextStepHandler}
                routePath={PATHS.CAI_PREVIEW}
                removeNextBtn
            >
                <>
                    {invokeSignatureMetadata?.isLoading && <LoaderCmp className="cover cai-preview" />}
                    <Section>
                        <PageTitle>Constatazione amichevole <InfoIcon icon={faCircleInfo} onClick={() => setOpenCaiModal(true)} /></PageTitle>
                        <Vspace></Vspace>
                        <TextDefault centered sm>Le informazioni del sinistro che ci hai condiviso sono state inserite nel modulo di constatazione amichevole che vedi qui sotto.</TextDefault>
                        <Vspace></Vspace>
                        <TextDefault centered sm>Procedendo con la firma riceverai un SMS per essere reindirizzato al completamento.</TextDefault>
                    </Section>

                    <Section>
                        <CaiPreviewsCmp
                            previewUrlPage_1={previewUrlPage_1}
                            previewUrlPage_2={previewUrlPage_2}
                            isCaiSecondPageVisible={isCaiSecondPageVisible}
                        ></CaiPreviewsCmp>
                    </Section>

                    {PrivCheckedCmp}

                    <Section>
                        <Vspace></Vspace>
                        <NextButton
                            disabled={shouldDisableButton()}
                            onClick={goToNextStepHandler}>Procedi</NextButton>
                        <Vspace></Vspace>
                        <SecondChoiseButton onClick={() => setCancelModalOpened(true)}>Non voglio firmare</SecondChoiseButton>
                    </Section>
                </>
            </PageContainerCmp>
        </>

    )
}

export default CaiPreviewPage