import { faHourglassHalf, faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InfoCard, PlainInfoCard } from "../components/styled/Card.style";
import { Section } from "../components/styled/Containers.style";
import { CardIconContainer, IconContainer } from "../components/styled/Icon.style";
import { Vspace } from "../components/styled/Separators.style";
import { BigText, TextDefault, TextDefaultSmall, TextLightB } from "../components/styled/Text.style";
import { PATHS } from "../routes/CaiExperienceRouter";
import PageContainerCmp from "./PageContainerCmp";

const ForceWaitCaiIsFillingPage = () => {
    return (
        <>
            <PageContainerCmp
                routePath={PATHS.FORCE_WAIT_CAI_IS_FILLING}
                removeNextBtn={true}
                isForceLastPathname={true}>
                <Section>
                    <PlainInfoCard>
                        <IconContainer>
                            <FontAwesomeIcon icon={faHourglassHalf} />
                        </IconContainer>
                        <Vspace></Vspace>
                        <Vspace></Vspace>
                        <BigText>Un attimo di pazienza.</BigText>
                    </PlainInfoCard>

                    <Vspace qty={2}></Vspace>
                    <TextDefault centered={true}><strong>La controparte sta compilando la Constatazione Amichevole (CAI) digitale. Ti chiediamo gentilmente di attendere che abbia compilato la sua versione.</strong></TextDefault>
                    <Vspace qty={2}></Vspace>
                    <TextDefaultSmall>Sarai ricontattato tramite SMS non appena possibile per visionare la CAI e procedere eventualmente con la fase di firma.</TextDefaultSmall>
                    <Vspace qty={4}></Vspace>

                    <InfoCard>
                        <CardIconContainer>
                            <FontAwesomeIcon icon={faUserFriends} />
                        </CardIconContainer>
                        <TextLightB>
                            Se rifiuterai di firmare la CAI proposta dalla Controparte, potrai <strong>fornire la tua versione</strong> dei fatti cliccando sul link contenuto nel primo SMS ricevuto.
                        </TextLightB>
                    </InfoCard>
                </Section>
            </PageContainerCmp>
        </>
    )
}

export default ForceWaitCaiIsFillingPage