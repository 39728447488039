import { faFaceFrown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlainInfoCard } from "../../components/styled/Card.style";
import { Section } from "../../components/styled/Containers.style";
import { IconContainer } from "../../components/styled/Icon.style";
import { Vspace } from "../../components/styled/Separators.style";
import { BigText, TextDefaultSmall, TextDefault } from "../../components/styled/Text.style";
import PageContainerCmp from "../PageContainerCmp";
import { AUTH_CONFIRM_PATHS } from "../../routes/AuthConfirmRouter";

const AuthConfirmEndTokenExpiredPage = () => {

    return (
        <>
            <PageContainerCmp
                routePath={AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_TOKEN_EXPIRED}
                removeNextBtn={true}
                isForceLastPathname={true}
            >
                <Section>
                    <PlainInfoCard>
                        <IconContainer>
                            <FontAwesomeIcon icon={faFaceFrown} />
                        </IconContainer>
                        <Vspace></Vspace>
                        <Vspace></Vspace>
                        <BigText>Procedura non conclusa</BigText>
                    </PlainInfoCard>

                    <Vspace qty={2}></Vspace>
                    <TextDefault centered={true}><strong>Siamo spiacenti ma le 24 ore a disposizione per compilare questa procedura sono scadute.</strong></TextDefault>
                    <Vspace qty={1}></Vspace>
                    <TextDefaultSmall>Contatta la tua Compagnia per proseguire. Grazie.</TextDefaultSmall>
                    <Vspace qty={8}></Vspace>


                </Section>
            </PageContainerCmp>
        </>
    )
}

export default AuthConfirmEndTokenExpiredPage