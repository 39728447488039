
import { booleanRadioOptions, BoolStringEnum, emailFormatMessage, messageRequired, MetadataType, numberFormat, numberFormatLength, TemplateTypeEnum, UNIPOLSAI_COMPLETE, ValueLabelOption } from "../commonTypes";
import * as yup from "yup";
import moment from "moment";
import { BaremeResponses } from "./caiType";
import { SignCaiBodyType } from "./sign-type.ts";

export enum UserRoleEnum {
  USER_1 = 'USER_1',
  CTP = 'CTP'
}

export enum CaiCaseEnum {
  CASE_1A = 'CASE_1A',
  CASE_1B = 'CASE_1B',
  CASE_2 = 'CASE_2',
}

export class CommunicationDataType {
  // Result Data
  public captchaChallenge: boolean = false;
  public aniaoptions: AniaItemType[] = [];
  public communication: CommunicationType = new CommunicationType();
  public ctp: CommunicationType = new CommunicationType();
  public caiValidationInfo: CaiValidationInfoType = new CaiValidationInfoType()
  public loadCommunicationMetadata: MetadataType = new MetadataType()
  public saveCommunicationMetadata: MetadataType = new MetadataType()
  public invokeSignatureMetadata: MetadataType = new MetadataType()

  constructor(input?: any) {
    if (input) {
      this.captchaChallenge = input.captchaChallenge || false;
      this.aniaoptions = input.aniaoptions?.map((el: any) => new AniaItemType(el)) || [];
      this.communication = new CommunicationType(input.communication);
      this.ctp = new CommunicationType(input.ctp);
      this.caiValidationInfo = new CaiValidationInfoType(input.caiValidationInfo);
      this.loadCommunicationMetadata = new MetadataType(input.loadCommunicationMetadata)
      this.saveCommunicationMetadata = new MetadataType(input.saveCommunicationMetadata)
      this.invokeSignatureMetadata = new MetadataType(input.invokeSignatureMetadata)
    }
  }
}

export class CaiValidationInfoType {
  public y_contactInfo?: boolean;
  public n_contactInfo?: boolean;
  public y_ctp_contactInfo?: boolean;
  public n_ctp_contactInfo?: boolean;
  public dn_ctp_contactInfo?: boolean;

  constructor(input?: any) {
    if (input) {
      this.y_contactInfo = input.y_contactInfo;
      this.n_contactInfo = input.n_contactInfo;
      this.y_ctp_contactInfo = input.y_ctp_contactInfo;
      this.n_ctp_contactInfo = input.n_ctp_contactInfo;
      this.dn_ctp_contactInfo = input.dn_ctp_contactInfo;
    }
  }
}

export enum BaremeUserChoiseEnum {
  BAREME_BATCH_ACCEPTED = 'BAREME_BATCH_ACCEPTED',
  BAREME_QUESTIONS_FILLED_ACCEPTED = 'BAREME_QUESTIONS_FILLED_ACCEPTED',
  BAREME_FREE_DESCRIPTION = 'BAREME_FREE_DESCRIPTION'
}

type PlaceholderValuesType = {
  plate?: string
}

type EvMetadataType = {
  sid?: string
}

export enum NoSignReasonEnum {
  INCORRECT_DOCUMENTS = 'INCORRECT_DOCUMENTS',
  SHOULD_NOT_BE_INVOLVED = 'SHOULD_NOT_BE_INVOLVED',
  OTHER = 'OTHER'
}

export enum ClaimConfirmedEnum {
  CLAIM_CONFIRMED = 'CLAIM_CONFIRMED',
  CLAIM_NOT_CONFIRMED = 'CLAIM_NOT_CONFIRMED',
  NOT_ANSWERED = 'NOT_ANSWERED',
}

export const getDefaultCtpCompAssic = (input: any) => {
  if (input?.type?.indexOf(TemplateTypeEnum.SIN_INAT) >= 0) {
    return UNIPOLSAI_COMPLETE
  }
  return ""
}

export class CommunicationType {
  public _id: string = '';
  public to: string = '';
  public content: string = '';
  public placeholderValues: PlaceholderValuesType = {};
  public contentData: PlaceholderValuesType = {};
  public company_code: string = '';
  public type: string = '';
  public hrm_message_id: string = '';
  public inc_utc_time: string = '';
  public inc_data: string = '';
  public disable_step_10?: boolean;
  public assic_cfpiva: string = '';
  public bareme_src: string = '';
  public comp_agenzia_nome: string = '';
  public comp_indirizzo: string = '';
  public crash_id: string = '';
  public assic_cognome: string = '';
  public assic_stato: string = '';
  public comp_polizza: string = '';
  public comp_agenzia_code: string = '';
  public bareme_desc: string = '';
  public assic_indirizzo: string = '';
  public assic_email: string = '';
  public assic_cap: string = '';
  public veic_targa: string = '';
  public veic_marca: string = '';
  public cai_side: string = '';
  public tel_o_mail: string = '';
  public comp_certificato_dal: string = '';
  public main_impact_point?: ZonaUrtoEnum;
  public inc_luogo: string = '';
  public update_date: string = '';
  public comp_agenzia_denominazione: string = '';
  public inc_latitude: string = '';
  public comp_certificato_al: string = '';
  public cp_veic_targa: string = '';
  public original_cp_veic_targa: string = '';
  public correlated_crash_id: string = '';
  public assic_nome: string = '';
  public phone_number: string = '';
  public inc_longitude: string = '';
  public assic_tel_o_email: string = '';
  public inc_ora: string = '';
  public creation_date: string = '';
  public cai_digital_ending_date: string = '';
  public token: string = '';
  public mainUserToken: string = '';
  public events: EventCommType[] = [];
  public interactions: InteractionType[] = [];
  public comp_tel_o_email: string = '';
  public file_download_cai_id: string = '';
  public file_download_tecnico_id: string = '';
  public onlyInternal: boolean = false;
  public caiData?: SignCaiBodyType;
  public userRole?: UserRoleEnum;
  public caiCaseEnum?: CaiCaseEnum;
  public previewSecondPageVisible?: boolean;
  public phoneCtp: string = '';
  public caiAcceptanceIpernaturale: boolean = false;
  public curtUrl: string = '';

  // ui created
  public numOfVeichles: number = 2;
  public numOfPasssengers: number = 0;
  public cp_compagnia_assic: string = ""
  public lastPathname: string = ''
  public baremeUserChoise?: BaremeUserChoiseEnum;
  public baremeFreeDescription: string = ''
  public noClaimWhatHappened?: ValueLabelOption<string>;
  public forceLastPathname: string = ''
  public fotoContestoList: FileItemType[] = []
  public conducenteInfo: ConducenteInfo = new ConducenteInfo()
  public rimorchioInfo: RimorchioInfo = new RimorchioInfo()
  public feritiInfo: OtherContactInfo = new OtherContactInfo()
  public testimoniInfo: OtherContactInfo = new OtherContactInfo()
  public altriVeicoliInfo: OtherContactInfo = new OtherContactInfo()
  public oggettiDiversiInfo: OtherContactInfo = new OtherContactInfo()
  public controparteInfo: ControparteInfo = new ControparteInfo()
  public zoneUrto: ZoneUrtoType[] = []
  public ctp_zoneUrto: ZoneUrtoType[] = []
  public noSignReason?: NoSignReasonEnum;
  public baremeResponses: BaremeResponses = new BaremeResponses()
  public baremeInfo: BaremeInfo = new BaremeInfo()
  public allFiles: FileItemType[] = []
  public auth_confirm_input_veic_targa: string = '';
  public authConfirmDocsList: FileItemType[] = []
  public authConfirmPrivacyChecked: boolean = false;
  public caiPaperList: FileItemType[] = [];
  public inApp: boolean = false;
  public isClaimConfirmed?: ClaimConfirmedEnum = ClaimConfirmedEnum.NOT_ANSWERED;

  constructor(input?: any) {
    if (input) {
      this._id = input._id;
      this.to = input.to;
      this.content = input.content;
      this.placeholderValues = input.placeholderValues;
      this.contentData = input.contentData;
      this.company_code = input.company_code;
      this.type = input.type;
      this.hrm_message_id = input.hrm_message_id;
      this.inc_utc_time = input.inc_utc_time;
      this.inc_data = input.inc_data;
      this.disable_step_10 = input.disable_step_10;
      this.assic_cfpiva = input.assic_cfpiva;
      this.bareme_src = input.bareme_src;
      this.comp_agenzia_nome = input.comp_agenzia_nome;
      this.comp_indirizzo = input.comp_indirizzo;
      this.crash_id = input.crash_id;
      this.assic_cognome = input.assic_cognome;
      this.assic_stato = input.assic_stato;
      this.comp_polizza = input.comp_polizza;
      this.comp_agenzia_code = input.comp_agenzia_code;
      this.bareme_desc = input.bareme_desc;
      this.assic_indirizzo = input.assic_indirizzo;
      this.assic_email = input.assic_email;
      this.assic_cap = input.assic_cap;
      this.veic_targa = input.veic_targa;
      this.veic_marca = input.veic_marca;
      this.cai_side = input.cai_side;
      this.tel_o_mail = input.tel_o_mail;
      this.comp_certificato_dal = input.comp_certificato_dal;
      this.main_impact_point = input.main_impact_point;
      this.inc_luogo = input.inc_luogo;
      this.update_date = input.update_date;
      this.comp_agenzia_denominazione = input.comp_agenzia_denominazione;
      this.inc_latitude = input.inc_latitude;
      this.comp_certificato_al = input.comp_certificato_al;
      this.cp_veic_targa = input.cp_veic_targa;
      this.original_cp_veic_targa = input.original_cp_veic_targa;
      this.correlated_crash_id = input.correlated_crash_id;
      this.assic_nome = input.assic_nome;
      this.phone_number = input.phone_number;
      this.inc_longitude = input.inc_longitude;
      this.assic_tel_o_email = input.assic_tel_o_email;
      this.inc_ora = input.inc_ora;
      this.creation_date = input.creation_date;
      this.token = input.token;
      this.mainUserToken = input.mainUserToken;
      this.events = ((input && input.events) || []).map((ev: any) => new EventCommType(ev))
      this.interactions = ((input && input.interactions) || []).map((ev: any) => new InteractionType(ev))
      this.cai_digital_ending_date = input.cai_digital_ending_date
      this.comp_tel_o_email = input.comp_tel_o_email
      this.file_download_cai_id = input.file_download_cai_id;
      this.file_download_tecnico_id = input.file_download_tecnico_id;
      this.onlyInternal = !!(input.onlyInternal)
      this.caiData = input.caiData
      this.userRole = input.userRole;
      this.caiCaseEnum = input.caiCaseEnum;
      this.previewSecondPageVisible = !!(input.previewSecondPageVisible)
      this.phoneCtp = input?.phoneCtp
      this.caiAcceptanceIpernaturale = !!(input.caiAcceptanceIpernaturale)
      this.isClaimConfirmed = input.isClaimConfirmed || ClaimConfirmedEnum.NOT_ANSWERED
      this.curtUrl = input?.curtUrl

      // ui created
      this.numOfVeichles = input.numOfVeichles || this.numOfVeichles;
      this.numOfPasssengers = input.numOfPasssengers || this.numOfPasssengers;
      this.cp_compagnia_assic = input.cp_compagnia_assic || this.cp_compagnia_assic || getDefaultCtpCompAssic(input);
      this.lastPathname = input.lastPathname || this.lastPathname;
      this.baremeUserChoise = input && input.baremeUserChoise;
      this.baremeFreeDescription = input.baremeFreeDescription || this.baremeFreeDescription;
      this.noClaimWhatHappened = input && input.noClaimWhatHappened;
      this.forceLastPathname = input.forceLastPathname || this.forceLastPathname;
      this.fotoContestoList = ((input && input.fotoContestoList) || []).map((f: any) => new FileItemType(f))
      this.conducenteInfo = new ConducenteInfo(input.conducenteInfo)
      this.rimorchioInfo = new RimorchioInfo(input.rimorchioInfo)
      this.feritiInfo = new OtherContactInfo(input.feritiInfo)
      this.testimoniInfo = new OtherContactInfo(input.testimoniInfo)
      this.altriVeicoliInfo = new OtherContactInfo(input.altriVeicoliInfo)
      this.oggettiDiversiInfo = new OtherContactInfo(input.oggettiDiversiInfo)
      this.controparteInfo = new ControparteInfo(input.controparteInfo)
      this.zoneUrto = ((input && input.zoneUrto) || []).map((f: any) => new ZoneUrtoType(f))
      this.ctp_zoneUrto = ((input && input.ctp_zoneUrto) || []).map((f: any) => new ZoneUrtoType(f))
      this.noSignReason = input.noSignReason;
      this.baremeResponses = new BaremeResponses(input.baremeResponses)
      this.baremeInfo = new BaremeInfo(input.baremeInfo)
      this.allFiles = ((input && input.allFiles) || []).map((f: any) => new FileItemType(f))
      this.auth_confirm_input_veic_targa = input.auth_confirm_input_veic_targa;
      this.authConfirmDocsList = ((input && input.authConfirmDocsList) || []).map((f: any) => new FileItemType(f))
      this.authConfirmPrivacyChecked = !!(input.authConfirmPrivacyChecked)
      this.caiPaperList = ((input && input.caiPaperList) || []).map((f: any) => new FileItemType(f))
      this.inApp = !!(input.inApp)
    }
  }
}

export class FileItemType {
  // Result Data
  public _id: string = '';
  public file_type: string = '';
  public name: string = '';
  public mimetype: string = '';
  public value: string[] = [];
  public timestamp: string = '';
  public token: string = '';
  public metadata: MetadataType = new MetadataType()

  constructor(input?: any) {
    if (input) {
      this._id = input._id;
      this.file_type = input.file_type;
      this.name = input.name;
      this.mimetype = input.mimetype;
      this.value = input.value || [];
      this.timestamp = input.timestamp;
      this.token = input.token;
      this.metadata = new MetadataType(input.metadata)
    }
  }
}

export enum ZonaUrtoEnum {
  "TOP-LEFT" = 'TOP-LEFT',
  "TOP-CENTER" = 'TOP-CENTER',
  "TOP-RIGHT" = 'TOP-RIGHT',
  "CENTER-LEFT" = 'CENTER-LEFT',
  "CENTER-CENTER" = 'CENTER-CENTER',
  "CENTER-RIGHT" = 'CENTER-RIGHT',
  "BOTTOM-LEFT" = 'BOTTOM-LEFT',
  "BOTTOM-CENTER" = 'BOTTOM-CENTER',
  "BOTTOM-RIGHT" = 'BOTTOM-RIGHT'
}
export class ZoneUrtoType {
  // Result Data
  public file?: FileItemType;
  public position?: ZonaUrtoEnum;
  public isSelected?: boolean;

  constructor(input?: Partial<ZoneUrtoType>) {
    if (input) {
      this.file = input && input.file ? new FileItemType(input.file) : undefined;
      this.position = input && input.position;
      this.isSelected = !!input && input.isSelected;
    }
  }
}

export class AniaItemType {
  public value: string = '';
  public label: string = '';

  constructor(input?: any) {
    if (input) {
      this.value = input.value;
      this.label = input.label;
    }
  }
}

export type FileActionFunction = (file?: FileItemType) => void;
export type setFilesActionFunction = (files: FileItemType[]) => any;

export enum EventValueEnum {
  SHOW_CAPTCHA = 'SHOW_CAPTCHA',
  CAI_FILLING_START = 'CAI_FILLING_START',
  FREE_CTP_CAI_FILLING = 'FREE_CTP_CAI_FILLING',
  IS_CLAIM_NOT_CONFIRMED = 'IS_CLAIM_NOT_CONFIRMED',
  NEGATION_REASON_CONFIRMED = 'NEGATION_REASON_CONFIRMED',
  IS_RCA = 'is_rca',
  IS_RCA_MANY = 'is_rca_many',
  IS_KASKO = 'is_kasko',
  POSITION_MODIFIED = 'POSITION_MODIFIED', //TODO: DA IMPLEMENTARE AL CAMBIO LUOGO
  WONT_PROCEED_DIGITAL_SIGN = 'WONT_PROCEED_DIGITAL_SIGN', //TODO: DA IMPLEMENTARE AL NON VOGLIO FIRMARE
  CAI_FILLING_END = 'CAI_FILLING_END', // TODO: AGGIUNGERE AL CLICK DELLA FIRMA
  FORWARD_COMMUNICATION = 'FORWARD_COMMUNICATION',
  CAI_TAKE_CONTROL_AFTER_FORWARD = 'CAI_TAKE_CONTROL_AFTER_FORWARD',
  CONFIRMED = 'HRM-02',
  MESSAGE_RECEIVED = 'MESSAGE_RECEIVED',
  MESSAGE_RX_FAILED = 'MESSAGE_RX_FAILED',
  FIRST_ACCESS = 'FIRST_ACCESS',
  CAPTCHA_SHOW = 'CAP-01',
  CAPTCHA_SUCCESS = 'CAP-02',
  CAPTCHA_ERROR = 'CAP-03',
  OPEN_CLAIM = 'OPEN_CLAIM',
  IS_CLAIM_CONFIRMED = 'IS_CLAIM_CONFIRMED',
  AUTH_CONFIRM_CHECK_CTP_CORRECT_PLATE = 'AUTH_CONFIRM_CHECK_CTP_CORRECT_PLATE',
  AUTH_CONFIRM_END_CANCEL = 'AUTH_CONFIRM_END_CANCEL',
  AUTH_CONFIRM_END_SUCCESS = 'AUTH_CONFIRM_END_SUCCESS',
  AUTH_CONFIRM_PLATE_CHECK_SUCCESS = 'AUTH_CONFIRM_PLATE_CHECK_SUCCESS',
  AUTH_CONFIRM_PREVIEW_ACCEPTED = 'AUTH_CONFIRM_PREVIEW_ACCEPTED',
  AUTH_CONFIRM_STEPS_COMPLETED = 'AUTH_CONFIRM_STEPS_COMPLETED',
  CAI_PAPER_UPLOADED = 'CAI_PAPER_UPLOADED',
  FREE_CTP_TOWARD_CAI_PAPER = 'FREE_CTP_TOWARD_CAI_PAPER',
  PROCEDURE_CANCELED = 'PROCEDURE_CANCELED',
  SIGNATURE_FOLDER_OUTCOME = 'SIGNATURE_FOLDER_OUTCOME',
  USER_SIGNATURE_OUTCOME = 'USER_SIGNATURE_OUTCOME'
}

export class EventCommType {
  // Result Data
  public receiving_timestamp: string = '';
  public timestamp: string = '';
  public value?: EventValueEnum;
  public env: string = '';
  public metadata: EvMetadataType = {};

  constructor(input?: any) {
    if (input) {
      this.receiving_timestamp = input.receiving_timestamp;
      this.timestamp = input.timestamp || moment();
      this.value = input.value;
      this.env = input.env;
      this.metadata = input.metadata;
    }
  }
}

export class InteractionType {
  // Result Data
  public _id: string = '';
  public file_type: string = '';
  public name: string = '';
  public value: string[] = [];
  public timestamp: string = '';
  public token: string = '';
  public metadata: MetadataType = new MetadataType()

  constructor(input?: any) {
    if (input) {
      this._id = input._id;
      this.file_type = input.file_type;
      this.name = input.name;
      this.value = input.value || [];
      this.timestamp = input.timestamp;
      this.token = input.token;
      this.metadata = new MetadataType(input.metadata)
    }
  }
}

export class ConducenteInfo {
  public alsoOwner?: BoolStringEnum;
  public y_contactInfo: ContactInfo = new ContactInfo();
  public n_contactInfo: ContactInfo = new ContactInfo();

  constructor(input?: any) {
    if (input) {
      this.alsoOwner = input.alsoOwner;
      this.y_contactInfo = new ContactInfo(input.y_contactInfo);
      this.n_contactInfo = new ContactInfo(input.n_contactInfo);
    }
  }
}


export class ControparteInfo {
  public alsoOwner?: BoolStringEnum;
  public y_ctp_contactInfo: ContactInfo = new ContactInfo();
  public n_ctp_contactInfo: ContactInfo = new ContactInfo();
  public dn_ctp_contactInfo: ContactInfo = new ContactInfo();

  constructor(input?: any) {
    if (input) {
      this.alsoOwner = input.alsoOwner;
      this.y_ctp_contactInfo = new ContactInfo(input.y_ctp_contactInfo);
      this.n_ctp_contactInfo = new ContactInfo(input.n_ctp_contactInfo);
      this.dn_ctp_contactInfo = new ContactInfo(input.dn_ctp_contactInfo);
    }
  }
}

export class
  OtherContactInfo {
  public isPresent?: BoolStringEnum;
  public contactList: ContactInfo[] = []
  constructor(input?: Partial<OtherContactInfo>) {
    if (input) {
      this.isPresent = input.isPresent;
      this.contactList = ((input && input.contactList) || []).map((f: Partial<ContactInfo>) => new ContactInfo(f))

    }
  }
}

export class RimorchioInfo {
  public trainavaRimorchio?: BoolStringEnum;
  public targa: string = '';
  public stato_immatricolazione: string = '';

  constructor(input?: any) {
    if (input) {
      this.trainavaRimorchio = input.trainavaRimorchio;
      this.targa = input.targa;
      this.stato_immatricolazione = input.stato_immatricolazione;
    }
  }
}

export class ContactInfo {
  public _id: string = '';
  public name: string = '';
  public surname: string = '';
  public phoneNumber: string = '';
  public email: string = '';
  public targa: string = '';
  public nameSurname: string = '';
  public description: string = '';
  public isPassenger?: BoolStringEnum;

  constructor(input?: any) {
    if (input) {
      this._id = input._id;
      this.name = input.name;
      this.surname = input.surname;
      this.phoneNumber = input.phoneNumber;
      this.email = input.email;
      this.targa = input.targa;
      this.nameSurname = input.nameSurname;
      this.description = input.description;
      this.isPassenger = input.isPassenger;
    }
  }
}

export interface ContactInfoItemUi {
  propName: keyof ContactInfo;
  label?: string;
  className?: string;
  type?: InputType;
  options?: ValueLabelOption<BoolStringEnum>[];
  otpionsLabel?: string;
}

export enum InputType {
  TEXT = 'TEXT',
  RADIO = 'RADIO',
  TEXTAREA = 'TEXTAREA',
}
export const otherPeople_contactInfoItems: ContactInfoItemUi[] = [{
  propName: 'nameSurname',
  label: 'Nome e cognome',
  className: 'upper'
}, {
  propName: 'phoneNumber',
  label: 'Numero di telefono',
  className: 'upper'
}, {
  propName: 'isPassenger',
  label: 'Passeggero del veicolo?',
  type: InputType.RADIO,
  options: booleanRadioOptions,
  otpionsLabel: 'Passeggero del veicolo?'
}]


export const otherVehicles_contactInfoItems: ContactInfoItemUi[] = [{
  propName: 'targa',
  label: 'Targa(*)',
  className: 'upper'
}, {
  propName: 'nameSurname',
  label: 'Nome e cognome',
  className: 'upper'
}, {
  propName: 'phoneNumber',
  label: 'Numero di telefono',
  className: 'upper'
}]

export const otherObjects_contactInfoItems: ContactInfoItemUi[] = [{
  propName: 'description',
  label: 'Descrizione',
  type: InputType.TEXTAREA,
}]


export const conducente_contactInfoItems: ContactInfoItemUi[] = [{
  propName: 'name',
  label: 'Il tuo nome (*)',
  className: 'upper'
}, {
  propName: 'surname',
  label: 'Il tuo cognome (*)',
  className: 'upper'
}, {
  propName: 'email',
  label: 'La tua email (*)'
}]

export const ctp_conducente_contactInfoItems: ContactInfoItemUi[] = [{
  propName: 'name',
  label: 'Nome (*)',
  className: 'upper'
}, {
  propName: 'surname',
  label: 'Cognome (*)',
  className: 'upper'
}, {
  propName: 'email',
  label: 'Email'
}]

export const conducente_noIntestatario_contactInfoItems: ContactInfoItemUi[] = [
  conducente_contactInfoItems[0],
  conducente_contactInfoItems[1], {
    propName: 'phoneNumber',
    label: 'Il tuo numero di telefono (*)'
  },
  conducente_contactInfoItems[2]
]

export const ctp_conducente_noIntestatario_contactInfoItems: ContactInfoItemUi[] = [
  ctp_conducente_contactInfoItems[0],
  ctp_conducente_contactInfoItems[1], {
    propName: 'phoneNumber',
    label: 'Numero di telefono (*)'
  },
  ctp_conducente_contactInfoItems[2]
]

const baseValidationSchema = {
  name: yup.string().required(messageRequired),
  surname: yup.string().required(messageRequired),
  email: yup.string().required(messageRequired).email(emailFormatMessage)
}

const completeValidationSchema = {
  ...baseValidationSchema,
  phoneNumber: yup.number().test(
    'len',
    numberFormatLength,
    (val) => {
      if (val === undefined) {
        return true;
      }
      return ((val.toString().length === 0 || (val.toString().length >= 8 && val.toString().length <= 16)))
    }
  ).typeError(numberFormat).required(messageRequired)
}

export const contactInfoSchema = yup.object().shape(baseValidationSchema);

export const contactInfoSchema_OptionalMail = yup.object().shape({
  ...baseValidationSchema,
  email: yup.string().email(emailFormatMessage)
});

export const contactCompleteInfoSchema = yup.object().shape(completeValidationSchema);

export const contactCompleteInfoSchema_OptionalMail = yup.object().shape({
  ...completeValidationSchema,
  email: yup.string().email(emailFormatMessage)
});

export const emptyValidationSchema = yup.object().shape({});

export const allPossibleZoneUrto: Partial<ZoneUrtoType>[] = [
  {
    position: ZonaUrtoEnum["TOP-LEFT"]
  }, {
    position: ZonaUrtoEnum["TOP-CENTER"]
  }, {
    position: ZonaUrtoEnum["TOP-RIGHT"]
  },
  {
    position: ZonaUrtoEnum["CENTER-LEFT"]
  }, {
    position: ZonaUrtoEnum["CENTER-CENTER"]
  }, {
    position: ZonaUrtoEnum["CENTER-RIGHT"]
  },
  {
    position: ZonaUrtoEnum["BOTTOM-LEFT"]
  }, {
    position: ZonaUrtoEnum["BOTTOM-CENTER"]
  }, {
    position: ZonaUrtoEnum["BOTTOM-RIGHT"]
  }
]

export enum CarInvolvedEnum {
  NS = 'NS',
  CTP = 'CTP',
  BOTH = 'BOTH',
}
export class BaremeInfo {
  public idNs?: string;
  public idCtp?: string;
  public description?: string;
  public assInRetro?: boolean;
  public ctpInRetro?: boolean;

  constructor(input?: Partial<BaremeInfo>) {
    this.idNs = input?.idNs;
    this.idCtp = input?.idCtp;
    this.description = input?.description;
    this.assInRetro = !!input?.assInRetro;
    this.ctpInRetro = !!input?.ctpInRetro;
  }

  fillFields(id?: string, description?: string, carInvolved?: CarInvolvedEnum) {
    if (carInvolved === CarInvolvedEnum.NS) {
      this.idNs = id;
    } else if (carInvolved === CarInvolvedEnum.CTP) {
      this.idCtp = id;
    } else if (carInvolved === CarInvolvedEnum.BOTH) {
      this.idNs = id;
      this.idCtp = id;
    }
    this.description = description;
  }
}

export const enum UserTypeEnum {
  IPER_NATURALE = 'IPER_NATURALE',
  SUPER_NATURALE = 'SUPER_NATURALE',
  NON_NATURALE = 'NON_NATURALE'
}