import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IncrementalInputContainer } from "../styled/Containers.style";

interface IncrementalInputProps {
    value: number;
    setValue: Function;
    minVal?: number;
    maxVal?: number;
}

const IncrementalInput = (props: IncrementalInputProps) => {
    const { value, setValue, minVal } = props
    const _minVal = minVal !== undefined ? minVal : 0

    const decrementHandler = () => {
        setValue((val: number) => {
            if (val <= _minVal) {
                return val
            } else {
                return (val - 1)
            }
        })
    }
    const incrementHandler = () => {
        setValue((val: number) => val + 1)
    }
    return (
        <IncrementalInputContainer>
            <button onClick={decrementHandler}>
                <FontAwesomeIcon icon={faMinus} />
            </button>
            <p className="val">{value}</p>
            <button onClick={incrementHandler}>
                <FontAwesomeIcon icon={faPlus} />
            </button>

        </IncrementalInputContainer>
    )
}

export default IncrementalInput