
import { useNavigate, useParams } from "react-router-dom";
import { Section } from "../components/styled/Containers.style";
import { selectCommunicationData } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { PageTitle, TextDefault } from "../components/styled/Text.style";
import { Vseparator, Vspace } from '../components/styled/Separators.style'
import { composeUrl } from "../utils/utils";
import { useState } from "react";
import IncrementalInput from "../components/input/incremental-input";
import { PATHS } from "../routes/CaiExperienceRouter";
import { saveCommunicationAct } from "../store/communication-data/actions";
import { CommunicationType, EventCommType, EventValueEnum } from "../types/communication/communicationDataType";
import PageContainerCmp from "./PageContainerCmp";

const VeicoliEPasseggeriPage = () => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let { token } = useParams();

    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData

    const [numOfVeichles, setNumOfVeichles] = useState<number>(communication.numOfVeichles || 2)
    const [numOfPasssengers, setNumOfPasssengers] = useState<number>(communication.numOfPasssengers || 0)

    /* istanbul ignore next */
    const goToNextStepHandler = () => {
        let newEvent: EventCommType = new EventCommType();

        if (numOfVeichles <= 1) newEvent = new EventCommType({ value: EventValueEnum.IS_KASKO });
        if (numOfVeichles === 2) newEvent = new EventCommType({ value: EventValueEnum.IS_RCA });
        if (numOfVeichles > 2) newEvent = new EventCommType({ value: EventValueEnum.IS_RCA_MANY });

        dispatch(saveCommunicationAct({
            data: new CommunicationType({
                ...communication,
                numOfVeichles,
                numOfPasssengers,
            }),
            events: [newEvent],
            callback: () => navigate(composeUrl(token, PATHS.RACCOLTA_DATI_CONTROPARTE))
        })
        )
    }

    /* istanbul ignore next */
    const goBackHandler = () => {
        let goBackEvent: EventCommType = new EventCommType({ value: EventValueEnum.FREE_CTP_CAI_FILLING });

        dispatch(saveCommunicationAct({
            events: [goBackEvent],
            callback: () => {
                navigate(composeUrl(token, PATHS.COSA_TI_CHIEDIAMO))
            }
        })
        )
    }

    return (
        <>
            <PageContainerCmp
                backStepHandler={goBackHandler}
                nextStepHandler={goToNextStepHandler}
                routePath={PATHS.VEICOLI_E_PASSEGGERI}
            >
                <>
                    <Section>
                        <PageTitle>Veicoli</PageTitle>
                        <Vspace></Vspace>
                        <TextDefault centered>Quanti sono i veicoli coinvolti, compreso il tuo?</TextDefault>
                        <Vspace qty={1.5}></Vspace>
                        <IncrementalInput
                            value={numOfVeichles}
                            setValue={setNumOfVeichles}
                            minVal={1} />
                    </Section>
                    <Vspace qty={1.5}/>
                    <Vseparator />
                    <Vspace qty={1.5}/>
                    <Section>
                        <PageTitle>Passeggeri</PageTitle>
                        <Vspace></Vspace>
                        <TextDefault centered>Quanti sono i passeggeri del tuo veicolo, escluso il guidatore?</TextDefault>
                        <Vspace qty={1.5}></Vspace>
                        <IncrementalInput
                            value={numOfPasssengers}
                            setValue={setNumOfPasssengers}
                            minVal={0} />
                    </Section>
                    <Vspace qty={1.5}/>
                </>
            </PageContainerCmp>
        </>
    )
}

export default VeicoliEPasseggeriPage