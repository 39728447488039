import { EventValueEnum } from "../types/communication/communicationDataType";
import { PATHS } from "../routes/CaiExperienceRouter";
import PageContainerCmp from "./PageContainerCmp";
import EndProcessSuccessCmp from "../components/end-process-success/EndProcessSuccessCmp";

const EndProcessPage = () => {

    return (
        <>
            <PageContainerCmp
                routePath={PATHS.END_PROCESS_PAGE}
                removeNextBtn={true}
                isForceLastPathname={true}
                eventsOnLoad={[EventValueEnum.CONFIRMED, EventValueEnum.OPEN_CLAIM]}>
                <EndProcessSuccessCmp />
            </PageContainerCmp>
        </>
    )
}

export default EndProcessPage