import { Store, createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
// We'll be using Redux Devtools. We can use the `composeWithDevTools()`
// directive so we can pass our middleware along with it
import { composeWithDevTools } from 'redux-devtools-extension'
import { createRootReducer, rootSaga } from './'
import { ApplicationState } from '../types/commonTypes'

export default function configureStore(initialState: ApplicationState): Store<ApplicationState> {
  const composeEnhancers = composeWithDevTools({})
  const sagaMiddleware = createSagaMiddleware()

  const store = createStore(
    createRootReducer(),
    // createRootReducer(history),
    // reHydrateStore(),
    composeEnhancers(applyMiddleware(sagaMiddleware))
  )

  sagaMiddleware.run(rootSaga)
  return store
}

const initialState = window.INITIAL_REDUX_STATE
export const store = configureStore(initialState)

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;