import { combineReducers } from 'redux'
import { all, fork } from 'redux-saga/effects'
import communicationDataSaga from './communication-data/sagas'
import { CommunicationDataReducer } from './communication-data/reducer'
import { RootState } from '../store/configureStore'

export const createRootReducer = () =>
  combineReducers({
    communicationData: CommunicationDataReducer
  })

export function* rootSaga() {
  yield all([fork(communicationDataSaga)])
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCommunicationData = (state: RootState) => {
  return state[`communicationData`]
};

