import styled from 'styled-components'

export const SplashScreen = styled.div `
    z-index: 999999999;
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: url("/realtime-splashpage.jpg") no-repeat;
    background-position: center;
    background-size: cover;
    &.make-disappear {
        animation: splashDisappearing 1s forwards; 
    }
    
    @keyframes splashDisappearing {
        0%,
        75% {
            opacity: 1;
        }
        
        99% {
            opacity: 0;
            width: 100vw;
            height: 100vh;
        }
        
        100% {
            opacity: 0;
            width: 0;
            height: 0;
            z-index: 0;
            overflow: hidden;
        }
    }
    `