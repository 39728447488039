
import { useNavigate, useParams } from "react-router-dom";
import { Section } from "../components/styled/Containers.style";
import { selectCommunicationData } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { PageTitle } from "../components/styled/Text.style";
import { useEffect, useState } from "react";
import { PATHS } from "../routes/CaiExperienceRouter";
import { saveCommunicationAct } from "../store/communication-data/actions";
import { CommunicationType, EventCommType, EventValueEnum, NoSignReasonEnum } from "../types/communication/communicationDataType";
import PageContainerCmp from "./PageContainerCmp";
import RadioOptionsCmp from "../components/radio/RadioOptionsCmp";
import { getValueLabelOptions, ValueLabelOption } from "../types/commonTypes";
import { composeUrl } from "../utils/utils";

const noSignOptions: ValueLabelOption<NoSignReasonEnum>[] = [{
    value: NoSignReasonEnum.INCORRECT_DOCUMENTS,
    label: 'I documenti sono scorretti/incompleti'
}, {
    value: NoSignReasonEnum.SHOULD_NOT_BE_INVOLVED,
    label: 'Non dovrei essere coinvolto in questa procedura'
}, {
    value: NoSignReasonEnum.OTHER,
    label: 'Altro'
}]

const NoSignPage = () => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let { token } = useParams();

    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData

    const [noSignReason, setNoSignReason] = useState<NoSignReasonEnum | undefined>()
    useEffect(() => {
        setNoSignReason(communication.noSignReason)
    }, [communication.noSignReason])

    const goToNextStepHandler = () => {
        const _pathname = composeUrl(token, PATHS.PROCEDURE_CANCELLED)
        const caiPaperEvent = new EventCommType({ value: EventValueEnum.FREE_CTP_TOWARD_CAI_PAPER })
        dispatch(saveCommunicationAct({
            data: new CommunicationType({
                ...communication,
                noSignReason
            }),
            events: [caiPaperEvent],
            callback: () => navigate(_pathname)
        })
        )
    }
    return (
        <>
            <PageContainerCmp
                nextStepHandler={goToNextStepHandler}
                routePath={PATHS.NO_SIGN}
                nextDisabled={!noSignReason}
            >
                <>
                    <Section>
                        <PageTitle>Non voglio firmare</PageTitle>
                    </Section>
                    <Section>
                        <RadioOptionsCmp
                            options={noSignOptions}
                            value={getValueLabelOptions(noSignOptions, noSignReason)}
                            className={'no-sign-reason-choise'}
                            onChange={(newChoise: ValueLabelOption<NoSignReasonEnum>) => setNoSignReason(newChoise.value)}
                        />
                    </Section>

                </>
            </PageContainerCmp>
        </>
    )
}

export default NoSignPage