import { useNavigate, useParams } from "react-router-dom";
import { invokeSignatureAct, saveCommunicationAct } from "../store/communication-data/actions";
import { useState } from "react";
import { PATHS } from "../routes/CaiExperienceRouter";
import FilesUploadListCmp from "../components/file/FilesUploadListCmp";
import { Section } from "../components/styled/Containers.style";
import { selectCommunicationData } from "../store";
import LoaderCmp from "../components/loader/LoaderCmp";
import { PageTitle, TextDefault } from "../components/styled/Text.style";
import { Vspace } from '../components/styled/Separators.style'
import PageContainerCmp from "./PageContainerCmp";
import { composeUrl } from "../utils/utils";
import { NextButton, SecondChoiseButton } from "../components/styled/Button.style";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { CommunicationType, EventCommType, EventValueEnum, FileItemType } from "../types/communication/communicationDataType";
import CancelConfirmIdentityModal from "../components/cancel-confirm-identity/CancelConfirmIdentityModal";

  /* istanbul ignore next */
const ConfirmIdentityUploadDocPage = () => {
    const dispatch = useAppDispatch();
    const communicationData = useAppSelector(selectCommunicationData)
    const { invokeSignatureMetadata, communication } = communicationData
    let navigate = useNavigate();
    let { token } = useParams();

    const [cancelModalOpened, setCancelModalOpened] = useState<boolean>(false)
    const [authConfirmDocsList, setAuthConfirmDocsList] = useState<FileItemType[]>(communication.authConfirmDocsList)

    const shouldDisableButton = () => {
        return !(authConfirmDocsList?.length);
    }

    const goToNextStepHandler = () => {
        const nextPath = composeUrl(token, PATHS.END_SIGNATURE_FORWARD_SUCCESS)
        const newEvent = new EventCommType({ value: EventValueEnum.CAI_FILLING_END })
        const authConfirmEvent = new EventCommType({ value: EventValueEnum.AUTH_CONFIRM_STEPS_COMPLETED })
        const data = new CommunicationType({
            ...communication,
            authConfirmDocsList
        })
        /* istanbul ignore next */
        dispatch(saveCommunicationAct({
            data,
            events: [newEvent, authConfirmEvent]
        }))
        /* istanbul ignore next */
        dispatch(invokeSignatureAct({
            data: null,
            callback: () => navigate(nextPath)
        }))
    }

    return (
        <>

            <CancelConfirmIdentityModal
                modalOpened={cancelModalOpened}
                onCloseHandler={() => setCancelModalOpened(false)} />

            <PageContainerCmp
                nextStepHandler={goToNextStepHandler}
                routePath={PATHS.CONFIRM_IDENTITY}
                removeNextBtn
            >
                <>
                    {invokeSignatureMetadata?.isLoading && <LoaderCmp className="cover cai-preview" />}
                    <Section>
                        <PageTitle>Documento di riconoscimento</PageTitle>
                        <Vspace></Vspace>
                        <TextDefault style={{'display': 'none'}}></TextDefault>
                        <TextDefault centered sm>Infine ti chiediamo di caricare una foto di un tuo documento di riconoscimento in corso di validità, <strong>preferibilmente la patente</strong>.</TextDefault>
                    </Section>

                    <Section>
                        <FilesUploadListCmp
                            files={authConfirmDocsList}
                            setFiles={setAuthConfirmDocsList}
                            fileType={"CONFIRM-IDENTITY"}
                            arrayDbPropSync={"authConfirmDocsList"}
                            textLabel={"Carica documento"}
                        />
                    </Section>
                    <TextDefault style={{'display': 'none'}}></TextDefault>

                    <Section>
                        <Vspace></Vspace>
                        <NextButton
                            disabled={shouldDisableButton()}
                            onClick={goToNextStepHandler}>Procedi</NextButton>
                    <TextDefault style={{'display': 'none'}}></TextDefault>
                        <Vspace></Vspace>
                        <SecondChoiseButton onClick={() => setCancelModalOpened(true)}>Non posso caricare il documento</SecondChoiseButton>
                    </Section>
                </>
            </PageContainerCmp>
        </>

    )
}

export default ConfirmIdentityUploadDocPage