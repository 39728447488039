import { useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { selectCommunicationData } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { saveCommunicationAct, captchaChallengeAct } from "../../store/communication-data/actions";
import { EventCommType, EventValueEnum } from "../../types/communication/communicationDataType";
import { hasBackendResponseErrors } from "../../utils/api";

const CaptchaComponent = () => {

    let { token } = useParams();
    const captchaRef = useRef<any>(null);
    const dispatch = useAppDispatch();
    const communicationData = useAppSelector(selectCommunicationData);
    const { captchaChallenge } = communicationData;

    useEffect(() => {
        /* istanbul ignore next */
        if (captchaRef?.current) {
            captchaRef.current.execute();
            document.body.classList.add('captcha-event');
        }
    }, [])

    let onChange = () => {
        /* istanbul ignore next */
        const token_captcha = captchaRef.current.getValue();
        /* istanbul ignore next */
        let newEvent = new EventCommType({ value: EventValueEnum.CAPTCHA_SHOW });
        captchaRef.current.reset();
        /* istanbul ignore next */
        let requestURL = `${window.REACT_APP_VTV_API_ENDPOINT}/api/realtime/communication/${token}/captcha-validate`;
        let resultEvent = new EventCommType({ value: EventValueEnum.CAPTCHA_ERROR });
        /* istanbul ignore next */
        axios.post(requestURL, { token_captcha })
            .then((res) => {
                if (hasBackendResponseErrors(res)) {
                    throw new Error(`Error validate captcha`)
                } else {
                    resultEvent = new EventCommType({ value: EventValueEnum.CAPTCHA_SUCCESS });
                    document.body.classList.remove('captcha-event');
                    dispatch(captchaChallengeAct(false));
                }
                dispatch(saveCommunicationAct({
                    events: [newEvent, resultEvent]
                }));
            })
            .catch((err) => {
                dispatch(captchaChallengeAct(false));
                console.error(err)
            })
    }

    return (
        captchaChallenge ? <ReCAPTCHA
            sitekey={window.REACT_APP_CAPTCHA_SITEKEY}
            onChange={onChange}
            ref={captchaRef}
            size="invisible"
        /> : <></>
    );
}

export default CaptchaComponent;