import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import CaiDownload from "../components/cai-download-cmp/CaiDownload";
import CrashIdBox from "../components/crashid-box/CrashIdBox";
import FooterCmp from "../components/footer/Footer";
import { InfoCard } from "../components/styled/Card.style";
import { CardIconContainer } from "../components/styled/Icon.style";
import { IncidentBanner } from "../components/styled/page-custom/CosaTiChiediamoPage.style";
import { SeparatorStandard, Vspace } from "../components/styled/Separators.style";
import { PageTitleStandard, TextDefault, TextLightB } from "../components/styled/Text.style";
import { PATHS } from "../routes/CaiExperienceRouter";
import { selectCommunicationData } from "../store";
import { useAppSelector } from "../store/hooks";
import { CaiCaseEnum } from "../types/communication/communicationDataType";
import PageContainerCmp from "./PageContainerCmp";

const RealtimeAccettazioneCaiPage = () => {
    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData

    const caiUrl = `${window.REACT_APP_VTV_API_ENDPOINT}/api/realtime/communication/${communication.token}/download-attachment/${communication.file_download_cai_id}`
    const tecnicoUrl = `${window.REACT_APP_VTV_API_ENDPOINT}/api/realtime/communication/${communication.token}/download-attachment/${communication.file_download_tecnico_id}`

    return (
        <>
            <PageContainerCmp
                routePath={PATHS.REALTIME_ACCETTAZIONE_CAI}
                removeNextBtn={true}
                data-testid="page-container"
            >
                <IncidentBanner data-testid="incident-banner">
                </IncidentBanner>

                <Vspace />

                {(communication.caiCaseEnum === CaiCaseEnum.CASE_1A && communication.caiAcceptanceIpernaturale === true) ?
                    <>
                        <TextDefault centered>Gentile {communication.assic_nome} {communication.assic_cognome},<br /><strong>hai accettato la constatazione amichevole proposta dalla controparte</strong>.<br />Il numero identificativo della richiesta di denuncia del sinistro avvenuto in data {communication.inc_data} è il seguente:</TextDefault>
                        <Vspace qty={2} />
                        <CrashIdBox crashId={communication.crash_id} />
                    </>
                    :
                    <TextDefault centered>Gentile {communication.assic_nome} {communication.assic_cognome},<br /><strong>hai accettato la constatazione amichevole proposta dalla controparte</strong>.</TextDefault>
                }

                <Vspace qty={2} />
                <SeparatorStandard />
                <Vspace />
                <PageTitleStandard centered>La procedura di firma è andata <br /> a buon fine.</PageTitleStandard>
                <Vspace />
                <TextDefault centered>Da qui puoi scaricare la copia del <strong>modulo di constatazione</strong> amichevole compilato e firmato, oltre ad un <strong>report tecnico</strong> che certifica il processo di firma digitale.</TextDefault>
                <Vspace />
                <CaiDownloadWrapper>
                    <CaiDownload data-testid="cai-url" url={caiUrl} imgPath={'/signed-cai-sample-blur.png'} />
                    <CaiDownload data-testid="tecnico-url" url={tecnicoUrl} imgPath={'/cai-report-blur.png'} />
                </CaiDownloadWrapper>
                <Vspace qty={6} />
                <InfoCard>
                    <CardIconContainer>
                        <FontAwesomeIcon icon={faPhone} />
                    </CardIconContainer>
                    {(communication.caiCaseEnum === CaiCaseEnum.CASE_1A && communication.caiAcceptanceIpernaturale === true) ?
                        <TextLightB>In caso di necessità puoi rivolgerti alla tua agenzia contattando il numero {communication.comp_tel_o_email} e indicando il riferimento della pratica sopra riportato.</TextLightB>
                        :
                        <TextLightB>In caso di necessità puoi rivolgerti alla tua agenzia.</TextLightB>
                    }
                </InfoCard>
                <Vspace qty={5} />
            </PageContainerCmp>
            <FooterCmp hasDownloadButtons={true} isRelativeTemplate={true} />
        </>

    )
}

const CaiDownloadWrapper = styled.div`
    display: flex;
    justify-content: center;
    column-gap: 35px;
`

export default RealtimeAccettazioneCaiPage