import axios from 'axios';

export interface ServerResponseType<T> {
    data: {
        data: T,
        opResult: boolean,
        metadata?: any
    }
 }

export function sendPost(_requestURL: string, _body: any) {
    return axios.post(_requestURL, _body);
}

export function sendGet(_requestURL: string) {
    return axios.get(_requestURL);
}

export function sendDelete(_requestURL: string) {
    return axios.delete(_requestURL);
}

export const getBackendData = (resp: any): any => {
    return resp && resp.data && resp.data.data;
}

export const getBackendFullResponse = (resp: any): any => {
    return resp && resp.data;
}

export const hasBackendResponseErrors = (resp: any) => {
    const respData = resp && resp.data;
    if (!respData || !respData.opResult || resp.error) {
        return true;
    }

    const backendErrors = respData.errors;
    return !!(backendErrors && backendErrors.length);
}

export const getBackendErrorCode = (resp: any) => {
    return resp && resp.data && resp.data.error && resp.data.error.code;
}

/* istanbul ignore next */
export const getBackendErrorCodeFromHttpErr = (err: any) => {
    return getBackendErrorCode(err && err.response)
}

export const getBackendErrorMetadataFromHttpErr = (err: any) => {
    /* istanbul ignore next */
    return err?.response?.data?.error?.metadata;
}

// /* Dev case:
// in dev mode (npm start) the default port is 3000, thus we are in localhost and we want to replace REACT_APP_VTV_API_ENDPOINT with .env.dev file
// in local prod mode (running the server) the port is 3006. In that case we do NOT want to replace REACT_APP_VTV_API_ENDPOINT with .env.dev file
// */
// (() => {
//     if (window.location.href.indexOf('localhost') >=0 && window.location.href.indexOf('localhost:3006') < 0) {
//         window.REACT_APP_VTV_API_ENDPOINT = process.env.REACT_APP_VTV_API_ENDPOINT
//         window.REACT_APP_ENV = process.env.REACT_APP_ENV
//     }
// })()

// export const API_ENDPOINT = window.REACT_APP_VTV_API_ENDPOINT;
