import React from 'react'
import styled from 'styled-components';

export interface CrashIdBoxProps {
    crashId: string;
}

const CrashIdBox = ({ crashId }: CrashIdBoxProps) => {
    return (
        <Wrapper>
            {crashId}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    text-align: center;
    max-width: 85%;
    margin: auto;
    font-family: Arial;
    color: #3079C9;
    font-weight: bold;
    padding: 15px 25px;
    border: solid #3079C9 1px;
    width: min-content;
    line-height: 22px;
    font-size: 17px;
}`

export default CrashIdBox