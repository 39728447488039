import { EventValueEnum } from "../types/communication/communicationDataType";
import { PATHS } from "../routes/CaiExperienceRouter";
import PageContainerCmp from "./PageContainerCmp";
import EndProcessSuccessCmp from "../components/end-process-success/EndProcessSuccessCmp";
import { useAppSelector } from "../store/hooks";
import { selectCommunicationData } from "../store";
import { localDate } from "../utils/time.utils";
import { useMemo } from "react";
import { TextDefault } from "../components/styled/Text.style";
import { Vspace } from "../components/styled/Separators.style";

/* istanbul ignore next */
const EndProcessCaiPaperPage = () => {

    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData

    const mainSentenceCmp = useMemo(() => (
        <>
            <TextDefault intM centered={true}>La tua pratica di denuncia del sinistro avvenuto in data <strong>{localDate(communication?.creation_date)}</strong> è andata a buon fine.</TextDefault>
            <Vspace qty={1}></Vspace>
            <TextDefault intM centered={true}><strong>ID Pratica: {communication?.crash_id}</strong></TextDefault>
            <Vspace qty={1}></Vspace>
        </>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [communication?.token])
    return (
        <>
            <PageContainerCmp
                routePath={PATHS.END_PROCESS_CAI_PAPER_PAGE}
                removeNextBtn={true}
                isForceLastPathname={true}
                eventsOnLoad={[EventValueEnum.CONFIRMED, EventValueEnum.OPEN_CLAIM]}>
                <EndProcessSuccessCmp
                    routePath={PATHS.END_PROCESS_CAI_PAPER_PAGE}
                    agencyPhone={communication.tel_o_mail}
                    title="Grazie, abbiamo avviato la tua procedura."
                    mainSentence={mainSentenceCmp} />
            </PageContainerCmp>
        </>
    )
}

export default EndProcessCaiPaperPage