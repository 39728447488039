import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { loadCommunicationErrorAct, loadCommunicationSuccessAct, saveCommunicationErrorAct, saveCommunicationSuccessAct, loadAniaErrorAct, loadAniaSuccessAct, saveCommunicationEndAct, invokeSignatureEndAct } from './actions';
import { AxiosResponse } from 'axios';
import { getBackendData, getBackendErrorCode, getBackendErrorCodeFromHttpErr, getBackendErrorMetadataFromHttpErr, hasBackendResponseErrors, sendGet, sendPost, ServerResponseType } from '../../utils/api';
import { CommunicationDatatActionTypes } from '../../types/actions/communication-data-action';
import { CommunicationDataType, CommunicationType, UserRoleEnum } from '../../types/communication/communicationDataType';
import { computeBaremeInfo } from '../../utils/bareme.utils';
import { composeUrl, isIpernaturale, navigateToUrl, ERROR_CODES, getExpiredPathFromComm, tqError } from '../../utils/utils';
import { PATHS } from '../../routes/CaiExperienceRouter';
import { TemplateTypeEnum } from '../../types/commonTypes';
import { AUTH_CONFIRM_BASE_ROUTH, AUTH_CONFIRM_PATHS } from '../../routes/AuthConfirmRouter';
import { CaiCaseEnum } from '../../types/communication/communicationDataType';

import { toast } from 'react-toastify';
import { getJsonLocalStorageAppState } from '../../utils/utils.store';

const SIGNATURE_ERROR_MESSAGE = 'Errore in fase di richiesta firma. Si prega di riprovare';

  /* istanbul ignore next */
const ToastNotifyError = () => {
    tqError({
        errors_elements: [window?.location?.href],
        errors_id: ['cai:errore in fase di richiesta firma'],
        errors_messages: [SIGNATURE_ERROR_MESSAGE],
        errors_type: ['user']
    })

    toast.error(SIGNATURE_ERROR_MESSAGE, {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "dark",
    });
}

export const getStateCommunicationData = (state: any) => state.communicationData

  /* istanbul ignore next */
export const getOptionalForceLocalStoragePath = () => {
    const localStorageAppState = getJsonLocalStorageAppState()
    return (localStorageAppState?.isForwarded && localStorageAppState?.forceLastPathname)
}

  /* istanbul ignore next */
export const manageCommunicationGoToForcePath = (action: any, communicationRes: Partial<CommunicationDataType>,
    token: string) => {
    const payloadNavigate = action.payload?.navigate
    const lo_forcePath = getOptionalForceLocalStoragePath()
    if (payloadNavigate) {
        const _communication = communicationRes?.communication;
        if (lo_forcePath) {
            navigateToUrl(payloadNavigate, lo_forcePath)
        } else if (_communication?.forceLastPathname) {
            navigateToUrl(payloadNavigate, _communication.forceLastPathname)
        } else if (!_communication?.forceLastPathname && !_communication?.lastPathname) {
            const newUrl = composeUrl(token, PATHS.WELCOME_FIRST_PAGE)
            navigateToUrl(payloadNavigate, newUrl)
        }
    }
}

export const manageAuthAndConfirmNextPath = (payloadNavigate: any, _communication: Partial<CommunicationType>) => {
    /* istanbul ignore next */
    if (_communication?.lastPathname) {
        navigateToUrl(payloadNavigate, _communication.lastPathname)
    } else {
        /* istanbul ignore next */
        const newUrl = composeUrl(_communication.token, AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_CLAIM, AUTH_CONFIRM_BASE_ROUTH)
        // const newUrl = '/auth-confirm/AA_B_CTP_AUTH_CONFIRM/authorize-confirm-claim'
        navigateToUrl(payloadNavigate, newUrl)
    }
}

  /* istanbul ignore next */
export const manageCommunicationGoToPath = (action: any, communicationRes: Partial<CommunicationDataType>,
    token: string) => {
    const payloadNavigate = action.payload?.navigate
    const localStorageForcePath = getOptionalForceLocalStoragePath()
    if (payloadNavigate) {
        const _communication = communicationRes?.communication;
        /* istanbul ignore next */
        if (_communication?.type === TemplateTypeEnum.REALTIME_ACCETTAZIONE_CAI) {
            navigateToUrl(payloadNavigate, PATHS.REALTIME_ACCETTAZIONE_CAI)
        } else /* istanbul ignore next */ if (_communication?.type === TemplateTypeEnum.CONFIRM_AND_AUTHORIZE_USER) {
            manageAuthAndConfirmNextPath(payloadNavigate, _communication)
            // navigateToUrl(payloadNavigate, PATHS.REALTIME_ACCETTAZIONE_CAI)
        } else if (localStorageForcePath) {
            navigateToUrl(payloadNavigate, localStorageForcePath)
        } else if (_communication?.forceLastPathname) {
            navigateToUrl(payloadNavigate, _communication.forceLastPathname)
        } else if (_communication?.lastPathname) {
            navigateToUrl(payloadNavigate, _communication.lastPathname)
        } else {
            const newUrl = composeUrl(token, PATHS.WELCOME_FIRST_PAGE)
            navigateToUrl(payloadNavigate, newUrl)
        }
    }
}

export function* handlePollingCommunicationCall(action: any) {
    /* avoid polling for template TemplateTypeEnum.CONFIRM_AND_AUTHORIZE_USER */
    const stateCommunicationData: CommunicationDataType = yield select(getStateCommunicationData);
    const { communication } = stateCommunicationData
    /* istanbul ignore next */
    if (communication?.type === TemplateTypeEnum.CONFIRM_AND_AUTHORIZE_USER) {
        return
    }

    const token = action.payload?.token
    try {
        let requestURL = `${window.REACT_APP_VTV_API_ENDPOINT}/api/realtime/communication/${token}`;
        /* istanbul ignore next */
        let res: AxiosResponse<ServerResponseType<any>> = yield call(sendGet, requestURL);

        if (!hasBackendResponseErrors(res)) {
            const communicationRes = getBackendData(res);
            manageCommunicationGoToForcePath(action, communicationRes, token)
        }
    } catch (err) {
        /* istanbul ignore next */
        console.error('Error while ')
    }
}

export function* handleLoadCommunicationCall(action: any) {

    const token = action.payload?.token

    try {
        let requestURL = `${window.REACT_APP_VTV_API_ENDPOINT}/api/realtime/communication/${token}`;
        let res: AxiosResponse<ServerResponseType<any>> = yield call(sendGet, requestURL);

        if (hasBackendResponseErrors(res)) {
            const errCode = getBackendErrorCode(res)
            yield put(loadCommunicationErrorAct(errCode));
        } else {
            const communicationRes = getBackendData(res);

            yield put(loadCommunicationSuccessAct(communicationRes));
            manageCommunicationGoToPath(action, communicationRes, token)
        }
    } catch (err) {
        /* istanbul ignore next */
        const errCode = getBackendErrorCodeFromHttpErr(err)
        /* istanbul ignore next */
        if (errCode === ERROR_CODES.TOKEN_EXPIRED) {
            const comm = new CommunicationType({ token })
            yield put(loadCommunicationSuccessAct({ communication: comm }));

            const errMetadata: any = getBackendErrorMetadataFromHttpErr(err)
            comm.type = errMetadata?.type
            const newUrl = getExpiredPathFromComm(comm)
            action.payload?.navigate && action.payload.navigate(newUrl)
        } else {
            /* istanbul ignore next */
            yield put(loadCommunicationErrorAct());
        }
    }
}

export function* handleLoadAniaCall(action: any) {
    const stateCommunicationData: CommunicationDataType = yield select(getStateCommunicationData);
    const { communication } = stateCommunicationData
    const token = communication?.token

    try {
        let requestURL = `${window.REACT_APP_VTV_API_ENDPOINT}/api/realtime/communication/${token}/ania-companies`;
        let res: AxiosResponse<ServerResponseType<any>> = yield call(sendGet, requestURL);
        if (hasBackendResponseErrors(res)) {
            const errCode = getBackendErrorCode(res);
            yield put(loadAniaErrorAct(errCode));
        } else {
            const aniaRes = getBackendData(res);
            yield put(loadAniaSuccessAct(aniaRes));
        }
    } catch (err) {
        /* istanbul ignore next */
        yield put(loadAniaErrorAct());
    }
}

    /* istanbul ignore next */
export function* handleInvokeSignatureCall(action: any) {
    const stateCommunicationData: CommunicationDataType = yield select(getStateCommunicationData);
    const { communication, ctp } = stateCommunicationData
    const token = communication?.token
    let signResult = false;

    try {
      let requestURL = `${window.REACT_APP_VTV_API_ENDPOINT}/api/realtime/communication/${token}`;
      if (communication.caiCaseEnum === CaiCaseEnum.CASE_1B ||
        (communication.caiCaseEnum === CaiCaseEnum.CASE_2 && communication.userRole === UserRoleEnum.CTP)  ||
          (communication.caiCaseEnum === CaiCaseEnum.CASE_1A && communication.userRole === UserRoleEnum.CTP)) {
        requestURL = `${requestURL}/invoke-signature`
      } else if (communication.caiCaseEnum === CaiCaseEnum.CASE_2 && communication.userRole === UserRoleEnum.USER_1) {
        requestURL = `${requestURL}/case2-invoke-ctp-auth-and-sign`
      } else {
        requestURL = `${requestURL}/case1a-invoke-ctp-auth-and-sign`
      }
        if (isIpernaturale(communication) && ctp?.token) {
            requestURL = `${requestURL}?ctpToken=${ctp.token}`
        }
        let res: AxiosResponse<ServerResponseType<any>> = yield call(sendGet, requestURL);
        if (hasBackendResponseErrors(res)) {
            ToastNotifyError();
        } else {
            signResult = true
            if (action.payload?.callback) {
                yield put(action.payload?.callback())
            }
        }
        yield put(invokeSignatureEndAct());
    } catch (err) /* istanbul ignore next */ {
        console.log(err)
        if (signResult === false) {
            ToastNotifyError();
        }
        yield put(invokeSignatureEndAct());
    }
}

export function* handleSaveCommunicationCall(action: any) {

    const stateCommunicationData: CommunicationDataType = yield select(getStateCommunicationData);
    const { communication } = stateCommunicationData
    const token = communication?.token
    const updatedCommunication = action.payload?.data

    if (action.payload?.computeBareme) {
        updatedCommunication.baremeInfo = computeBaremeInfo(updatedCommunication.baremeResponses)
    }

    const body = {
        communication: updatedCommunication,
        events: action.payload?.events
    }

    try {
        let requestURL = `${window.REACT_APP_VTV_API_ENDPOINT}/api/realtime/communication/${token}`;
        let res: AxiosResponse<ServerResponseType<any>> = yield call(sendPost, requestURL, body);
        /* istanbul ignore next */
        if (hasBackendResponseErrors(res)) {
            const errCode = getBackendErrorCode(res)
            yield put(saveCommunicationErrorAct(errCode));
        } else /* istanbul ignore next */ {
            // const communicationRes = getBackendData(res);
            if (action.payload?.data) {
                yield put(saveCommunicationSuccessAct(body));
            } else {
                yield put(saveCommunicationEndAct());
            }
            if (action.payload?.callback) {
                yield put(action.payload?.callback())
            }
        }
    } catch (err) {
        /* istanbul ignore next */
        yield put(saveCommunicationErrorAct());
    }
}

export function* watchLoadCommunicationRequest() {
    yield takeEvery([CommunicationDatatActionTypes.LOAD_COMMUNICATION], handleLoadCommunicationCall)
}
export function* watchExecutePollingRequest() {
    yield takeEvery([CommunicationDatatActionTypes.EXECUTE_POLLING], handlePollingCommunicationCall)
}
export function* watchLoadAniaRequest() {
    yield takeEvery([CommunicationDatatActionTypes.LOAD_ANIA], handleLoadAniaCall)
}
export function* watchInvokeSignatureRequest() {
    yield takeEvery([CommunicationDatatActionTypes.INVOKE_SIGNATURE], handleInvokeSignatureCall)
}
export function* watchSaveCommunicationRequest() {
    yield takeEvery([CommunicationDatatActionTypes.SAVE_COMMUNICATION, CommunicationDatatActionTypes.SAVE_COMMUNICATION_IN_BACKGROUND], handleSaveCommunicationCall)
}

export function* communicationDataSaga() {
    yield all([fork(watchLoadCommunicationRequest), fork(watchLoadAniaRequest),
    fork(watchSaveCommunicationRequest), fork(watchInvokeSignatureRequest),
    fork(watchExecutePollingRequest)])
}

export default communicationDataSaga;
