import { useState } from "react";
import { ValueLabelOption, createValueLabelOption } from "../../types/commonTypes";
import { PRIVACY_LABEL } from "../common-cmp";
import PrivacyCheckCmp from "../../components/privacy/PrivacyCheckCmp";

export const usePrivacyCheckCmp = (initialValue: boolean) => {
    const [privacyChecked, setPrivacyChecked] = useState<ValueLabelOption<boolean>>(createValueLabelOption(initialValue, PRIVACY_LABEL))

    const privacyCmp = <PrivacyCheckCmp
        privacyChecked={privacyChecked}
        setPrivacyChecked={setPrivacyChecked} />

    return {
        privChecked: privacyChecked,
        PrivCheckedCmp: privacyCmp
    }
}