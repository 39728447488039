
import { useNavigate, useParams } from "react-router-dom";
import { selectCommunicationData } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { composeUrl } from "../utils/utils";
import { PATHS } from "../routes/CaiExperienceRouter";
import { saveCommunicationAct } from "../store/communication-data/actions";
import { CommunicationType, ZoneUrtoType } from "../types/communication/communicationDataType";
import PageContainerCmp from "./PageContainerCmp";
import { Section } from "../components/styled/Containers.style";
import { PageTitle, TextDefault } from "../components/styled/Text.style";
import { Vspace } from "../components/styled/Separators.style";
import { useEffect, useMemo, useState } from "react";
import ZoneUrtoCmp from "../components/zone-urto/ZoneUrtoCmp";
import { AdditionalCard, AdditionalCardText } from "../components/styled/Card.style";

const CtpZoneUrtoFotoPage = () => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let { token } = useParams();

    const communicationData = useAppSelector(selectCommunicationData)
    const { communication, ctp } = communicationData
    const [zoneUrto, setZoneUrto] = useState<ZoneUrtoType[]>([])
    const zoneUrtoValid = useMemo(() => zoneUrto.length > 0, [zoneUrto.length])
    const nextBtnDisabled = !zoneUrtoValid;
    
    const goToNextStepHandler = () => {
        dispatch(saveCommunicationAct({
            data: new CommunicationType({
                ...communication,
                ctp_zoneUrto: zoneUrto
            }),
            callback: () => navigate(composeUrl(token, PATHS.FOTO_DEL_CONTESTO))
        })
        )
    }

    useEffect(() => {
        // iniialize zone d'urto
        let initZoneUrto = communication.ctp_zoneUrto;
        if (!initZoneUrto?.length) {
            initZoneUrto = [new ZoneUrtoType({
                position: ctp.main_impact_point,
                isSelected: true
            })]
        }
        setZoneUrto(initZoneUrto)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <PageContainerCmp
                nextStepHandler={goToNextStepHandler}
                routePath={PATHS.CTP_ZONE_URTO_E_FOTO}
                nextDisabled={nextBtnDisabled}
            >
                <>
                    <Section>
                        <PageTitle>Zone d’urto e foto della controparte</PageTitle>
                        <Vspace></Vspace>
                        <TextDefault centered>Cliccando sul veicolo puoi aggiungere/rimuovere zone di urto della controparte e <strong>associare delle foto.</strong></TextDefault>
                        <TextDefault centered>Le eventuali aree già evidenziate sono rilevate dalla black box.</TextDefault>
                    </Section>

                    <Section>
                        <ZoneUrtoCmp
                            zoneUrto={zoneUrto}
                            setZoneUrto={setZoneUrto}
                        />
                    </Section>

                    <Section>
                        <AdditionalCard>
                            <AdditionalCardText>Per privacy è vietato fotografare persone.</AdditionalCardText>
                        </AdditionalCard>
                    </Section>
                </>
            </PageContainerCmp>
        </>
    )
}

export default CtpZoneUrtoFotoPage