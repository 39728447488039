import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export interface CaiDownloadType {
    imgPath: string;
    url?: string;
}

const CaiDownload = ({ imgPath, url }: CaiDownloadType) => {
    return (
        <a target="_blank" href={url} rel="noreferrer">
            <Wrapper imgPath={imgPath}>
                <DownloadBtn><FontAwesomeIcon icon={faDownload} /></DownloadBtn>
            </Wrapper>
        </a>
    )
}

const Wrapper = styled.div<CaiDownloadType>`
    position: relative;
    width: 100px;
    height: 150px;
    border: 2px solid #3079C9;
    background-image: url(${props => props.imgPath});
    background-size: cover;
`

const DownloadBtn = styled.div`
    position: absolute;
    bottom: -10px;
    right: -10px;
    height: 40px;
    width: 40px;
    background-color: #3079C9;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        color: #fff;
    }
`

export default CaiDownload