import { useState, useEffect } from "react"
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { selectCommunicationData } from "../../store"
import { useAppSelector } from "../../store/hooks"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalContentProps } from "../../types/commonTypes"
import { RimorchioInfo } from "../../types/communication/communicationDataType"
import InputCmp from "../input/InputCmp"
import { ContactModalInfoTitle, ModalConfirmButton } from "../styled/Modal.style"
import { ModalContactActionBtn } from "../styled/page-custom/Cai.style";
import { isPlateAlphanumericOnly, isPlateLengthCorrect } from "../../utils/utils";

const RimorchioModalCmp = ({ onConfirmAndCloseHandler, initValue }: ModalContentProps<Partial<RimorchioInfo>>) => {
    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData
    const [targa, setTarga] = useState<string>(communication?.rimorchioInfo?.targa || initValue?.targa)
    const [stato_immatricolazione, setStato_immatricolazione] = useState<string>(communication?.rimorchioInfo?.stato_immatricolazione || initValue?.stato_immatricolazione)
    const [formErrors, setFormErrors] = useState<any>()

    const removePlate = () => {
        setTarga("");
        setStato_immatricolazione("");
    }

    /* istanbul ignore next */
    useEffect(() => {
        if (targa) {
            if (!isPlateAlphanumericOnly(targa))
                return setFormErrors({ "contact-info-rimorchio-targa": { message: 'La targa deve contenere solo caratteri alfanumerici.' } })
            if (!isPlateLengthCorrect(targa))
                return setFormErrors({ "contact-info-rimorchio-targa": { message: 'La targa deve essere lunga almeno 6 cifre.' } })
        }
        setFormErrors(null)
    }, [targa])

    return (
        <>
            <ContactModalInfoTitle><p>Rimorchio</p></ContactModalInfoTitle>
            <InputCmp
                value={targa}
                setValue={(val: string) => setTarga(val.toUpperCase())}
                name={`contact-info-rimorchio-targa`}
                label={'N. di targa o telaio'}
                labelClassName="marked"
                className={`contact-info small`}
                containerClassName="mb"
                errors={formErrors}
            />
            <InputCmp
                value={stato_immatricolazione}
                setValue={(val: string) => setStato_immatricolazione(val.toUpperCase())}
                name={`contact-info-rimorchio-immatricolazione`}
                label={'Stato d’immatricolazione'}
                labelClassName="marked"
                className={`contact-info small`}
                containerClassName="mb"
            />
            <ModalContactActionBtn
                onClick={removePlate}>
                <FontAwesomeIcon icon={faTrash} />
                Elimina questo elemento
            </ModalContactActionBtn>
            <ModalConfirmButton
                className="with-m"
                onClick={() => onConfirmAndCloseHandler && onConfirmAndCloseHandler({ targa, stato_immatricolazione })}
            > Conferma</ModalConfirmButton>

        </>
    )
}

export default RimorchioModalCmp