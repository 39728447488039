import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate, useParams } from "react-router-dom"
import { ModalContentProps } from "../../types/commonTypes"
import { composeUrl } from "../../utils/utils"
import { SecondChoiseButton } from "../styled/Button.style"
import { Section } from "../styled/Containers.style"
import { IconContainer } from "../styled/Icon.style"
import { ModalConfirmButton } from "../styled/Modal.style"
import { Vspace } from "../styled/Separators.style"
import { TextDefaultSmall } from "../styled/Text.style"
import { AUTH_CONFIRM_BASE_ROUTH, AUTH_CONFIRM_PATHS } from "../../routes/AuthConfirmRouter"
import { EventCommType, EventValueEnum } from "../../types/communication/communicationDataType"
import { saveCommunicationAct } from "../../store/communication-data/actions"
import { useAppDispatch } from "../../store/hooks"

/* istanbul ignore next */
export const CancelAuthorizeConfirmClaimModalContent = ({ close, additionalData }: ModalContentProps<null>) => {
    const dispatch = useAppDispatch();
    const { token } = useParams();
    const navigate = useNavigate();

    const evVal = additionalData?.event || EventValueEnum.AUTH_CONFIRM_END_CANCEL

    const endProcessHandler = () => {
        /* istanbul ignore next */
        const nextPath = composeUrl(token, AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_END_CANCEL, AUTH_CONFIRM_BASE_ROUTH)
        /* istanbul ignore next */
        const newEvent = new EventCommType({ value: evVal })
        /* istanbul ignore next */
        dispatch(saveCommunicationAct({
            events: [newEvent],
        }))
        // Go in any case to the Cancel path
        navigate(nextPath)
    }

    return (
        <>
            <Section>
                <IconContainer>
                    <FontAwesomeIcon icon={faInfoCircle} />
                </IconContainer>
            </Section>

            <Section>
                <TextDefaultSmall>Questo passaggio è necessario per procedere con la Constatazione Amichevole digitale.<br /><br />Vuoi tornare indietro per riprovare?</TextDefaultSmall>
            </Section>

            <Vspace qty={2}></Vspace>

            <Section>
                <ModalConfirmButton onClick={close}>TORNO INDIETRO</ModalConfirmButton>
                <Vspace></Vspace>
                <SecondChoiseButton
                    onClick={endProcessHandler}
                >Termina la procedura</SecondChoiseButton>
            </Section>

        </>
    )
}
