import ZoomableImgCmp from "../img/ZoomableImgCmp"

interface CaiPreviewItemCmpProps {
  imgSrc: string
}

const CaiPreviewItemCmp = (props: CaiPreviewItemCmpProps) => {
  const { imgSrc } = props;

  return (
    <>
    <ZoomableImgCmp
      imgSrc={imgSrc}
    ></ZoomableImgCmp>
    </>
  )
}

export default CaiPreviewItemCmp