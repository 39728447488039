import { CommunicationDatatActionTypes } from "../../types/actions/communication-data-action";
import { CommunicationDataType } from "../../types/communication/communicationDataType";

export const initialState: CommunicationDataType = new CommunicationDataType();

const reducer = (state = initialState, action: any): CommunicationDataType => {
    let newState = new CommunicationDataType();

    switch (action.type) {
        case CommunicationDatatActionTypes.LOAD_COMMUNICATION:
            newState = new CommunicationDataType({
                ...state,
                captchaChallenge: true,
                loadCommunicationMetadata: {
                    isLoading: true,
                    errorCode: null,
                    error: false
                }
            })
            return newState;

        case CommunicationDatatActionTypes.LOAD_COMMUNICATION_SUCCESS:
            const _communication = (action.payload && action.payload.communication);
            const _ctp = (action.payload && action.payload.ctp);
            newState = new CommunicationDataType({
                ...state,
                communication: _communication,
                ctp: _ctp,
                loadCommunicationMetadata: {
                    isLoading: false,
                    errorCode: null,
                    error: false
                }
            });
            return newState;

        case CommunicationDatatActionTypes.LOAD_COMMUNICATION_ERROR:
            newState = new CommunicationDataType({
                ...state,
                loadCommunicationMetadata: {
                    isLoading: false,
                    errorCode: action.payload,
                    error: true
                }
            });
            return newState;

        case CommunicationDatatActionTypes.INVOKE_SIGNATURE:
            newState = new CommunicationDataType({
                ...state,
                invokeSignatureMetadata: {
                    isLoading: true,
                    errorCode: null,
                    error: false
                }
            })
            return newState;

        case CommunicationDatatActionTypes.INVOKE_SIGNATURE_END:
            newState = new CommunicationDataType({
                ...state,
                invokeSignatureMetadata: {
                    isLoading: false,
                    errorCode: null,
                    error: false
                }
            })
            return newState;

        case CommunicationDatatActionTypes.SAVE_COMMUNICATION:
            newState = new CommunicationDataType({
                ...state,
                saveCommunicationMetadata: {
                    isLoading: true,
                    errorCode: null,
                    error: false
                }
            })
            return newState;
        case CommunicationDatatActionTypes.SAVE_COMMUNICATION_SUCCESS:
            const updated_communication = (action.payload && action.payload.communication);

            newState = new CommunicationDataType({
                ...state,
                communication: updated_communication,
                saveCommunicationMetadata: {
                    isLoading: false,
                    errorCode: null,
                    error: false
                }
            });
            return newState;

        case CommunicationDatatActionTypes.SAVE_COMMUNICATION_END:

            newState = new CommunicationDataType({
                ...state,
                saveCommunicationMetadata: {
                    isLoading: false,
                    errorCode: null,
                    error: false
                }
            });
            return newState;

        case CommunicationDatatActionTypes.SAVE_COMMUNICATION_ERROR:
            newState = new CommunicationDataType({
                ...state,
                saveCommunicationMetadata: {
                    isLoading: false,
                    errorCode: action.payload,
                    error: true
                }
            });
            return newState;

        case CommunicationDatatActionTypes.CAI_VALIDATION_UPDATE:
            const newValidationState = action?.payload || {}
            const updtValidationState = {
                ...state.caiValidationInfo,
                ...newValidationState
            }
            newState = new CommunicationDataType({
                ...state,
                caiValidationInfo: updtValidationState,
                saveCommunicationMetadata: {
                    isLoading: false,
                    errorCode: action.payload,
                    error: true
                }
            });
            return newState;

        case CommunicationDatatActionTypes.CAPTCHA_CHALLENGE:
            newState = new CommunicationDataType({
                ...state,
                captchaChallenge: action.payload
            });
            return newState;

        case CommunicationDatatActionTypes.LOAD_ANIA:
            newState = new CommunicationDataType({
                ...state,
                aniaoptions: [],
                loadCommunicationMetadata: {
                    isLoading: true,
                    errorCode: null,
                    error: false
                }
            })
            return newState;

        case CommunicationDatatActionTypes.LOAD_ANIA_SUCCESS:
            const _aniaoptions = action.payload;
            newState = new CommunicationDataType({
                ...state,
                aniaoptions: _aniaoptions,
                loadCommunicationMetadata: {
                    isLoading: false,
                    errorCode: null,
                    error: false
                }
            });
            return newState;

        case CommunicationDatatActionTypes.LOAD_ANIA_ERROR:
            newState = new CommunicationDataType({
                ...state,
                loadCommunicationMetadata: {
                    isLoading: false,
                    errorCode: action.payload,
                    error: true
                }
            });
            return newState;

        default:
            return state
    }
}

export { reducer as CommunicationDataReducer };