import { faHourglassEnd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlainInfoCard } from "../components/styled/Card.style";
import { Section } from "../components/styled/Containers.style";
import { IconContainer } from "../components/styled/Icon.style";
import { Vspace } from "../components/styled/Separators.style";
import { BigText, TextDefault } from "../components/styled/Text.style";
import { PATHS } from "../routes/CaiExperienceRouter";
import PageContainerCmp from "./PageContainerCmp";

const EndTokenExpiredPage = () => {

    return (
        <>
            <PageContainerCmp
                routePath={PATHS.TOKEN_EXPIRED}
                removeNextBtn={true}>
                <Section>
                    <PlainInfoCard>
                        <IconContainer>
                            <FontAwesomeIcon icon={faHourglassEnd} />
                        </IconContainer>
                        <Vspace></Vspace>
                        <Vspace></Vspace>
                        <BigText>Comunicazione scaduta</BigText>
                    </PlainInfoCard>
                </Section>
                <Vspace />
                <Section>
                    <TextDefault centered>Siamo spiacenti ma questa comunicazione<br />non è più attiva, puoi procedere con la<br />chiusura della pagina.</TextDefault>
                </Section>
            </PageContainerCmp>
        </>
    )
}

export default EndTokenExpiredPage