import styled from 'styled-components'
import { CenteredContainer, HCenteredContainer } from './Common.style'

interface CheckboxOptionsProps {
  horizontal?: boolean;
  light?: boolean
}

export const CheckboxCmpContainer = styled(HCenteredContainer)`
  align-items: flex-start;
  width: 100%;
`

export const CheckboxOptionsLabel = styled.p`
    font-family: "Bold";
    line-height: 22px;
    color: #3079c9;
    font-size: 16px;`

export const CheckboxItem = styled(CenteredContainer) <CheckboxOptionsProps>`
  cursor: pointer;
  width: 75%;
  justify-content: flex-start;
  padding: 0.7rem 0 1rem;
  border-bottom: 1px solid #e6e6e6;

  ${(props: CheckboxOptionsProps) => props.horizontal && `
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    margin: 0;
    border-bottom: none;
  `}

  &.privacy-ckeck {
    align-items: flex-start;
  }
`

export const CheckboxCheck = styled(CenteredContainer)`
  cursor: pointer;
  margin-bottom: 20px;
  height: 25px;
  width: 25px;
  min-width: 25px;
  background-color: #eee;

  &.active {
    background-color: #3079c9;
  }

  &.privacy-ckeck {
    margin-top: 4px;
  }

  svg {
    color: #fff;
    font-size: 18px;
  }
`

export const CheckboxLabel = styled.p<CheckboxOptionsProps>`
  cursor: pointer;
  font-family: "medium";
  font-size: 17px;
  margin-left: 1rem;
  line-height: 22px;
  margin-top: 2px;
  color: #0f3250;

  ${(props: CheckboxOptionsProps) => props.horizontal && `
    margin-left: 1.2rem;
    margin-right: 1.5rem;
  `}
  ${(props: CheckboxOptionsProps) => props.light && `
    font-family: "Book";
    font-size: 13px;
  `}
  &
    a {
      color: #0f3250;
    }
`

export const CheckboxSubLabel = styled.p`
  font-family: "Unipol-Book";
  line-height: 20px;
  color: #666666;
  margin-left: 1rem;
`

export const CheckboxLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const CheckboxOptionsContainer = styled(HCenteredContainer) <CheckboxOptionsProps>`
  width: 100%;

  ${(props: CheckboxOptionsProps) => props.horizontal && `
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    margin: 0;
  `}

  &.cai ${CheckboxLabel}{
    font-size: 15px;
    font-family: "Book";
  }
`




