import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FileActionFunction, FileItemType } from '../../types/communication/communicationDataType'
import { FileActionButton, FileActionsContainer, FileItemContent, FileListItem, FilePreviewImg } from '../styled/File.style';
import { useParams } from 'react-router-dom';
import FileImgFullPageCmp from './FileImgFullPageCmp';
import { useState } from 'react';

interface FileItemCmpProps {
    file?: FileItemType
    deleteFile: FileActionFunction
    canDelete?: boolean
    fullPageCanDelete?: boolean
}

const FileItemCmp = ({ file, deleteFile, canDelete, fullPageCanDelete }: FileItemCmpProps) => {
    let { token } = useParams();
    const [showFullpageImage, setShowFullpageImage] = useState<boolean>(false)
    let previewURL = `${window.REACT_APP_VTV_API_ENDPOINT}/api/realtime/communication/${token}/download-attachment/${file?._id}`;

    const showFullPageFileImgHandler = () => {
        setShowFullpageImage(true)
    }

    const deleteFileHandler = () => {
        deleteFile(file);
        setShowFullpageImage(false)
    }

    return (
        <>
            {showFullpageImage && file && <FileImgFullPageCmp
                deleteHandler={fullPageCanDelete && deleteFileHandler}
                file={file} close={() => setShowFullpageImage(false)} />}
            <FileListItem
                className="file-item"
                key={file?._id || file?.name}>
                <FileItemContent onClick={showFullPageFileImgHandler}>
                    {file?.metadata?.isLoading && <FontAwesomeIcon
                        icon={faSpinner} className="fa-spin" />
                    }
                    {
                       file?._id && <FilePreviewImg src={previewURL} alt="Foto contesto preview" />
                    }
                </FileItemContent>
                {canDelete && <FileActionsContainer>
                    {!file?.metadata?.isLoading && <FileActionButton
                        onClick={() => deleteFile(file)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </FileActionButton>
                    }
                </FileActionsContainer>
                }
            </FileListItem>
        </>
    )
}

export default FileItemCmp
