import { EventValueEnum } from "../types/communication/communicationDataType";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlainInfoCard } from "../components/styled/Card.style";
import { Section } from "../components/styled/Containers.style";
import { IconContainer } from "../components/styled/Icon.style";
import { Vspace } from "../components/styled/Separators.style";
import { BigText } from "../components/styled/Text.style";
import { PATHS } from "../routes/CaiExperienceRouter";
import PageContainerCmp from "./PageContainerCmp";

const EndSorryToDisturbPage = () => {

    return (
        <>
            <PageContainerCmp
                routePath={PATHS.END_SORRY_TO_DISTURB}
                removeNextBtn={true}
                isForceLastPathname={true}
                eventsOnLoad={[EventValueEnum.CONFIRMED, EventValueEnum.NEGATION_REASON_CONFIRMED]}>
                <>
                    <Section>
                        <Vspace qty={0.5}></Vspace>
                        <PlainInfoCard>
                            <IconContainer>
                                <FontAwesomeIcon icon={faCheckCircle} />
                            </IconContainer>
                            <Vspace></Vspace>
                            <Vspace></Vspace>
                            <BigText>Ci scusiamo per il disturbo.</BigText>
                        </PlainInfoCard>
                    </Section>
                </>
            </PageContainerCmp>
        </>
    );
};

export default EndSorryToDisturbPage;
