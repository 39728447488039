import { useEffect } from "react"
import { ModalContentProps } from "../../types/commonTypes"
import { Section } from "../styled/Containers.style"
import { ModalConfirmButton } from "../styled/Modal.style"
import { TextDefault, TextDefaultSmall } from "../styled/Text.style"
import { tqPageView } from "../../utils/utils"

/* istanbul ignore next */
export const CaiInfoModal = ({ close }: ModalContentProps<null>) => {
    useEffect(() => {
        // Analytics
        tqPageView('cai:info constatazione amichevole')
    }, [])
    return (
        <>
            <Section>
                <TextDefault><strong>Cos'è la constatazione amichevole?</strong></TextDefault>
            </Section>

            <Section>
                <TextDefaultSmall>È una procedura necessaria in caso di incidente stradale e consiste nella compilazione del “<strong>Modulo CAI</strong>”. I dati necessari sono relativi all'incidente come, per esempio: luogo, ora, generalità di persone e veicoli, dinamica dell'incidente.</TextDefaultSmall>
            </Section>

            <Section>
                <TextDefaultSmall>Il modulo CAI semplifica il processo assicurativo e perciò dev'essere <strong>compilato entro 3 giorni</strong> dall'incidente. Può essere richiesto alla propria Compagnia assicurativa.</TextDefaultSmall>
            </Section>

            <Section>
                <TextDefaultSmall>Attraverso questa procedura real time e guidata potrai compilare <strong>digitalmente</strong> la tua CAI.</TextDefaultSmall>
            </Section>

            <Section>
                <ModalConfirmButton onClick={close}>OK</ModalConfirmButton>
            </Section>

        </>
    )
}
