import { BaremeResponses, CaiAnswersEnum, Q1_AnswersEnum, Q2_AnswersEnum, Q3_13_AnswersEnum, Q3_28_AnswersEnum, Q3_29_AnswersEnum, Q3_30_AnswersEnum, Q3_31_AnswersEnum, Q3_32_AnswersEnum, Q3_33_AnswersEnum } from '../types/communication/caiType';
import { BaremeInfo, CarInvolvedEnum, CommunicationType } from '../types/communication/communicationDataType';

export const computeQ3Enum = (communication: CommunicationType): CaiAnswersEnum | undefined => {
    const { Q1_Answer, Q2_Answer } = communication.baremeResponses

    switch (Q1_Answer) {
        case Q1_AnswersEnum.FRONTE_NS:
            switch (Q2_Answer) {
                case Q2_AnswersEnum.FRONTE_CTP:
                    return CaiAnswersEnum.Q3_13
                case Q2_AnswersEnum.RETRO_CTP:
                    return CaiAnswersEnum.Q3_28
                case Q2_AnswersEnum.SINISTRA_CTP:
                    return CaiAnswersEnum.Q3_30
                case Q2_AnswersEnum.DESTRA_CTP:
                    return CaiAnswersEnum.Q3_29
            }
            break

        case Q1_AnswersEnum.RETRO_NS:
            switch (Q2_Answer) {
                case Q2_AnswersEnum.FRONTE_CTP:
                    return CaiAnswersEnum.Q3_31
                case Q2_AnswersEnum.RETRO_CTP:
                    return CaiAnswersEnum.Q3_32
                case Q2_AnswersEnum.SINISTRA_CTP:
                    return undefined
                case Q2_AnswersEnum.DESTRA_CTP:
                    return undefined
            }
            break

        case Q1_AnswersEnum.SINISTRA_NS:
            switch (Q2_Answer) {
                case Q2_AnswersEnum.FRONTE_CTP:
                    return CaiAnswersEnum.Q3_29
                case Q2_AnswersEnum.RETRO_CTP:
                    return undefined
                case Q2_AnswersEnum.SINISTRA_CTP:
                    return CaiAnswersEnum.Q3_13
                case Q2_AnswersEnum.DESTRA_CTP:
                    return CaiAnswersEnum.Q3_13
            }
            break

        case Q1_AnswersEnum.DESTRA_NS:
            switch (Q2_Answer) {
                case Q2_AnswersEnum.FRONTE_CTP:
                    return CaiAnswersEnum.Q3_30
                case Q2_AnswersEnum.RETRO_CTP:
                    return undefined
                case Q2_AnswersEnum.SINISTRA_CTP:
                    return CaiAnswersEnum.Q3_13
                case Q2_AnswersEnum.DESTRA_CTP:
                    return CaiAnswersEnum.Q3_33
            }
            break

        default:
            return CaiAnswersEnum.Q3_13
    }
    return CaiAnswersEnum.Q3_28

}

  /* istanbul ignore next */
export const computeBaremeInfo = (b: BaremeResponses): BaremeInfo => {
    const baremeInfo = new BaremeInfo()

    if (b.Q1_Answer === Q1_AnswersEnum.DESTRA_NS &&
        b.Q2_Answer === Q2_AnswersEnum.FRONTE_CTP &&
        b.Q3_30_Answer === Q3_30_AnswersEnum.NO) {
        baremeInfo.fillFields('16', 'La controparte proveniva da destra', CarInvolvedEnum.CTP)
        return baremeInfo
    }

    if (b.Q1_Answer === Q1_AnswersEnum.DESTRA_NS &&
        b.Q2_Answer === Q2_AnswersEnum.SINISTRA_CTP &&
        b.Q3_13_Answer as any === Q3_13_AnswersEnum.NS) {
        baremeInfo.fillFields('10', 'Cambiavo fila', CarInvolvedEnum.NS)
        baremeInfo.ctpInRetro = false;
        return baremeInfo
    }


    if (b.Q1_Answer === Q1_AnswersEnum.SINISTRA_NS &&
        b.Q2_Answer === Q2_AnswersEnum.DESTRA_CTP &&
        b.Q3_13_Answer as any === Q3_13_AnswersEnum.NS) {
        baremeInfo.fillFields('10', 'Cambiavo fila', CarInvolvedEnum.NS)
        baremeInfo.ctpInRetro = false;
        return baremeInfo
    }

    if (b.Q1_Answer === Q1_AnswersEnum.DESTRA_NS &&
        b.Q2_Answer === Q2_AnswersEnum.SINISTRA_CTP &&
        b.Q3_13_Answer as any === Q3_13_AnswersEnum.CTP) {
        baremeInfo.fillFields('10', 'La controparte cambiava fila', CarInvolvedEnum.CTP)
        return baremeInfo
    }


    if (b.Q1_Answer === Q1_AnswersEnum.SINISTRA_NS &&
        b.Q2_Answer === Q2_AnswersEnum.DESTRA_CTP &&
        b.Q3_13_Answer as any === Q3_13_AnswersEnum.CTP) {
        baremeInfo.fillFields('10', 'La controparte cambiava fila', CarInvolvedEnum.CTP)
        baremeInfo.ctpInRetro = false;
        return baremeInfo
    }

    if (b.Q1_Answer === Q1_AnswersEnum.DESTRA_NS &&
        b.Q2_Answer === Q2_AnswersEnum.SINISTRA_CTP &&
        b.Q3_13_Answer as any === Q3_13_AnswersEnum.BOTH) {
        baremeInfo.fillFields('10', 'Io e la controparte cambiavamo fila', CarInvolvedEnum.BOTH)
        baremeInfo.ctpInRetro = false;
        return baremeInfo
    }


    if (b.Q1_Answer === Q1_AnswersEnum.SINISTRA_NS &&
        b.Q2_Answer === Q2_AnswersEnum.DESTRA_CTP &&
        b.Q3_13_Answer as any === Q3_13_AnswersEnum.BOTH) {
        baremeInfo.fillFields('10', 'Io e la controparte cambiavamo fila', CarInvolvedEnum.BOTH)
        baremeInfo.ctpInRetro = false;
        return baremeInfo
    }

    if (b.Q1_Answer === Q1_AnswersEnum.FRONTE_NS &&
        b.Q2_Answer === Q2_AnswersEnum.SINISTRA_CTP &&
        b.Q3_30_Answer === Q3_30_AnswersEnum.NO) {
        baremeInfo.fillFields('17', 'Non avevo osservato il segnale di precedenza o di semaforo rosso', CarInvolvedEnum.NS)
        return baremeInfo
    }

    if (b.Q1_Answer === Q1_AnswersEnum.RETRO_NS &&
        b.Q2_Answer === Q2_AnswersEnum.SINISTRA_CTP) {
        baremeInfo.fillFields('14', 'Retrocedevo', CarInvolvedEnum.NS)
        baremeInfo.assInRetro = true;
        return baremeInfo
    }


    if (b.Q1_Answer === Q1_AnswersEnum.RETRO_NS &&
        b.Q2_Answer === Q2_AnswersEnum.DESTRA_CTP) {
        baremeInfo.fillFields('14', 'Retrocedevo', CarInvolvedEnum.NS)
        baremeInfo.assInRetro = true;
        return baremeInfo
    }


    if (b.Q1_Answer === Q1_AnswersEnum.SINISTRA_NS &&
        b.Q2_Answer === Q2_AnswersEnum.RETRO_CTP) {
        baremeInfo.fillFields('14', 'La controparte retrocedeva', CarInvolvedEnum.CTP)
        baremeInfo.ctpInRetro = true;
        return baremeInfo
    }


    if (b.Q1_Answer === Q1_AnswersEnum.DESTRA_NS &&
        b.Q2_Answer === Q2_AnswersEnum.RETRO_CTP) {
        baremeInfo.fillFields('14', 'La controparte retrocedeva', CarInvolvedEnum.CTP)
        baremeInfo.ctpInRetro = true;
        return baremeInfo
    }

    if (b.Q3_13_Answer === Q3_13_AnswersEnum.NS) {
        baremeInfo.fillFields('15', 'Invadevo la sede stradale riservata alla circolazione in senso inverso', CarInvolvedEnum.NS)
        return baremeInfo
    }

    if (b.Q3_13_Answer === Q3_13_AnswersEnum.BOTH) {
        baremeInfo.fillFields('15', 'Io e la controparte invadevamo la sede stradale riservata alla circolazione in senso inverso', CarInvolvedEnum.BOTH)
        return baremeInfo
    }

    if (b.Q3_28_Answer === Q3_28_AnswersEnum.SI) {
        baremeInfo.fillFields('1', 'Veicolo in sosta o in fermata', CarInvolvedEnum.NS)
        baremeInfo.fillFields('14', 'La controparte retrocedeva', CarInvolvedEnum.CTP)
        baremeInfo.ctpInRetro = true;
        return baremeInfo
    }

    if (b.Q3_28_Answer === Q3_28_AnswersEnum.NO) {
        baremeInfo.fillFields('8', 'Tamponavo procedendo nello stesso senso e nella stessa fila', CarInvolvedEnum.NS)
        return baremeInfo
    }

    if (b.Q3_29_Answer === Q3_29_AnswersEnum.SI) {
        baremeInfo.fillFields('17', 'Non avevo osservato il segnale di precedenza o di semaforo rosso', CarInvolvedEnum.NS)
        return baremeInfo
    }

    if (b.Q3_31_Answer === Q3_31_AnswersEnum.SI) {
        baremeInfo.fillFields('1', 'Veicolo in sosta o in fermata', CarInvolvedEnum.CTP)
        baremeInfo.fillFields('14', 'Retrocedevo', CarInvolvedEnum.NS)
        baremeInfo.assInRetro = true;
        return baremeInfo
    }

    if (b.Q3_32_Answer === Q3_32_AnswersEnum.NS) {
        baremeInfo.fillFields('1', 'Veicolo in sosta o in fermata', CarInvolvedEnum.CTP)
        baremeInfo.fillFields('14', 'Retrocedevo', CarInvolvedEnum.NS)
        baremeInfo.assInRetro = true;
        return baremeInfo
    }

    if (b.Q3_32_Answer === Q3_32_AnswersEnum.CTP) {
        baremeInfo.fillFields('1', 'Veicolo in sosta o in fermata', CarInvolvedEnum.NS)
        baremeInfo.fillFields('14', 'La controparte retrocedeva', CarInvolvedEnum.CTP)
        baremeInfo.ctpInRetro = true;
        return baremeInfo
    }

    if (b.Q3_32_Answer === Q3_32_AnswersEnum.BOTH) {
        baremeInfo.fillFields('14', 'Io e la controparte retrocedevamo', CarInvolvedEnum.BOTH)
        baremeInfo.assInRetro = true;
        baremeInfo.ctpInRetro = true;
        return baremeInfo
    }


    if (b.Q3_33_Answer === Q3_33_AnswersEnum.ASSICURATO) {
        baremeInfo.fillFields('2', 'Ripartivo dopo una sosta, aprivo una portiera', CarInvolvedEnum.NS)
        return baremeInfo
    }


    if (b.Q3_33_Answer === Q3_33_AnswersEnum.BOTH) {
        baremeInfo.fillFields('2', 'Io e la controparte ripartivamo dopo una sosta, aprivamo una portiera', CarInvolvedEnum.BOTH)
        return baremeInfo
    }

    if (b.Q3_33_Answer === Q3_33_AnswersEnum.CTP) {
        baremeInfo.fillFields('2', 'La controparte ripartiva dopo una sosta, apriva una portiera', CarInvolvedEnum.CTP)
        return baremeInfo
    }


    if (b.Q3_31_Answer === Q3_31_AnswersEnum.NO) {
        baremeInfo.fillFields('8', 'La controparte tamponava procedendo nello stesso senso e nella stessa fila', CarInvolvedEnum.CTP)
        return baremeInfo
    }


    if (b.Q3_13_Answer === Q3_13_AnswersEnum.CTP) {
        baremeInfo.fillFields('15', 'La controparte invadeva la sede stradale riservata alla circolazione in senso inverso', CarInvolvedEnum.CTP)
        return baremeInfo
    }


    if (b.Q3_30_Answer === Q3_30_AnswersEnum.SI) {
        baremeInfo.fillFields('17', 'La controparte non aveva osservato il segnale di precedenza o di semaforo rosso', CarInvolvedEnum.CTP)
        return baremeInfo
    }


    if (b.Q3_29_Answer as any === Q3_29_AnswersEnum.NO) {
        baremeInfo.fillFields('17', 'La controparte non aveva osservato il segnale di precedenza o di semaforo rosso', CarInvolvedEnum.CTP)
        return baremeInfo
    }

    return baremeInfo;
}

export enum BaremeEnum {
    Bareme_17 = 'Non avevo osservato il segnale di precedenza o di semaforo rosso',
    Bareme_16 = 'Provenivo da destra',
    Bareme_15 = 'Invadevo la sede stradale riservata alla circolazione in senso inverso',
    Bareme_14 = 'Retrocedevo',
    Bareme_13 = 'Giravo a sinistra',
    Bareme_12 = 'Giravo a destra',
    Bareme_11 = 'Sorpassavo',
    Bareme_10 = 'Cambiavo fila',
    Bareme_9 = 'Procedevo nello stesso senso, ma in fila diversa',
    Bareme_8 = 'Tamponavo procedendo nello stesso senso o nella stessa fila',
    Bareme_7 = 'Circolavo su una piazza a senso rotatorio',
    Bareme_6 = 'Mi immettevo su una piazza a senso rotatorio',
    Bareme_5 = 'Entravo in un parcheggio, in un luogo privato, in una strada vicinale',
    Bareme_4 = 'Uscivo da un parcheggio, da un luogo privato, in una strada vicinale',
    Bareme_3 = 'Stavo parcheggiando',
    Bareme_2 = 'Ripartivo dopo una sosta/apriva una portiera',
    Bareme_1 = 'Veicolo in sosta o in fermata'
}

export const mapBaremeIdLabel: any = {
    '1': BaremeEnum.Bareme_1,
    '2': BaremeEnum.Bareme_2,
    '3': BaremeEnum.Bareme_3,
    '4': BaremeEnum.Bareme_4,
    '5': BaremeEnum.Bareme_5,
    '6': BaremeEnum.Bareme_6,
    '7': BaremeEnum.Bareme_7,
    '8': BaremeEnum.Bareme_8,
    '9': BaremeEnum.Bareme_9,
    '10': BaremeEnum.Bareme_10,
    '11': BaremeEnum.Bareme_11,
    '12': BaremeEnum.Bareme_12,
    '13': BaremeEnum.Bareme_13,
    '14': BaremeEnum.Bareme_14,
    '15': BaremeEnum.Bareme_15,
    '16': BaremeEnum.Bareme_16,
    '17': BaremeEnum.Bareme_17,
}

// export const baremeOptions: ValueLabelOption<string>[] = [];

// (() => {
//     for(const property in mapBaremeIdLabel) {
//         baremeOptions.push({value: property, label: mapBaremeIdLabel[property]})
//     }
// })
