import styled from 'styled-components'

export const MapPageContainer = styled.div`
  font-family: "Book";
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  inset: 0px;
  z-index: 99;

  &.input-el {
    position: relative;
    top: 100%;
    left: 0;
    height: 120px;
    width: 100%;
    border: 1px solid #cecece;
    border-top: none;
    margin-top: -5px;
    }

  .parent-map {
    display: block;
  }

  .parent-stop-events {
    pointer-events: none;
  }

  .leaflet-container {
    position: absolute;
    inset: 0px;
    width: 100%;
    height: 100%;
    touch-action: none;
    font-size: 0.75rem;
    line-height: 1.5;
    background: #ddd;
    outline-offset: 1px;
    overflow: hidden;
  }

  .leaflet-pane {
    transform: translate3d(0px, 0px, 0px);
    z-index: 400;
    position: absolute;
    left: 0;
    top: 0;
  }

  .marker-map-centered {
    pointer-events: none;
    position: absolute;
    z-index: 500;
    margin-top: -25px;
    width: 60px;
    height: 60px;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
  }

  .leaflet-pane.leaflet-marker-pane {
    display: none;
  }

  .buttonMap {
    font-size: 23px;
    line-height: 28px;
    height: 48px;
    width: 45px;
    text-align: center;
    left: 20px;
    position: absolute;
    top: 10px;
    z-index: 8000;
    cursor: pointer;
    background-color: whitesmoke;
    border: solid 1px grey;
    border-radius: 4px 0px 0px 4px;
    border-right: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .address-form {
    position: absolute;
    z-index: 600;
    top: 10px;
    width: 100%;

    .form-class {
      display: grid;

      textarea {
        outline: none;
        margin-left: 65px;
        width: 75%;
        max-width: 400px;
        height: 48px;
        font-family: "Book";
        font-size: 16px;
        vertical-align: middle;
        border-color: grey;
        margin-bottom: 5px;
        padding: 0.2rem 0.4rem;
        line-height: 20px;
      }

      .suggested-list {
          border-radius: 4px;
          overflow-y: auto;
          overflow-x: hidden;
          z-index: 8000;
          text-align: left;
        }

          .text-suggestion {
              width: 75%;
              padding-left: 10px;
              background-color: #fff;
              border-bottom: 1px solid #e6e6e6;
              overflow: hidden;
              box-shadow: 0 1px 2px rgb(0 0 0 / 15%);
              white-space: nowrap;
              height: 35px;
              text-overflow: ellipsis;
              line-height: 35px;
              font-size: 13px;
              margin-left: 60px;
              margin-right: 5px;
          }
    }

    
    .proximity-warning-container{
        background-color: #FFF0E3;
        width: 80%;
        max-width: 400px;
        display: block;
        margin: auto;
        border: 1px solid #FF7600;
        margin-top: 7px;
        position:relative;
        .little-cube{
            width: 10px;
            height: 10px;
            display: block;
            margin: auto;
            position: absolute;
            top: -6px;
            border-right: 1px solid #ff7600;
            border-top: 1px solid #ff7600;
            transform: rotate(-45deg);
            left: calc(50% - 5px);
            background-color: #fff0e3;        
        }
        >svg{
            position: absolute;
            right: 5px;
            font-size: 16px;
            top: 2px;
            color: #ff7600;
        }
        div{
            display: flex;
            height: 80px;
            justify-content: center;
            align-items: center;
            svg{
                font-size: 30px;
                color: #ff7600;
                border-radius: 50%;
                margin-left: 15px;
                margin-right: 15px;
            }
            p{
                margin:0px;
                font-family: 'Unipol-Medium', Sans-Serif;
                color: #FF7600;
                font-size: 16px;
                padding-right: 25px;
            }
        }
        
    }
  }
`

