import { ValueLabelOption, createValueLabelOption } from "../../types/commonTypes";
import CheckboxItemCmp from "../checkbox/CheckboxItemCmp";
import { Section } from "../styled/Containers.style";
import { Vspace } from "../styled/Separators.style";

interface PrivacyCheckCmpProps {
    privacyChecked: ValueLabelOption<boolean>;
    setPrivacyChecked: Function
}

function PrivacyCheckCmp({privacyChecked, setPrivacyChecked}: PrivacyCheckCmpProps) {

    const privacyToggleHandler = () => {
        /* istanbul ignore next */
        setPrivacyChecked((prevValue: ValueLabelOption<boolean>) => createValueLabelOption(!prevValue.value, prevValue.label))
    }
    
    return (
        <>
            <Section>
                <Vspace></Vspace>
                <CheckboxItemCmp
                    isActive={privacyChecked.value === true}
                    item={privacyChecked}
                    itemClassName={'privacy-ckeck'}
                    onSelect={privacyToggleHandler}
                    horizontal={true}
                />
            </Section>
        </>
    )
}

export default PrivacyCheckCmp