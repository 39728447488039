import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { NextButton } from "../components/styled/Button.style";
import { PlainInfoCard } from "../components/styled/Card.style";
import { Section } from "../components/styled/Containers.style";
import { IconContainer } from "../components/styled/Icon.style";
import { Vspace } from "../components/styled/Separators.style";
import { BigText } from "../components/styled/Text.style";
import { PATHS } from "../routes/CaiExperienceRouter";
import { composeUrl } from "../utils/utils";
import { setLocalStorage_forwardState } from "../utils/utils.store";
import PageContainerCmp from "./PageContainerCmp";

  /* istanbul ignore next */
const EndCommunicationForwardedPage = () => {
    let navigate = useNavigate();
    let { token } = useParams();

    const takeControlBackHandler = () => {
        const _pathname = composeUrl(token, PATHS.WELCOME_FIRST_PAGE)
        setLocalStorage_forwardState(false)
        navigate(_pathname)
    }

    return (
        <>
            <PageContainerCmp
                routePath={PATHS.END_COMMUNICATION_FORWARDED}
                removeNextBtn={true}
                doNotRegisterLastPathname={true}>
                <Section>
                    <PlainInfoCard>
                        <IconContainer>
                            <FontAwesomeIcon icon={faCheckCircle} />
                        </IconContainer>
                        <Vspace></Vspace>
                        <Vspace></Vspace>
                        <BigText>Hai inoltrato la comunicazione al conducente che era alla guida, sarà lui a compilare i dati relativi all’incidente.</BigText>
                    </PlainInfoCard>

                    <Section>
                        <NextButton
                            onClick={takeControlBackHandler}>Riprendi il controllo
                        </NextButton>
                    </Section>

                </Section>
            </PageContainerCmp>
        </>
    )
}

export default EndCommunicationForwardedPage