import { yupResolver } from "@hookform/resolvers/yup";
import { forwardRef, useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";
import { BoolStringEnum, getValueLabelOptions, ValueLabelOption } from "../../types/commonTypes";
import { ContactInfo, ContactInfoItemUi, InputType } from "../../types/communication/communicationDataType";
import InputCmp from "../input/InputCmp"
import RadioOptionsCmp from "../radio/RadioOptionsCmp";
import { ContactItemContainer } from "../styled/Input.style";
import { CaiContactInfoForm } from "../styled/page-custom/Cai.style";
import { TextArea } from "../styled/TextArea.style";
import { genericStateReducer } from "./genericStateReducer";

export const ContactInfoStateAction = {
    UPDATE_STATE: '@@contactInfo/UPDATE_STATE'
}

export interface ContactInfoCmpProps {
    initValues: ContactInfo;
    contactItems: ContactInfoItemUi[]
    onContactChange?: (updValues: Partial<ContactInfo>) => void,
    validationSchema?: any,
    onValiddationStateChange?: any
}

// Use React.forwardRef
const ContactInfoCmp = forwardRef(({ ...props }: ContactInfoCmpProps, ref) => {

    const { initValues, contactItems, onContactChange, validationSchema, onValiddationStateChange
    } = props
    const [contactInfoState, contactInfoDispatch] = useReducer(genericStateReducer<ContactInfo>(ContactInfoStateAction), initValues);

    useEffect(() => {
        onContactChange && onContactChange(contactInfoState)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactInfoState])


    const { register, handleSubmit, formState } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange"
    });

    useEffect(() => {
        onValiddationStateChange && onValiddationStateChange(formState)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState?.isValid])


    const onSubmit = () => { };

    return (
        <>
            <CaiContactInfoForm onSubmit={handleSubmit(onSubmit)}>

                {
                    contactItems && contactItems.map((ctItem) => {
                        return <ContactItemContainer
                            key={ctItem.propName}>
                            {(!ctItem.type || ctItem.type === InputType.TEXT) &&
                                <InputCmp
                                    value={(contactInfoState)[ctItem.propName] || ''}
                                    setValue={(val: any) => {
                                        const updObj: Partial<ContactInfo> = {}
                                        updObj[ctItem.propName] = val;
                                        contactInfoDispatch({ type: ContactInfoStateAction.UPDATE_STATE, payload: { ...updObj } })
                                    }}
                                    name={ctItem.propName}
                                    label={ctItem.label}
                                    labelClassName="marked"
                                    className={`contact-info small ${ctItem.className}`}
                                    register={register}
                                    errors={formState?.errors}
                                />
                            }

                            {ctItem.type === InputType.RADIO &&
                                <RadioOptionsCmp
                                    horizontal
                                    otpionsLabel={ctItem.otpionsLabel}
                                    options={ctItem.options || []}
                                    value={getValueLabelOptions(ctItem.options || [], (contactInfoState)[ctItem.propName])}
                                    className={'cai anche-other-contact'}
                                    onChange={(newChoise: ValueLabelOption<BoolStringEnum>) => {
                                        const updObj: Partial<ContactInfo> = {}
                                        updObj[ctItem.propName] = newChoise.value;
                                        contactInfoDispatch({ type: ContactInfoStateAction.UPDATE_STATE, payload: { ...updObj } })
                                    }}
                                />
                            }

                            {ctItem.type === InputType.TEXTAREA &&
                                <TextArea
                                    placeholder="Descrivi la cosa o animale danneggiato..."
                                    value={(contactInfoState)[ctItem.propName] || ''}
                                    onChange={(e: any) => {
                                        const updObj: Partial<ContactInfo> = {}
                                        updObj[ctItem.propName] = e.target.value;
                                        contactInfoDispatch({ type: ContactInfoStateAction.UPDATE_STATE, payload: { ...updObj } })
                                    }}
                                />
                            }
                        </ContactItemContainer>
                    })
                }
            </CaiContactInfoForm>
        </>

    )
}
)

export default ContactInfoCmp