import styled from "styled-components";

export interface FooterProps {
  isRelativeTemplate?: boolean;
}

export const Footer = styled.div<FooterProps>`
  position: ${props => props.isRelativeTemplate ? 'relative' : 'fixed'};
  left: 0;
  bottom: 0;
  width: ${props => props.isRelativeTemplate ? 'calc(100% + 50px)' : '100%'};
  background: rgba(48, 121, 201, 0.68);
  margin-top: 20px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px 0;
  height: auto;
  padding: 20px 0;
  margin-left:  ${props => props.isRelativeTemplate ? '-25px' : '0'};
  margin-bottom:  ${props => props.isRelativeTemplate ? '-25px' : '0'};
`;

export const FooterTriangle = styled.div`
  position: absolute;
  background: white;
  clip-path: polygon(50% 50%, 0% 100%, 100% 100%);
  width: 20px;
  height: 20px;
  top: 9px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
`;

export const FooterImgContainer = styled.div`
  display: flex;
  gap: 0 20px;
`;

export const FooterImg = styled.img``;

export const FooterLink = styled.a`
  color: #fff;
  text-decoration: underline;
`;
