import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValueLabelOption } from "../../types/commonTypes";
import { RadioCheck, RadioItem, RadioLabel, RadioLabelContainer, RadioSubLabel } from "../styled/Radio.style";

interface RadioItemCmpProps<T> {
    item: ValueLabelOption<T>;
    itemClassName?: string;
    onSelect: any;
    isActive?: boolean;
    horizontal?: boolean;
}

const RadioItemCmp = <T extends unknown>(props: RadioItemCmpProps<T>) => {
    const { item, itemClassName, onSelect, isActive, horizontal } = props
    const isActiveClass = isActive ? 'active' : ''
    return (
        <RadioItem className={itemClassName}
            onClick={() => onSelect(item)}
            horizontal={horizontal}>
            <RadioCheck className={isActiveClass}>
                {<FontAwesomeIcon icon={faCheck} />}
            </RadioCheck>
            <RadioLabelContainer>
                <RadioLabel horizontal={horizontal}>{item.label}</RadioLabel>
                <RadioSubLabel>{item.subLabel}</RadioSubLabel>
            </RadioLabelContainer>
        </RadioItem>
    )
}

export default RadioItemCmp