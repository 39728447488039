import { useNavigate, useParams } from "react-router-dom";
import { Section } from "../../components/styled/Containers.style";
import { PageTitle, TextDefault } from "../../components/styled/Text.style";
import { Vspace } from '../../components/styled/Separators.style'
import PageContainerCmp from "../PageContainerCmp";
import { composeUrl } from "../../utils/utils";
import { NextButton, SecondChoiseButton } from "../../components/styled/Button.style";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { CaiInfoModal } from "../../components/cai-info-modal/CaiInfoModal";
import { RealtimeModal } from "../../components/modal/RealtimeModal";
import { InfoIcon } from "../../components/styled/Icon.style";
import { selectCommunicationData } from "../../store";
import { AUTH_CONFIRM_BASE_ROUTH, AUTH_CONFIRM_PATHS } from "../../routes/AuthConfirmRouter";
import CaiPreviewsCmp from "../../components/cai-preview/CaiPreviewsCmp";
import { CommunicationType, EventCommType, EventValueEnum, UserRoleEnum } from "../../types/communication/communicationDataType";
import { saveCommunicationAct } from "../../store/communication-data/actions";
import { usePrivacyCheckCmp } from "../../utils/hooks/usePrivacyCheckCmp";
import AuthConfirmCancelProcedureModal from "../../components/cancel-authorize-confirm-claim-modal/AuthConfirmCancelProcedureModal";

/* istanbul ignore next */
const AuthConfirmCaiPreviewPage = () => {
    const dispatch = useAppDispatch();
    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData
    let navigate = useNavigate();
    let { token } = useParams();
    const { privChecked, PrivCheckedCmp } = usePrivacyCheckCmp(communication.authConfirmPrivacyChecked)

    const previewToken = communication.userRole === UserRoleEnum.USER_1 ? communication.token : communication.mainUserToken
    const previewUrlPage_1 = `${window.REACT_APP_VTV_API_ENDPOINT}/api/realtime/communication/${previewToken}/dinamica-attachment-cai-data/full-page`;
    const previewUrlPage_2 = `${window.REACT_APP_VTV_API_ENDPOINT}/api/realtime/communication/${previewToken}/dinamica-attachment-cai-data/second-full-page`;
    const msgPage = communication.userRole === UserRoleEnum.USER_1 ?
        'Le informazioni del sinistro che ci hai condiviso sono state inserite nel modulo di constatazione amichevole che vedi qui sotto.' :
        'La controparte ha compilato la Constatazione Amichevole relativa al sinistro in cui sei coinvolto, di cui puoi visualizzare l\'anteprima:'

    const [openCaiModal, setOpenCaiModal] = useState(false)
    const [cancelModalOpened, setCancelModalOpened] = useState<boolean>(false)

    const shouldDisableButton = () => {
        return !(privChecked?.value);
    }

    const goToNextStepHandler = () => {
        /* istanbul ignore next */
        const _pathname = composeUrl(token, AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_UPLOAD_DOC, AUTH_CONFIRM_BASE_ROUTH)
        /* istanbul ignore next */
        const newEvent = new EventCommType({ value: EventValueEnum.AUTH_CONFIRM_PREVIEW_ACCEPTED })
        const data = new CommunicationType({
            ...communication,
            authConfirmPrivacyChecked: privChecked.value
        })
        /* istanbul ignore next */
        dispatch(saveCommunicationAct({
            events: [newEvent],
            data,
            callback: () => {
                navigate(_pathname)
            }
        })
        )
    }

    return (
        <>
            <AuthConfirmCancelProcedureModal
                modalOpened={cancelModalOpened}
                onCloseHandler={() => setCancelModalOpened(false)} />

            <RealtimeModal
                modalOpened={openCaiModal}
                hTitle="Constatazione amichevole"
                hCloseButton={true}
                onCloseHandler={() => setOpenCaiModal(false)}>
                <CaiInfoModal />
            </RealtimeModal>

            <PageContainerCmp
                nextStepHandler={goToNextStepHandler}
                routePath={AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_CAI_PREVIEW}
                removeNextBtn
                eventsOnLoad={[EventValueEnum.AUTH_CONFIRM_PLATE_CHECK_SUCCESS]}
                backPath={communication.userRole === UserRoleEnum.USER_1 ?
                    AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_CTP_NUMBER :
                    AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_CLAIM
                }
            >
                <>
                    <Section>
                        <PageTitle>Constatazione amichevole <InfoIcon icon={faCircleInfo} onClick={() => setOpenCaiModal(true)} /></PageTitle>
                        <Vspace></Vspace>
                        <TextDefault centered sm>{msgPage}</TextDefault>
                    </Section>

                    <Section>
                        <CaiPreviewsCmp
                            previewUrlPage_1={previewUrlPage_1}
                            previewUrlPage_2={previewUrlPage_2}
                            isCaiSecondPageVisible={!!communication?.previewSecondPageVisible}
                        ></CaiPreviewsCmp>
                    </Section>

                    {PrivCheckedCmp}

                    <Section>
                        <Vspace></Vspace>
                        <NextButton
                            disabled={shouldDisableButton()}
                            onClick={goToNextStepHandler}>Procedi</NextButton>
                        <Vspace></Vspace>

                        <SecondChoiseButton onClick={() => setCancelModalOpened(true)}>Non voglio firmare</SecondChoiseButton>
                    </Section>
                </>
            </PageContainerCmp >
        </>

    )
}

export default AuthConfirmCaiPreviewPage