
import { useNavigate, useParams } from "react-router-dom";
import { Section } from "../components/styled/Containers.style";
import { selectCommunicationData } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { PageTitle } from "../components/styled/Text.style";
import { PATHS } from "../routes/CaiExperienceRouter";
import PageContainerCmp from "./PageContainerCmp";
import RadioOptionsCmp from "../components/radio/RadioOptionsCmp";
import { getValueLabelOptions, ValueLabelOption } from "../types/commonTypes";
import { BaremeResponses, CaiAnswersEnum, CAI_QUESTIONS, Q1_AnswersEnum } from "../types/communication/caiType";
import { useEffect, useState } from "react";
import { composeUrl } from "../utils/utils";
import { saveCommunicationAct } from "../store/communication-data/actions";
import { BaremeUserChoiseEnum, CommunicationType } from "../types/communication/communicationDataType";

const Q1Page = () => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let { token } = useParams();

    const currQ = CAI_QUESTIONS[CaiAnswersEnum.Q1]

    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData

    const [q1Resp, setQ1Resp] = useState<Q1_AnswersEnum | undefined>()
    useEffect(() => {
        setQ1Resp(communication.baremeResponses.Q1_Answer)
    }, [communication.baremeResponses.Q1_Answer])

    const goToNextStepHandler = () => {
        const updtBaremeResp = new BaremeResponses({Q1_Answer: q1Resp})

        let _pathname = composeUrl(token, PATHS.Q2)
        if (q1Resp === Q1_AnswersEnum.NESSUNA_NS) {
            // go to dynamic image
            _pathname = composeUrl(token, PATHS.FREE_INCIDENT_DESCRIPTION)
        }

        dispatch(saveCommunicationAct({
            data: new CommunicationType({
                ...communication,
                baremeUserChoise: BaremeUserChoiseEnum.BAREME_QUESTIONS_FILLED_ACCEPTED,
                baremeResponses: updtBaremeResp
            }),
            callback: () => navigate(_pathname)
        })
        )
    }
    return (
        <>
            <PageContainerCmp
                nextStepHandler={goToNextStepHandler}
                routePath={PATHS.Q1}
                nextDisabled={!q1Resp}
            >
                <>
                    <Section>
                        <PageTitle>{currQ.title}</PageTitle>
                    </Section>
                    <Section>
                        <RadioOptionsCmp
                            options={currQ.possibleAnswers}
                            value={getValueLabelOptions(currQ.possibleAnswers, q1Resp)}
                            className={'bareme-q1'}
                            onChange={(newChoise: ValueLabelOption<Q1_AnswersEnum>) => setQ1Resp(newChoise.value)}
                        />
                    </Section>

                </>
            </PageContainerCmp>
        </>
    )
}

export default Q1Page