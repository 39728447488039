

export enum CaiAnswersEnum {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3_13 = 'Q3_13',
  Q3_28 = 'Q3_28',
  Q3_29 = 'Q3_29',
  Q3_30 = 'Q3_30',
  Q3_31 = 'Q3_31',
  Q3_32 = 'Q3_32',
  Q3_33 = 'Q3_33',
  Q3_34 = 'Q3_34'
}

export enum Q1_AnswersEnum {
  FRONTE_NS = 'fronte-ns',
  RETRO_NS = 'retro-ns',
  SINISTRA_NS = 'sinistra-ns',
  DESTRA_NS = 'destra-ns',
  NESSUNA_NS = 'nessuna-ns',
}

interface CaiQuestionType<T> {
  code: CaiAnswersEnum;
  title: T
  possibleAnswers: PossibleAnswerType<T>[],
  value?: T
}
interface PossibleAnswerType<T> {
  value: T
  label: string
  subLabel?: string
}

export const CAI_QUESTIONS: { [key: string]: CaiQuestionType<string>; } = {};

CAI_QUESTIONS[CaiAnswersEnum.Q1] = {
  code: CaiAnswersEnum.Q1,
  title: 'Dove è stata maggiormente danneggiata la tua auto?',
  possibleAnswers: [{
    value: Q1_AnswersEnum.FRONTE_NS,
    label: 'Fronte',
    subLabel: '(parte destra o sinistra)'
  }, {
    value: Q1_AnswersEnum.RETRO_NS,
    label: 'Retro',
    subLabel: '(parte destra o sinistra)'
  }, {
    value: Q1_AnswersEnum.SINISTRA_NS,
    label: 'Lato sinistro',
    subLabel: '(parte anteriore o posteriore)'
  }, {
    value: Q1_AnswersEnum.DESTRA_NS,
    label: 'Lato destro',
    subLabel: '(parte anteriore o posteriore)'
  }, {
    value: Q1_AnswersEnum.NESSUNA_NS,
    label: 'Nessuna delle precedenti'
  }]
}

export enum Q2_AnswersEnum {
  FRONTE_CTP = 'fronte-ctp',
  RETRO_CTP = 'retro-ctp',
  SINISTRA_CTP = 'sinistra-ctp',
  DESTRA_CTP = 'destra-ctp',
  NESSUNA_CTP = 'nessuna-ctp',
}

CAI_QUESTIONS[CaiAnswersEnum.Q2] = {
  code: CaiAnswersEnum.Q2,
  title: 'Dove è stata maggiormente danneggiata l\'auto della controparte?',
  possibleAnswers: [{
    value: Q2_AnswersEnum.FRONTE_CTP,
    label: 'Fronte',
    subLabel: '(parte destra o sinistra)'
  }, {
    value: Q2_AnswersEnum.RETRO_CTP,
    label: 'Retro',
    subLabel: '(parte destra o sinistra)'
  }, {
    value: Q2_AnswersEnum.SINISTRA_CTP,
    label: 'Lato sinistro',
    subLabel: '(parte anteriore o posteriore)'
  }, {
    value: Q2_AnswersEnum.DESTRA_CTP,
    label: 'Lato destro',
    subLabel: '(parte anteriore o posteriore)'
  }, {
    value: Q2_AnswersEnum.NESSUNA_CTP,
    label: 'Nessuna delle precedenti'
  }]
}

export enum Q3_13_AnswersEnum {
  NS = 'ns',
  CTP = 'ctp',
  BOTH = 'both',
  NESSUNA_INV = 'nessuna-inv',
}

CAI_QUESTIONS[CaiAnswersEnum.Q3_13] = {
  code: CaiAnswersEnum.Q3_13,
  title: 'Chi ha invaso l\'altra corsia?',
  possibleAnswers: [{
    value: Q3_13_AnswersEnum.NS,
    label: 'Io',
  }, {
    value: Q3_13_AnswersEnum.CTP,
    label: 'Controparte',
  }, {
    value: Q3_13_AnswersEnum.BOTH,
    label: 'Entrambi',
  }, {
    value: Q3_13_AnswersEnum.NESSUNA_INV,
    label: 'Nessuna delle precedenti',
  }]
}

export enum Q3_28_AnswersEnum {
  SI = 'ctp-retro-si',
  NO = 'ctp-retro-no',
  NESSUNA_RETRO = 'nessuna-retro'
}

CAI_QUESTIONS[CaiAnswersEnum.Q3_28] = {
  code: CaiAnswersEnum.Q3_28,
  title: 'Controparte in retromarcia? (Assicurato non ha tamponato?)',
  possibleAnswers: [{
    value: Q3_28_AnswersEnum.SI,
    label: 'Sì',
  }, {
    value: Q3_28_AnswersEnum.NO,
    label: 'No',
  }, {
    value: Q3_28_AnswersEnum.NESSUNA_RETRO,
    label: 'Nessuna delle precedenti',
  }]
}


export enum Q3_29_AnswersEnum {
  SI = 'ns-stop-sem-pre-priv-si',
  NO = 'ns-stop-sem-pre-priv-no',
  NESSUNA = 'ns-stop-sem-pre-priv-nessuna'
}

CAI_QUESTIONS[CaiAnswersEnum.Q3_29] = {
  code: CaiAnswersEnum.Q3_29,
  title: 'Avevi lo stop o il semaforo rosso o dovevi dare precedenza o provenivi da un luogo privato?',
  possibleAnswers: [{
    value: Q3_29_AnswersEnum.SI,
    label: 'Sì',
  }, {
    value: Q3_29_AnswersEnum.NO,
    label: 'No',
  }, {
    value: Q3_29_AnswersEnum.NESSUNA,
    label: 'Nessuna delle precedenti',
  }]
}

export enum Q3_30_AnswersEnum {
  SI = 'ctp-stop-sem-pre-priv-si',
  NO = 'ctp-stop-sem-pre-priv-no',
  NESSUNA = 'nessuna-ctp-stop-sem-pre-priv'
}

CAI_QUESTIONS[CaiAnswersEnum.Q3_30] = {
  code: CaiAnswersEnum.Q3_30,
  title: 'Controparte aveva lo stop o il semaforo rosso o doveva dare precedenza o proveniva da un luogo privato?',
  possibleAnswers: [{
    value: Q3_30_AnswersEnum.SI,
    label: 'Sì',
  }, {
    value: Q3_30_AnswersEnum.NO,
    label: 'No',
  }, {
    value: Q3_30_AnswersEnum.NESSUNA,
    label: 'Nessuna delle precedenti',
  }]
}

export enum Q3_31_AnswersEnum {
  SI = 'ass-retro-si',
  NO = 'ass-retro-no',
  NESSUNA = 'nessuna-ass-retro'
}

CAI_QUESTIONS[CaiAnswersEnum.Q3_31] = {
  code: CaiAnswersEnum.Q3_31,
  title: 'Procedevi in retromarcia?',
  possibleAnswers: [{
    value: Q3_31_AnswersEnum.SI,
    label: 'Sì',
  }, {
    value: Q3_31_AnswersEnum.NO,
    label: 'No',
  }, {
    value: Q3_31_AnswersEnum.NESSUNA,
    label: 'Nessuna delle precedenti',
  }]
}

export enum Q3_32_AnswersEnum {
  NS = 'retro-ns',
  CTP = 'retro-ctp',
  BOTH = 'retro-both',
  NESSUNA_INV = 'nessuna-retro',
}

CAI_QUESTIONS[CaiAnswersEnum.Q3_32] = {
  code: CaiAnswersEnum.Q3_32,
  title: 'Chi retrocedeva?',
  possibleAnswers: [{
    value: Q3_32_AnswersEnum.NS,
    label: 'Io',
  }, {
    value: Q3_32_AnswersEnum.CTP,
    label: 'Controparte',
  }, {
    value: Q3_32_AnswersEnum.BOTH,
    label: 'Entrambi',
  }, {
    value: Q3_32_AnswersEnum.NESSUNA_INV,
    label: 'Nessuna delle precedenti',
  }]
}

export enum Q3_33_AnswersEnum {
  ASSICURATO = 'sosta-port-ns',
  CTP = 'sosta-port-ctp',
  BOTH = 'sosta-port-both',
  NESSUNA_INV = 'nessuna-sosta-port',
}

CAI_QUESTIONS[CaiAnswersEnum.Q3_33] = {
  code: CaiAnswersEnum.Q3_33,
  title: 'Chi si è mosso dopo una sosta o chi ha aperto la portiera?',
  possibleAnswers: [{
    value: Q3_33_AnswersEnum.ASSICURATO,
    label: 'Assicurato',
  }, {
    value: Q3_33_AnswersEnum.CTP,
    label: 'Controparte',
  }, {
    value: Q3_33_AnswersEnum.BOTH,
    label: 'Entrambi',
  }, {
    value: Q3_33_AnswersEnum.NESSUNA_INV,
    label: 'Nessuna delle precedenti',
  }]
}


export enum Q3_34_AnswersEnum {
  SI = 'ctp-stop-sem-pre-priv-si-2',
  NO = 'ctp-stop-sem-pre-priv-no-2',
  NESSUNA = 'nessuna-2-ctp-stop-sem-pre-priv'
}

CAI_QUESTIONS[CaiAnswersEnum.Q3_34] = {
  code: CaiAnswersEnum.Q3_34,
  title: 'Controparte aveva lo stop o il semaforo rosso o doveva dare precedenza o proveniva da un luogo privato?',
  possibleAnswers: [{
    value: Q3_34_AnswersEnum.SI,
    label: 'Sì',
  }, {
    value: Q3_34_AnswersEnum.NO,
    label: 'No',
  }, {
    value: Q3_34_AnswersEnum.NESSUNA,
    label: 'Nessuna delle precedenti',
  }]
}


export class BaremeResponses {
  public Q1_Answer?: Q1_AnswersEnum;
  public Q2_Answer?: Q2_AnswersEnum;
  public Q3_13_Answer?: Q3_13_AnswersEnum;
  public Q3_28_Answer?: Q3_28_AnswersEnum;
  public Q3_29_Answer?: Q3_29_AnswersEnum;
  public Q3_30_Answer?: Q3_30_AnswersEnum;
  public Q3_31_Answer?: Q3_31_AnswersEnum;
  public Q3_32_Answer?: Q3_32_AnswersEnum;
  public Q3_33_Answer?: Q3_33_AnswersEnum;
  public Q3_34_Answer?: Q3_34_AnswersEnum;
  constructor(input?: Partial<BaremeResponses>) {
    if (input) {
      this.Q1_Answer = input.Q1_Answer
      this.Q2_Answer = input.Q2_Answer
      this.Q3_13_Answer = input.Q3_13_Answer
      this.Q3_28_Answer = input.Q3_28_Answer
      this.Q3_29_Answer = input.Q3_29_Answer
      this.Q3_30_Answer = input.Q3_30_Answer
      this.Q3_31_Answer = input.Q3_31_Answer
      this.Q3_32_Answer = input.Q3_32_Answer
      this.Q3_33_Answer = input.Q3_33_Answer
      this.Q3_34_Answer = input.Q3_34_Answer
    }
  }
}

