
import { useNavigate, useParams } from "react-router-dom";
import { Section } from "../components/styled/Containers.style";
import { selectCommunicationData } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { PageTitle, TextDefault, TextLightB } from "../components/styled/Text.style";
import { SeparatorLight, Vspace } from '../components/styled/Separators.style'
import { composeUrl } from "../utils/utils";
import { useState } from "react";
import { PATHS } from "../routes/CaiExperienceRouter";
import { saveCommunicationAct } from "../store/communication-data/actions";
import { CommunicationType, FileItemType } from "../types/communication/communicationDataType";
import PageContainerCmp from "./PageContainerCmp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { InfoCard } from "../components/styled/Card.style";
import { CardIconContainer } from "../components/styled/Icon.style";
import FilesUploadListCmp from "../components/file/FilesUploadListCmp";

const FotoContestoPage = () => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let { token } = useParams();

    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData

    const numOfVehicles = communication.numOfVeichles

    const [fotoContestoList, setFotoContestoList] = useState<FileItemType[]>(communication.fotoContestoList)

    const goToNextStepHandler = () => {
        let nextPath: string;
        let data;

        if(numOfVehicles !== 2) {
            nextPath = PATHS.END_SIGNATURE_MANY_VEHICLES;
            data = undefined
        } else {
            nextPath = PATHS.CAI_FILLING_CONDUCENTE

            data = new CommunicationType({
                ...communication,
                fotoContestoList
            })
        }

        dispatch(saveCommunicationAct({
            data,
            callback: () => navigate(composeUrl(token, nextPath))
        }))
    }

    return (
        <>
            <PageContainerCmp
                nextStepHandler={goToNextStepHandler}
                routePath={PATHS.FOTO_DEL_CONTESTO}
            >
                <>
                    <Section>
                        <PageTitle>Foto del contesto</PageTitle>
                        <Vspace></Vspace>
                        <TextDefault centered>Ti chiediamo inoltre di caricare le foto dell’incidente nel suo contesto: segnaletica ed eventuali danni materiali.</TextDefault>
                    </Section>

                    <Section>
                        <FilesUploadListCmp
                            files={fotoContestoList}
                            setFiles={setFotoContestoList}
                            fileType={"OTHER-PH"}
                            arrayDbPropSync={"fotoContestoList"}
                            textLabel={"Aggiungi foto"}
                             />
                    </Section>

                    <Section>
                        <InfoCard>
                            <CardIconContainer>
                                <FontAwesomeIcon icon={faCamera} />
                            </CardIconContainer>
                            <TextLightB centered>
                                Ti consigliamo di fare anche una foto in cui la <strong>targa della controparte</strong> sia visibile.
                            </TextLightB>
                            <SeparatorLight></SeparatorLight>
                            <TextLightB centered>
                                Per privacy è vietato fotografare persone.
                            </TextLightB>
                        </InfoCard>
                    </Section>

                </>
            </PageContainerCmp>
        </>

    )
}

export default FotoContestoPage