
import { selectCommunicationData } from "../store";
import { useAppSelector } from "../store/hooks";
import { CaiAnswersEnum, Q3_13_AnswersEnum, Q3_28_AnswersEnum, Q3_29_AnswersEnum, Q3_30_AnswersEnum, Q3_31_AnswersEnum, Q3_32_AnswersEnum, Q3_33_AnswersEnum, Q3_34_AnswersEnum } from "../types/communication/caiType";
import { useEffect, useState } from "react";
import { Q3Cmp } from "./Q3Cmp";
import { computeQ3Enum } from "../utils/bareme.utils";
import { useNavigate, useParams } from "react-router-dom";
import { composeUrl } from "../utils/utils";
import { PATHS } from "../routes/CaiExperienceRouter";

const Q3ContainerPage = () => {
    const communicationData = useAppSelector(selectCommunicationData)
    let { token } = useParams();
    let navigate = useNavigate();
    const { communication } = communicationData

    const [currentQ3Enum, setCurrentQ3Enum] = useState<CaiAnswersEnum>()
    useEffect(() => {
        const computedQ3Enum: CaiAnswersEnum | undefined = computeQ3Enum(communication)
        if (!computedQ3Enum) {
            // go to dynamic image
            const _pathname = composeUrl(token, PATHS.BAREME_Q_DYNAMIC)
            navigate(_pathname)
        }
        setCurrentQ3Enum(computedQ3Enum)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {(currentQ3Enum === CaiAnswersEnum.Q3_13) &&
                <Q3Cmp<Q3_13_AnswersEnum>
                    Q3_AnswerPropName="Q3_13_Answer"
                    caiAnswersEnum={CaiAnswersEnum.Q3_13} />
            }
            {(currentQ3Enum === CaiAnswersEnum.Q3_28) &&
                <Q3Cmp<Q3_28_AnswersEnum>
                    Q3_AnswerPropName="Q3_28_Answer"
                    caiAnswersEnum={CaiAnswersEnum.Q3_28} />
            }
            {(currentQ3Enum === CaiAnswersEnum.Q3_29) &&
                <Q3Cmp<Q3_29_AnswersEnum>
                    Q3_AnswerPropName="Q3_29_Answer"
                    caiAnswersEnum={CaiAnswersEnum.Q3_29} />
            }
            {(currentQ3Enum === CaiAnswersEnum.Q3_30) &&
                <Q3Cmp<Q3_30_AnswersEnum>
                    Q3_AnswerPropName="Q3_30_Answer"
                    caiAnswersEnum={CaiAnswersEnum.Q3_30} />
            }
            {(currentQ3Enum === CaiAnswersEnum.Q3_31) &&
                <Q3Cmp<Q3_31_AnswersEnum>
                    Q3_AnswerPropName="Q3_31_Answer"
                    caiAnswersEnum={CaiAnswersEnum.Q3_31} />
            }
            {(currentQ3Enum === CaiAnswersEnum.Q3_32) &&
                <Q3Cmp<Q3_32_AnswersEnum>
                    Q3_AnswerPropName="Q3_32_Answer"
                    caiAnswersEnum={CaiAnswersEnum.Q3_32} />
            }
            {(currentQ3Enum === CaiAnswersEnum.Q3_33) &&
                <Q3Cmp<Q3_33_AnswersEnum>
                    Q3_AnswerPropName="Q3_33_Answer"
                    caiAnswersEnum={CaiAnswersEnum.Q3_33} />
            }
            {(currentQ3Enum === CaiAnswersEnum.Q3_34) &&
                <Q3Cmp<Q3_34_AnswersEnum>
                    Q3_AnswerPropName="Q3_34_Answer"
                    caiAnswersEnum={CaiAnswersEnum.Q3_34} />
            }
        </>
    )
}

export default Q3ContainerPage