import styled from 'styled-components'
import { CenteredContainer, HCenteredContainer } from './Common.style'

interface RadioOptionsProps {
  horizontal?: boolean;
}

export const RadioCmpContainer = styled(HCenteredContainer)`
  align-items: flex-start;
  width: 100%;
`

export const RadioOptionsLabel = styled.p`
    font-family: "Bold";
    line-height: 22px;
    color: #3079c9;
    font-size: 16px;`

export const RadioItem = styled(CenteredContainer) <RadioOptionsProps>`
  cursor: pointer;
  width: 75%;
  justify-content: flex-start;
  padding: 0.7rem 0 1rem;
  border-bottom: 1px solid #e6e6e6;

  ${(props: RadioOptionsProps) => props.horizontal && `
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    margin: 0;
    border-bottom: none;
  `}
`

export const RadioCheck = styled(CenteredContainer)`
  cursor: pointer;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  min-width: 25px;
  background-color: #eee;

  &.active {
    background-color: #3079c9;
  }

  svg {
    color: #fff;
    font-size: 18px;
  }
`

export const RadioLabel = styled.p<RadioOptionsProps>`
  cursor: pointer;
  font-family: "medium";
  font-size: 17px;
  margin-left: 1rem;
  line-height: 22px;
  margin-top: 2px;
  color: #0f3250;
  ${(props: RadioOptionsProps) => props.horizontal && `
    margin-left: 0.7rem;
    margin-right: 1.5rem;
  `}
`

export const RadioSubLabel = styled.p `
  font-family: "Unipol-Book";
  line-height: 20px;
  color: #666666;
  margin-left: 1rem;
`

export const RadioLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const RadioOptionsContainer = styled(HCenteredContainer) <RadioOptionsProps>`
  width: 100%;

  ${(props: RadioOptionsProps) => props.horizontal && `
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    margin: 0;
  `}

  &.cai ${RadioLabel}{
    font-size: 15px;
    font-family: "Book";
  }
`




