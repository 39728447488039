import { StateAction } from "../../types/commonTypes";


interface IStateAction {
  UPDATE_STATE: string
}

export const genericStateReducer = <T>(stateAction: IStateAction) => (state: T, action: StateAction<Partial<T>>): T => {
  if (action.type === stateAction.UPDATE_STATE) {
    return {
      ...state,
      ...action.payload
    };
  }
  throw Error('Unknown action.');
}