
import { RealtimeModal } from "../../components/modal/RealtimeModal"
import { EventValueEnum } from "../../types/communication/communicationDataType"
import { tqPageView } from "../../utils/utils"
import { CancelConfirmIdentityModalContent } from "../cancel-confirm-identity/CancelConfirmIdentityModalContent"

interface CancelConfirmIdentityModalProps {
    modalOpened: boolean
    onCloseHandler?: any
    event?: EventValueEnum
}

/* istanbul ignore next */
const CancelConfirmIdentityModal = (props: CancelConfirmIdentityModalProps) => {
    const { modalOpened, onCloseHandler, event } = props

    const onCurrentModalCloseHandler = () => {
        /* istanbul ignore next */
        onCloseHandler && onCloseHandler()
    }

    return <>
        <RealtimeModal
            modalOpened={modalOpened}
            hTitle="Informazioni mancanti"
            hCloseButton={true}
            onCloseHandler={onCurrentModalCloseHandler}
            onOpenHandler={() => {
                // Analytics
                 /* istanbul ignore next */
                tqPageView('cai:cai:documento di riconoscimento:info mancante')
            }}>
            <CancelConfirmIdentityModalContent
                additionalData={{ event }} />
        </RealtimeModal>
    </>
}

export default CancelConfirmIdentityModal