import { selectCommunicationData } from "../../store";
import { saveCommunicationAct } from "../../store/communication-data/actions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { CommunicationType, FileItemType } from "../../types/communication/communicationDataType"
import { Vspace } from "../styled/Separators.style";
import FileListCmp from "./FileListCmp"
import FileUploadCmp from "./FileUploadCmp"

interface FilesUploadListCmpProps {
    files: FileItemType[];
    setFiles: any,
    fileType: string;
    arrayDbPropSync?: string;
    textLabel?: string;
}

const FilesUploadListCmp = (props: FilesUploadListCmpProps) => {
    const dispatch = useAppDispatch();
    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData
    const { files, setFiles, fileType, arrayDbPropSync, textLabel } = props

    const updateDbCommunicationFileList = (newFileList: FileItemType[]) => {
        if (arrayDbPropSync) {
            const updComm: any = new CommunicationType(communication)
            updComm[arrayDbPropSync] = newFileList
            dispatch(saveCommunicationAct({
                data: updComm
            })
            )
        }
    }

    const onFileUpsertHandler = (_file?: FileItemType) => {
        if (!_file) {
            return _file
        }
        setFiles((currentFiles: FileItemType[]) => {
            let fileFound = false
            const newFiles = currentFiles.map((file: FileItemType) => {
                if ((_file._id === file._id) ||
                    (_file.name === file.name && !file._id)
                ) {
                    fileFound = true;
                    return _file
                } else {
                    return file
                }
            }) || []
            let newFilesArray = []
            if (fileFound) {
                newFilesArray = [...newFiles]
            } else {
                newFilesArray = [...newFiles, _file]
            }

            // sync db array prop, if present
            if (_file._id) {
                updateDbCommunicationFileList(newFilesArray)
            }
            return newFilesArray
        })
    }

    const onFileRemovedHandler = (_file?: FileItemType) => {
        if (!_file) {
            return _file
        }
        setFiles((currentFiles: FileItemType[]) => {
            const newFilesArray = currentFiles.filter((file: FileItemType) => file._id !== _file._id)
            updateDbCommunicationFileList(newFilesArray)
            return newFilesArray;
        })
    }

    const fileUploadCmp = <FileUploadCmp
        files={files}
        onFileAdded={onFileUpsertHandler}
        onFileRemoved={onFileRemovedHandler}
        className={files.length === 0 ? "foto-contesto-file-upload" : "foto-contesto-file-upload wrapped"}
        fileType={fileType}
        textLabel={textLabel}
    />

    return (
        <>
            {files.length === 0 ? fileUploadCmp : <></>}
            <Vspace qty={2}></Vspace>

            <FileListCmp
                children={files.length ? fileUploadCmp : <></>}
                files={files}
                onFileRemoved={onFileRemovedHandler}
                className={"foto-contesto-file-upload"} />
            <Vspace qty={2}></Vspace>
        </>
    )
}

export default FilesUploadListCmp