import styled from 'styled-components'
import { CenteredContainer } from './Common.style'

export const BreadcrumbContainer = styled(CenteredContainer) `
  position: fixed;
  width: 100%;
  top: 50px;
  left: 0;
  background-color: #f8f8f8;
  justify-content: flex-start;
  border-bottom: 1px #cecece solid;
  z-index: 9;

  &.in-app {
    top: 0;
  }
`

export const BreadcrumbStepsContainer = styled(CenteredContainer) `
  width: 100%;
  position: relative;
  flex: 1;
  justify-content: flex-start;
  padding: 0 1rem;

  &::before {
    content: "";
    position: absolute;
    left: 5%;
    top: 50%;
    width: 90%;
    transform: translateY(-50%);
    height: 1px;
    background: linear-gradient(to right, transparent 0%, transparent calc(12px), #cecece calc(12px), #cecece calc(100% - 12px), transparent calc(100% - 12px), transparent 100%);

  }
`

export const BreadcrumbStepItem = styled(CenteredContainer) `
    background-color: #cecece;
    color: transparent;
    width: 18px;
    height: 18px;
    transform: rotate(45deg);
    z-index: 10;
    margin-right: 0.8rem;

    span {
      transform: rotate(-45deg);
      color: #fff;
      font-family: "Bold";
      text-transform: uppercase;
    }

    &.active {
      background-color: #3079c9;
      margin-right: auto;
    }
`

interface BreadcrumbTitleProps {
  activeIndex: number;
}

export const BreadcrumbTitle = styled.p<BreadcrumbTitleProps> `
  font-family: "Bold";
  font-style: normal;
  text-transform: uppercase;
  font-size: 17px;
  color: #3079c9;
  background-color: #f8f8f8;
  padding: 0;
  padding-left: 5px;
  padding-right: 5px;
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  left: ${(props: BreadcrumbTitleProps) => props.activeIndex * 2.65}rem;
`

