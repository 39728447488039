import React, { useEffect } from 'react';

import { Store } from "redux";
import { Provider } from "react-redux";
import {
  RouterProvider
} from "react-router-dom"; 
import { ApplicationState } from './types/commonTypes';
import './App.scss';
import HeaderCmp from "./components/header/Header";
import { RouteWrapper } from "./components/styled/Containers.style";
import { router } from "./Router";
import { useAppSelector } from './store/hooks';
import { getIsInAppClass } from './utils/utils';
import { selectCommunicationData } from './store';
  
declare global {
  interface Window {
    INITIAL_REDUX_STATE: any;
    REACT_APP_CAPTCHA_SITEKEY: string;
    REACT_APP_SKIP_CAPTCHA: string;
    REACT_APP_POLLING_INTERVAL_IN_MS: number;
    REACT_APP_VTV_API_ENDPOINT: string;
    REACT_APP_MAP_APP_ID: string;
    REACT_APP_MAP_APP_CODE: string;
    REACT_APP_CAI_COMMUNICATION_EXPIRATION_IN: string;
    REACT_APP_CAI_SIGNATURE_EXPIRATION_IN: string;
    utag: any;
  }
}

interface MainProps {
  store: Store<ApplicationState>;
}
const AppCmpWithStore = () => {
  const communicationData = useAppSelector(selectCommunicationData)
  const { communication } = communicationData

  /* istanbul ignore next */
  useEffect(() => {
    if (!communication?.token) {
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communication?.token])

  const isInAppClass = getIsInAppClass()

  return (
    <>
      <div className={`app ${isInAppClass}`}>
        <HeaderCmp className={isInAppClass} />
        <RouteWrapper className={isInAppClass}>
          <RouterProvider router={router} />
        </RouteWrapper>
      </div>
    </>
  );
};

const App: React.FC<MainProps> = ({ store }) => {

  return (
    <>
      <Provider store={store}>
        <AppCmpWithStore />
      </Provider>
    </>
  );
};

export default App;
