
import { useNavigate, useParams } from "react-router-dom";
import { Section } from "../components/styled/Containers.style";
import { selectCommunicationData } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { PageTitle } from "../components/styled/Text.style";
import { PATHS } from "../routes/CaiExperienceRouter";
import PageContainerCmp from "./PageContainerCmp";
import RadioOptionsCmp from "../components/radio/RadioOptionsCmp";
import { getValueLabelOptions, ValueLabelOption } from "../types/commonTypes";
import { BaremeResponses, CaiAnswersEnum, CAI_QUESTIONS, Q3_13_AnswersEnum, Q3_28_AnswersEnum, Q3_29_AnswersEnum, Q3_30_AnswersEnum, Q3_31_AnswersEnum, Q3_32_AnswersEnum, Q3_33_AnswersEnum, Q3_34_AnswersEnum } from "../types/communication/caiType";
import { useEffect, useState } from "react";
import { composeUrl } from "../utils/utils";
import { saveCommunicationAct } from "../store/communication-data/actions";
import { BaremeUserChoiseEnum, CommunicationType } from "../types/communication/communicationDataType";

// TabBar.tsx
interface Q3CmpProps {
    Q3_AnswerPropName: keyof BaremeResponses;
    caiAnswersEnum: CaiAnswersEnum;
}

/* istanbul ignore next */
export function Q3Cmp<T>(props: Q3CmpProps) {
    const { Q3_AnswerPropName, caiAnswersEnum } = props
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let { token } = useParams();

    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData

    const currQ = CAI_QUESTIONS[caiAnswersEnum]

    const [q3Resp, setQ3Resp] = useState<T | undefined>()
    useEffect(() => {
        setQ3Resp(communication.baremeResponses[Q3_AnswerPropName] as T)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [communication.baremeResponses[Q3_AnswerPropName]])

    const goToNextStepHandler = () => {
        const updtBaremeResp = new BaremeResponses({
            Q1_Answer: communication.baremeResponses.Q1_Answer,
            Q2_Answer: communication.baremeResponses.Q2_Answer
        })
        updtBaremeResp[Q3_AnswerPropName] = q3Resp as any
        let _pathname = composeUrl(token, PATHS.BAREME_Q_DYNAMIC)

        // if 'nessuna delle precedenti, then go to free description page'
        if ([Q3_13_AnswersEnum.NESSUNA_INV,
            Q3_28_AnswersEnum.NESSUNA_RETRO,
            Q3_29_AnswersEnum.NESSUNA,
            Q3_30_AnswersEnum.NESSUNA,
            Q3_31_AnswersEnum.NESSUNA,
            Q3_32_AnswersEnum.NESSUNA_INV,
            Q3_33_AnswersEnum.NESSUNA_INV,
            Q3_34_AnswersEnum.NESSUNA].includes(q3Resp as any)) {
            // go to dynamic image
            _pathname = composeUrl(token, PATHS.FREE_INCIDENT_DESCRIPTION)
        }

        dispatch(saveCommunicationAct({
            data: new CommunicationType({
                ...communication,
                baremeUserChoise: BaremeUserChoiseEnum.BAREME_QUESTIONS_FILLED_ACCEPTED,
                baremeResponses: updtBaremeResp
            }),
            callback: () => navigate(_pathname),
            computeBareme: true
        })
        )
    }

    return (
        <>
            <PageContainerCmp
                nextStepHandler={goToNextStepHandler}
                routePath={PATHS.Q3_container}
                nextDisabled={!q3Resp}
            >
                <>
                    <Section>
                        <PageTitle>{currQ.title}</PageTitle>
                    </Section>
                    <Section>
                        <RadioOptionsCmp
                            options={currQ.possibleAnswers}
                            value={getValueLabelOptions<string>(currQ.possibleAnswers, q3Resp as string)}
                            className={'bareme-q3'}
                            onChange={(newChoise: ValueLabelOption<T>) => setQ3Resp(newChoise.value)}
                        />
                    </Section>

                </>
            </PageContainerCmp>
        </>
    )
}