import { useEffect, useReducer } from "react";
import { caiValidationUpdateAct } from "../../store/communication-data/actions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { booleanWithDnRadioOptions, BoolStringEnum, CommonInfoCmpProps, getValueLabelOptions, ValueLabelOption } from "../../types/commonTypes";
import { ControparteInfo, contactCompleteInfoSchema_OptionalMail, contactInfoSchema_OptionalMail, ContactInfo, ctp_conducente_contactInfoItems, ctp_conducente_noIntestatario_contactInfoItems } from "../../types/communication/communicationDataType";
import RadioOptionsCmp from "../radio/RadioOptionsCmp";
import { Section } from "../styled/Containers.style";
import ContactInfoCmp from "../contact/ContactInfoCmp";
import { genericStateReducer } from "../contact/genericStateReducer";
import { VPercentageSeparator, Vspace } from "../styled/Separators.style";
import { TextDefault } from "../styled/Text.style";
import { selectCommunicationData } from "../../store";
import { isIpernaturale } from "../../utils/utils";

export const ControparteInfoStateAction = {
    UPDATE_STATE: '@@controparteInfo/UPDATE_STATE'
}

const CaiControparteCmp = (props: CommonInfoCmpProps<ControparteInfo>) => {
    const communicationData = useAppSelector(selectCommunicationData)
    const { ctp, communication } = communicationData
    const dispatch = useAppDispatch();
    const { initValues, onChange } = props
    const [controparteState, controparteDispatch] = useReducer(genericStateReducer<ControparteInfo>(ControparteInfoStateAction), initValues);

    useEffect(() => {
        onChange && onChange(controparteState)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controparteState])


    useEffect(() => {
        // init conducente info with assicurato info
        if (isIpernaturale(communication)) {
            const initCtpAssicInfo = controparteState.y_ctp_contactInfo
            initCtpAssicInfo.name = initCtpAssicInfo.name || ctp.assic_nome
            initCtpAssicInfo.surname = initCtpAssicInfo.surname || ctp.assic_cognome
            initCtpAssicInfo.email = initCtpAssicInfo.email || ctp.assic_email
            initCtpAssicInfo.phoneNumber = initCtpAssicInfo.phoneNumber || ctp.phone_number
            controparteDispatch({
                type: ControparteInfoStateAction.UPDATE_STATE, payload: {
                    y_ctp_contactInfo: new ContactInfo(initCtpAssicInfo)
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Section>
                <RadioOptionsCmp
                    horizontal
                    otpionsLabel="Il conducente dell’altra vettura coinvolta è anche intestatario della polizza? (*)"
                    options={booleanWithDnRadioOptions}
                    value={getValueLabelOptions(booleanWithDnRadioOptions, controparteState.alsoOwner)}
                    className={'cai controparte-anche-intestatario'}
                    onChange={(newChoise: ValueLabelOption<BoolStringEnum>) =>
                        controparteDispatch({ type: ControparteInfoStateAction.UPDATE_STATE, payload: { alsoOwner: newChoise.value } })}
                />
                <VPercentageSeparator></VPercentageSeparator>
            </Section>

            <TextDefault intM={true} centered sm>Inserisci alcuni dati relativi alla controparte necessari per procedere con la Constatazione Amichevole digitale.</TextDefault>
            <Vspace></Vspace>

            {controparteState?.alsoOwner === BoolStringEnum.Y &&
                <>
                    <ContactInfoCmp
                        initValues={controparteState?.y_ctp_contactInfo}
                        contactItems={isIpernaturale(communication) ? ctp_conducente_contactInfoItems : ctp_conducente_noIntestatario_contactInfoItems}
                        onContactChange={(newContactInfo: Partial<ContactInfo>) =>
                            controparteDispatch({
                                type: ControparteInfoStateAction.UPDATE_STATE, payload: {
                                    y_ctp_contactInfo: new ContactInfo({
                                        ...controparteState,
                                        ...newContactInfo
                                    })
                                }
                            })}
                        validationSchema={
                            /* istanbul ignore next */
                            isIpernaturale(communication) ? contactInfoSchema_OptionalMail : contactCompleteInfoSchema_OptionalMail
                        }
                        onValiddationStateChange={(formState: any) =>
                            dispatch(caiValidationUpdateAct({ y_ctp_contactInfo: formState?.isValid }))
                        }
                    />
                </>}

            {controparteState?.alsoOwner === BoolStringEnum.N &&
                <>
                    <ContactInfoCmp
                        initValues={controparteState?.n_ctp_contactInfo}
                        contactItems={ctp_conducente_noIntestatario_contactInfoItems}
                        onContactChange={(newContactInfo: Partial<ContactInfo>) =>
                            controparteDispatch({
                                type: ControparteInfoStateAction.UPDATE_STATE, payload: {
                                    n_ctp_contactInfo: new ContactInfo({
                                        ...controparteState,
                                        ...newContactInfo
                                    })
                                }
                            })}
                        validationSchema={contactCompleteInfoSchema_OptionalMail}
                        onValiddationStateChange={(formState: any) =>
                            dispatch(caiValidationUpdateAct({ n_ctp_contactInfo: formState?.isValid }))
                        }
                    />
                </>}

            {controparteState?.alsoOwner === BoolStringEnum.DN &&
                <>
                    <ContactInfoCmp
                        initValues={controparteState?.dn_ctp_contactInfo}
                        contactItems={ctp_conducente_noIntestatario_contactInfoItems}
                        onContactChange={(newContactInfo: Partial<ContactInfo>) =>
                            controparteDispatch({
                                type: ControparteInfoStateAction.UPDATE_STATE, payload: {
                                    dn_ctp_contactInfo: new ContactInfo({
                                        ...controparteState,
                                        ...newContactInfo
                                    })
                                }
                            })}
                        validationSchema={contactCompleteInfoSchema_OptionalMail}
                        onValiddationStateChange={(formState: any) =>
                            dispatch(caiValidationUpdateAct({ dn_ctp_contactInfo: formState?.isValid }))
                        }
                    />
                </>}
        </>
    )
}

export default CaiControparteCmp