import '../App.scss';
import { RouteType } from "../types/commonTypes";
import WelcomePage from "../pages/WelcomePage";
import CosaTiChiediamoPage from "../pages/CosaTiChiediamoPage";
import VeicoliEPasseggeriPage from "../pages/VeicoliEPasseggeriPage";
import RaccoltaDatiContropartePage from "../pages/RaccoltaDatiContropartePage";
import NoSinistroPage from "../pages/NoSinistroPage";
import OraELuogoPage from "../pages/OraELuogoPage";
import ModificaLuogoIncidentePage from "../pages/ModificaLuogoIncidentePage";
import BachBaremeDynamicPage from "../pages/BachBaremeDynamicPage";
import BaremeFreeDescriptionPage from "../pages/BaremeFreeDescriptionPage";
import EndSorryToDisturbPage from "../pages/EndSorryToDisturbPage";
import FotoContestoPage from "../pages/FotoContestoPage";
import CaiFillingConducentePage from "../pages/CaiFillingConducentePage";
import CaiFeritiETestimoniPage from "../pages/CaiFeritiETestimoniPage";
import CaiDatiContropartePage from "../pages/CaiDatiContropartePage";
import CaiPreviewPage from "../pages/CaiPreviewPage";
import ZoneUrtoFotoPage from "../pages/ZoneUrtoFotoPage";
import CtpZoneUrtoFotoPage from "../pages/CtpZoneUrtoFotoPage";
import ForceWaitCaiIsFillingPage from "../pages/ForceWaitCaiIsFillingPage";
import NoSignPage from "../pages/NoSignPage";
import EndProcedureCancelledPage from "../pages/EndProcedureCancelledPage";
import EndSignatureForwardSuccessPage from "../pages/EndSignatureForwardSuccessPage";
import EndProcessPage from "../pages/EndProcessPage";
import Q1Page from "../pages/Q1Page";
import Q2Page from "../pages/Q2Page";
import Q3ContainerPage from "../pages/Q3ContainerPage";
import BaremeQDynamicPage from "../pages/BaremeQDynamicPage";
import BaremeFreeSelezionaCircostanzaPage from "../pages/BaremeFreeSelezionaCircostanzaPage";
import RealtimeAccettazioneCaiPage from "../pages/RealtimeAccettazioneCaiPage";
import EndSignatureManyVehiclesPage from "../pages/EndSignatureManyVehiclesPage";
import EndCommunicationForwardedPage from "../pages/EndCommunicationForwardedPage";
import EndProcessPageCtpAccepted from "../pages/EndProcessPageCtpAccepted";
import EndTokenExpiredPage from "../pages/EndTokenExpiredPage";
import ConfirmIdentityUploadDocPage from '../pages/ConfirmIdentityUploadDocPage';
import CaiPaperUploadPage from '../pages/CaiPaperUploadPage';
import EndProcessCaiPaperPage from '../pages/EndProcessCaiPaperPage';
import CtpProposePaperCaiPage from '../pages/CtpProposePaperCaiPage';


export const PATHS = {
    WELCOME_FIRST_PAGE: "welcome_first_page",
    COSA_TI_CHIEDIAMO: "cosa-ti-chiediamo",
    VEICOLI_E_PASSEGGERI: "veicoli-e-passeggeri",
    RACCOLTA_DATI_CONTROPARTE: "raccolta-dati-controparte",
    NO_INCIDENT_PAGE: "no-sinistro",
    ORA_E_LUOGO: "ora-e-luogo",
    MODIFICA_LUOGO_INCIDENTE: "modifica-luogo-incidente",
    BACH_BAREME_DYNAMIC: "batch-bareme-dynamic",
    BAREME_Q_DYNAMIC: "bareme-q-dynamic",
    ZONE_URTO_E_FOTO: "zone-urto-e-foto",
    CTP_ZONE_URTO_E_FOTO: "ctp-zone-urto-e-foto",
    FREE_INCIDENT_DESCRIPTION: "free-incident-description",
    FREE_SELEZIONA_CIRCOSTANZA: "free-seleziona-circostanza",
    FOTO_DEL_CONTESTO: "foto-del-contesto",
    CAI_FILLING_CONDUCENTE: "cai-filling-conducente",
    CAI_FERITI_E_TESTIMONI: "cai-feriti-e-testimoni",
    CAI_DATI_CONTROPARTE: "cai-dati-controparte",
    CAI_PREVIEW: 'cai-preview',
    Q1: 'q1',
    Q2: 'q2',
    Q3_container: 'q3_container',
    NO_SIGN: "no-sign",
    REALTIME_ACCETTAZIONE_CAI: 'realtime-accettazione-cai',
    END_SORRY_TO_DISTURB: "end-sorry-to-disturb",
    END_COMMUNICATION_FORWARDED: 'end-communication-forwarded',
    END_SIGNATURE_FORWARD_SUCCESS: "end-signature-forward-success",
    END_SIGNATURE_MANY_VEHICLES: "end-signature-many-vehicles",
    END_PROCESS_PAGE: "end-process",
    END_PROCESS_CAI_PAPER_PAGE: "end-cai-paper-process",
    END_PROCESS_PAGE_CTP_ACCEPTED: "end-process-ctp-refused",
    FORCE_WAIT_CAI_IS_FILLING: "force-wait-cai-is-filling",
    PROCEDURE_CANCELLED: "procedure-cancelled",
    TOKEN_EXPIRED: "token-expired",
    CONFIRM_IDENTITY: "confirm-identity",
    CAI_PAPER: "cai-paper",
    CTP_PROPOSE_PAPER_CAI: "ctp-propose-paper-cai"
}

export const ROUTES: RouteType[] = [{
    path: PATHS.WELCOME_FIRST_PAGE,
    element: <WelcomePage />,
    tqPageName: 'cai:come ti aiuteremo'
}, {
    path: PATHS.REALTIME_ACCETTAZIONE_CAI,
    element: <RealtimeAccettazioneCaiPage />,
    tqPageName: 'cai:landing download documenti'
}, {
    path: PATHS.NO_INCIDENT_PAGE,
    element: <NoSinistroPage />,
    hasBreadcrumb: true,
    breadcrumbIndex: 1,
    defaultBackPath: PATHS.WELCOME_FIRST_PAGE,
    tqPageName: 'cai:nessun incidente: raccolta dati'
}, {
    path: PATHS.COSA_TI_CHIEDIAMO,
    element: <CosaTiChiediamoPage />,
    tqPageName: 'cai:cosa ti chiediamo'
}, {
    path: PATHS.VEICOLI_E_PASSEGGERI,
    element: <VeicoliEPasseggeriPage />,
    hasBreadcrumb: true,
    breadcrumbIndex: 1,
    defaultBackPath: PATHS.COSA_TI_CHIEDIAMO,
    tqPageName: 'cai:raccolta dati:veicoli e passeggeri'
}, {
    path: PATHS.RACCOLTA_DATI_CONTROPARTE,
    element: <RaccoltaDatiContropartePage />,
    hasBreadcrumb: true,
    breadcrumbIndex: 1,
    defaultBackPath: PATHS.VEICOLI_E_PASSEGGERI,
    tqPageName: 'cai:raccolta dati:dati controparte'
}, {
    path: PATHS.ORA_E_LUOGO,
    element: <OraELuogoPage />,
    hasBreadcrumb: true,
    breadcrumbIndex: 1,
    defaultBackPath: PATHS.RACCOLTA_DATI_CONTROPARTE,
    tqPageName: 'cai:raccolta dati:ora e luogo'
}, {
    path: PATHS.MODIFICA_LUOGO_INCIDENTE,
    element: <ModificaLuogoIncidentePage />,
    hasBreadcrumb: true,
    breadcrumbIndex: 1,
    defaultBackPath: PATHS.ORA_E_LUOGO,
    tqPageName: 'cai:raccolta dati:mappa'
}, {
    path: PATHS.BACH_BAREME_DYNAMIC,
    element: <BachBaremeDynamicPage />,
    hasBreadcrumb: true,
    breadcrumbIndex: 1,
    defaultBackPath: PATHS.ORA_E_LUOGO,
    tqPageName: 'cai:raccolta dati:descrizione dinamica'
}, {
    path: PATHS.BAREME_Q_DYNAMIC,
    element: <BaremeQDynamicPage />,
    hasBreadcrumb: true,
    breadcrumbIndex: 1,
    defaultBackPath: PATHS.Q1,
    tqPageName: 'cai:raccolta dati:descrizione dinamica'
}, {
    path: PATHS.FREE_INCIDENT_DESCRIPTION,
    element: <BaremeFreeDescriptionPage />,
    hasBreadcrumb: true,
    breadcrumbIndex: 1,
    defaultBackPath: PATHS.ORA_E_LUOGO,
    tqPageName: 'cai:raccolta dati:descrizione dinamica evento'
}, {
    path: PATHS.FREE_SELEZIONA_CIRCOSTANZA,
    element: <BaremeFreeSelezionaCircostanzaPage />,
    hasBreadcrumb: true,
    breadcrumbIndex: 1,
    defaultBackPath: PATHS.FREE_INCIDENT_DESCRIPTION,
    tqPageName: 'cai:raccolta dati:inserimento circostanze incidente'
}, {
    path: PATHS.ZONE_URTO_E_FOTO,
    element: <ZoneUrtoFotoPage />,
    hasBreadcrumb: true,
    breadcrumbIndex: 2,
    defaultBackPath: PATHS.BACH_BAREME_DYNAMIC,
    tqPageName: 'cai:foto evento:zone urto e foto'
}, {
    path: PATHS.CTP_ZONE_URTO_E_FOTO,
    element: <CtpZoneUrtoFotoPage />,
    hasBreadcrumb: true,
    breadcrumbIndex: 2,
    defaultBackPath: PATHS.ZONE_URTO_E_FOTO,
    tqPageName: 'cai:foto evento:zone urto e controparte'
}, {
    path: PATHS.FOTO_DEL_CONTESTO,
    element: <FotoContestoPage />,
    hasBreadcrumb: true,
    breadcrumbIndex: 2,
    defaultBackPath: PATHS.CTP_ZONE_URTO_E_FOTO,
    tqPageName: 'cai:foto evento:foto del contesto'
}, {
    path: PATHS.CAI_FILLING_CONDUCENTE,
    element: <CaiFillingConducentePage />,
    hasBreadcrumb: true,
    breadcrumbIndex: 3,
    defaultBackPath: PATHS.FOTO_DEL_CONTESTO,
    tqPageName: 'cai:cai:constatazione amichevole:step1'
}, {
    path: PATHS.CAI_FERITI_E_TESTIMONI,
    element: <CaiFeritiETestimoniPage />,
    hasBreadcrumb: true,
    breadcrumbIndex: 3,
    defaultBackPath: PATHS.CAI_FILLING_CONDUCENTE,
    tqPageName: 'cai:cai:constatazione amichevole:step2'
}, {
    path: PATHS.CAI_DATI_CONTROPARTE,
    element: <CaiDatiContropartePage />,
    hasBreadcrumb: true,
    breadcrumbIndex: 3,
    defaultBackPath: PATHS.CAI_FERITI_E_TESTIMONI,
    tqPageName: 'cai:raccolta dati:questionario:step3'
}, {
    path: PATHS.CAI_PREVIEW,
    element: <CaiPreviewPage />,
    hasBreadcrumb: true,
    breadcrumbIndex: 3,
    defaultBackPath: PATHS.CAI_DATI_CONTROPARTE,
    tqPageName: 'cai:cai:cai ipernaturale'
}, {
    path: PATHS.CONFIRM_IDENTITY,
    element: <ConfirmIdentityUploadDocPage />,
    hasBreadcrumb: true,
    breadcrumbIndex: 3,
    defaultBackPath: PATHS.CAI_PREVIEW,
    tqPageName: 'cai:cai:documento di riconoscimento'
}, {
    path: PATHS.CAI_PAPER,
    hasBreadcrumb: true,
    breadcrumbIndex: 3,
    element: <CaiPaperUploadPage />,
    tqPageName: 'cai:cai:caricamento constatazione cartacea'
}, {
    path: PATHS.Q1,
    element: <Q1Page />,
    hasBreadcrumb: true,
    breadcrumbIndex: 1,
    defaultBackPath: PATHS.ORA_E_LUOGO,
    tqPageName: 'cai:raccolta dati:questionario:step1'
}, {
    path: PATHS.Q2,
    element: <Q2Page />,
    hasBreadcrumb: true,
    breadcrumbIndex: 1,
    defaultBackPath: PATHS.Q1,
    tqPageName: 'cai:raccolta dati:questionario:step2'
}, {
    path: PATHS.Q3_container,
    element: <Q3ContainerPage />,
    hasBreadcrumb: true,
    breadcrumbIndex: 1,
    defaultBackPath: PATHS.Q2,
    tqPageName: 'cai:cai:constatazione amichevole:step4'
}, {
    path: PATHS.NO_SIGN,
    element: <NoSignPage />,
    hasBreadcrumb: true,
    breadcrumbIndex: 1,
    defaultBackPath: PATHS.CAI_PREVIEW,
    tqPageName: 'cai:cai:non voglio firmare'
}, {
    path: PATHS.END_SORRY_TO_DISTURB,
    hasFooter: true,
    element: <EndSorryToDisturbPage />,
    tqPageName: 'cai:nessun incidente:conclusione'
}, {
    path: PATHS.END_COMMUNICATION_FORWARDED,
    hasFooter: true,
    hasFooterDownloadButtons: true,
    element: <EndCommunicationForwardedPage />,
    tqPageName: 'cai:comunicazione inoltrata'
}, {
    path: PATHS.FORCE_WAIT_CAI_IS_FILLING,
    element: <ForceWaitCaiIsFillingPage />,
    tqPageName: 'cai:flusso interrotto'
}, {
    path: PATHS.CTP_PROPOSE_PAPER_CAI,
    element: <CtpProposePaperCaiPage />,
    defaultBackPath: PATHS.FORCE_WAIT_CAI_IS_FILLING,
    tqPageName: 'cai:sblocco controparte'
}, {
    path: PATHS.PROCEDURE_CANCELLED,
    hasFooter: true,
    hasFooterDownloadButtons: true,
    element: <EndProcedureCancelledPage />,
    tqPageName: 'cai:cai:conclusione con rifiuto firma'
}, {
    path: PATHS.TOKEN_EXPIRED,
    element: <EndTokenExpiredPage />,
    tqPageName: 'cai:tempo scaduto'
}, {
    path: PATHS.END_SIGNATURE_FORWARD_SUCCESS,
    hasFooter: true,
    hasFooterDownloadButtons: true,
    element: <EndSignatureForwardSuccessPage />,
    tqPageName: 'cai:cai:conclusione raccolta dati'
}, {
    path: PATHS.END_PROCESS_PAGE,
    hasFooter: true,
    hasFooterDownloadButtons: true,
    element: <EndProcessPage />,
    tqPageName: 'cai:cai:conclusione ok'
}, {
    path: PATHS.END_PROCESS_PAGE_CTP_ACCEPTED,
    hasFooter: true,
    hasFooterDownloadButtons: true,
    element: <EndProcessPageCtpAccepted />,
    tqPageName: 'cai:flusso concluso'
},
{
    path: PATHS.END_SIGNATURE_MANY_VEHICLES,
    hasFooter: true,
    hasFooterDownloadButtons: true,
    element: <EndSignatureManyVehiclesPage />,
    tqPageName: 'cai:conclusione avvio procedura per piu di 2 veicoli coinvolti'
}, {
    path: PATHS.END_PROCESS_CAI_PAPER_PAGE,
    hasFooter: true,
    hasFooterDownloadButtons: true,
    element: <EndProcessCaiPaperPage />,
    tqPageName: 'cai:conclusione avvio procedura'
}, {
    path: "",
    element: <WelcomePage />,
}]
