import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import useMinimumLoading from "../../utils/hooks/useMinimumLoading";
import LoaderCmp from "../loader/LoaderCmp";
import { CloseImgZoomBtn, FullPageOverlay, ImgZoomBtn, ZommableCmpContainer, ZommableImageContainer } from "../styled/Img.style";
import { tqPageView } from "../../utils/utils";

interface ZoomableImgCmpProps {
  imgSrc: string;
  alt?: string
}

    /* istanbul ignore next */
const ZoomableImgCmp = (props: ZoomableImgCmpProps) => {
  const { imgSrc, alt } = props;
  const [isFullPage, setIsFullPage] = useState(false);
  const isFullPageClass = isFullPage ? 'full-page-img' : '';
  const { isLoading, setIsLoading } = useMinimumLoading(false)
  const isLoadingClass = isLoading ? 'is-loading' : ''

  useEffect(() => {
    setIsLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleImageLoaded = () => {
    setIsLoading(false);
  }

  const handleImageErrored = () => {
    setIsLoading(false);
  }

  const setIsFullPageHandler = (newVal: boolean) => {
    setIsFullPage(true)
    // Analytics
    tqPageView('cai:cai:constatazione amichevole:cai ipernaturale:zoom cai')
  }

  return (
    <>
      <ZommableCmpContainer
        className={`${isFullPageClass} ${isLoadingClass}`}
        onClick={() => !isFullPage && !isLoading && setIsFullPageHandler(true)}>
        {isFullPage && <FullPageOverlay></FullPageOverlay>}
        {isLoading && <LoaderCmp className="cover" />}
        <ZommableImageContainer
          className={isFullPageClass}>
          <img
            src={imgSrc}
            alt={alt}
            onLoad={handleImageLoaded}
            onError={handleImageErrored} />
        </ZommableImageContainer>
        {
          !isLoading && !isFullPage && <ImgZoomBtn>
            <FontAwesomeIcon icon={faSearch} />
          </ImgZoomBtn>
        }
        {
          isFullPage && <CloseImgZoomBtn
            onClick={() => setIsFullPage(false)}>
            <FontAwesomeIcon icon={faTimes} />
          </CloseImgZoomBtn>
        }
      </ZommableCmpContainer>
    </>
  )
}

export default ZoomableImgCmp