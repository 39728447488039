import { useEffect, useReducer, useState } from "react"
import { booleanRadioOptions, BoolStringEnum, CommonInfoCmpProps, getValueLabelOptions, ValueLabelOption } from "../../types/commonTypes"
import { RimorchioInfo } from "../../types/communication/communicationDataType"
import { genericStateReducer } from "../contact/genericStateReducer"
import { RealtimeModal } from "../modal/RealtimeModal"
import RadioOptionsCmp from "../radio/RadioOptionsCmp"
import { CaiButton } from "../styled/Button.style"
import { CaiSection } from "../styled/Containers.style"
import RimorchioModalCmp from "./RimorchioModalCmp"
import { tqPageView } from "../../utils/utils"

export const RimorchioStateAction = {
    UPDATE_STATE: '@@rimorchio/UPDATE_STATE'
}

const CaiRimorchioCmp = (props: CommonInfoCmpProps<RimorchioInfo>) => {
    const { initValues, onChange } = props
    const [rimorchioState, rimorchioDispatch] = useReducer(genericStateReducer<RimorchioInfo>(RimorchioStateAction), initValues);

    const [rimorchioModalOpened, setRimorchioModalOpened] = useState<boolean>(false)
    useEffect(() => {
        onChange && onChange(rimorchioState)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rimorchioState])

    /* istanbul ignore next */
    const remorchioInfoModalConfirm = (retData: Partial<RimorchioInfo>) => {
        rimorchioDispatch({
            type: RimorchioStateAction.UPDATE_STATE,
            payload: { ...retData }
        })
    }

    /* istanbul ignore next */
    const customTextButton = rimorchioState.targa ? <span>&#10003;  Verifica Dettagli</span> : "+ Aggiungi informazioni";

    return (
        <>
            <CaiSection className="cai-section">
                <RadioOptionsCmp
                    horizontal
                    otpionsLabel="La vettura trainava un rimorchio?"
                    options={booleanRadioOptions}
                    value={getValueLabelOptions(booleanRadioOptions, rimorchioState.trainavaRimorchio)}
                    className={'cai anche-rimorchio'}
                    onChange={(newChoise: ValueLabelOption<BoolStringEnum>) =>
                        rimorchioDispatch({ type: RimorchioStateAction.UPDATE_STATE, payload: { trainavaRimorchio: newChoise.value } })}
                />

                {rimorchioState?.trainavaRimorchio === BoolStringEnum.Y && <CaiButton
                    onClick={() => setRimorchioModalOpened(true)}
                >{customTextButton}</CaiButton>}

                <RealtimeModal
                    modalOpened={rimorchioModalOpened}
                    hTitle="Aggiungi informazioni"
                    hCloseButton={true}
                    onConfirmHandler={remorchioInfoModalConfirm}
                    onCloseHandler={() => setRimorchioModalOpened(false)}
                    onOpenHandler={() => {
                        // Analytics
                        tqPageView('cai:cai:aggiungi informazioni:rimorchio')
                    }}>
                    <RimorchioModalCmp initValue={rimorchioState} />
                </RealtimeModal>
            </CaiSection>
        </>
    )
}

export default CaiRimorchioCmp