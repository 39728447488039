import styled from 'styled-components'

export const TextArea = styled.textarea `
    font-family: "Book";
    resize: none;
    width: 100%;
    height: 160px;
    padding: 1rem;
    background-color: #f5f5f5;
    border: 1px solid #cecece;
    color: #333333;
    border-radius: 0px;
    box-shadow: none;
    box-sizing: border-box;
    font-size: 17px;
    line-height: 25px;
    outline: none;
`
