
import { useNavigate, useParams } from "react-router-dom";
import { selectCommunicationData } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { composeUrl } from "../utils/utils";
import { PATHS } from "../routes/CaiExperienceRouter";
import { saveCommunicationAct } from "../store/communication-data/actions";
import { BaremeUserChoiseEnum, CommunicationType } from "../types/communication/communicationDataType";
import PageContainerCmp from "./PageContainerCmp";
import { Section } from "../components/styled/Containers.style";
import { PageTitle, TextDefault, TextDefaultItalic } from "../components/styled/Text.style";
import { Vspace } from "../components/styled/Separators.style";
import { AdditionalCard, AdditionalCardText, SquaredCard } from "../components/styled/Card.style";
import { CenteredContainer } from "../components/styled/Common.style";
import { LegendItemContainer, LegendItemIndicator, LegendItemLabel } from "../components/styled/Bareme.style";
import BaremeImgCmp from "../components/img/BaremeImgCmp";

const BachBaremeDynamicPage = () => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let { token } = useParams();

    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData

    const goToNextStepHandler = () => {
        dispatch(saveCommunicationAct({
            data: new CommunicationType({
                ...communication,
                baremeUserChoise: BaremeUserChoiseEnum.BAREME_BATCH_ACCEPTED
            }),
            callback: () => navigate(composeUrl(token, PATHS.ZONE_URTO_E_FOTO))
        })
        )
    }

    const describeIncidentHandler = () => {
        navigate(composeUrl(token, PATHS.FREE_INCIDENT_DESCRIPTION))
    }

    return (
        <>
            <PageContainerCmp
                nextStepHandler={goToNextStepHandler}
                routePath={PATHS.BACH_BAREME_DYNAMIC}
            >
                <>
                    <Section>
                        <PageTitle>Descrizione della dinamica</PageTitle>
                        <Vspace></Vspace>
                        <TextDefault centered>I dati che vedi sono rilevati dalla tua box installata e indicano come probabile la seguente dinamica di incidente.</TextDefault>
                    </Section>

                    <SquaredCard>
                        <TextDefaultItalic centered>{communication.bareme_desc}</TextDefaultItalic>
                        <Vspace></Vspace>

                        <BaremeImgCmp src={`${window.REACT_APP_VTV_API_ENDPOINT}/api/realtime/communication/${token}/bareme-batch-image`} />

                        <Vspace></Vspace>
                        <CenteredContainer>
                            <LegendItemContainer>
                                <LegendItemIndicator></LegendItemIndicator>
                                <LegendItemLabel>Tu</LegendItemLabel>
                            </LegendItemContainer>
                            <LegendItemContainer>
                                <LegendItemIndicator ctp></LegendItemIndicator>
                                <LegendItemLabel>Controparte</LegendItemLabel>
                            </LegendItemContainer>
                        </CenteredContainer>
                    </SquaredCard>

                    <Section>
                        <AdditionalCard>
                            <AdditionalCardText><strong>Non è corretto?</strong></AdditionalCardText>
                            <AdditionalCardText link
                                onClick={describeIncidentHandler}>Puoi descrivere tu la dinamica</AdditionalCardText>
                            <AdditionalCardText><strong>integrando con opportuni dettagli.</strong></AdditionalCardText>
                        </AdditionalCard>
                    </Section>
                </>
            </PageContainerCmp>
        </>
    )
}

export default BachBaremeDynamicPage