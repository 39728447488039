import styled from 'styled-components'
import { CaiButton } from '../Button.style'
import { CenteredContainer } from '../Common.style'

export const CaiWizardPointBadge = styled(CenteredContainer)`
    position: relative;
    font-family: "bold";
    background-color: #e4eef8;
    color: #3079c9;
    font-size: 12px;
    text-align: center;
    padding: 0;
    height: 22px;
    padding: 0 0.8rem;
    margin-top: 0.4rem;

    &::after,
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -11px;
        border-top: 11px solid transparent;
        border-bottom: 11px solid transparent;
        border-left: 11px solid rgb(228, 238, 248);
    }

    &::before {
        right: unset;
        left: -10px;
        border-right: 10px solid rgb(228, 238, 248);
        border-left: unset;
    }
`

export const CaiContactInfoForm = styled.form`
    width: 100%;
`

export const ModalContactActionBtn = styled(CaiButton)`
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    svg {
        margin-right: 7px;
        margin-bottom: 4px;
    }
`