import { faFileSignature, faPhone, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode, useMemo } from "react";
import { InfoCard, PlainInfoCard } from "../../components/styled/Card.style";
import { Section } from "../../components/styled/Containers.style";
import { CardIconContainer, IconContainer } from "../../components/styled/Icon.style";
import { Vspace } from "../../components/styled/Separators.style";
import { BigText, TextDefault, TextLightB } from "../../components/styled/Text.style";
import { PATHS } from "../../routes/CaiExperienceRouter";
import { getCurrentRoute } from "../../utils/utils";
import FooterCmp from "../footer/Footer";

/* istanbul ignore next */
interface EndProcessSuccessCmpProps {
    title?: string;
    mainSentence?: string | ReactNode,
    agencyPhone?: string,
    routePath?: string
}

/* istanbul ignore next */
const EndProcessSuccessCmp = (props: EndProcessSuccessCmpProps) => {
    let { title, mainSentence, agencyPhone, routePath } = props
    title = title || 'Abbiamo avviato la procedura di firma.'
    mainSentence = mainSentence || <TextDefault intM centered={true}>Riceverai un messaggio per firmare digitalmente la tua Constatazione Amichevole entro 24 ore.</TextDefault>
    const route = useMemo(() => routePath ? getCurrentRoute(routePath) : null, [routePath])

    return (
        <>
            <Section>
                <PlainInfoCard>
                    <IconContainer>
                        <FontAwesomeIcon icon={routePath === PATHS.END_PROCESS_CAI_PAPER_PAGE ? faThumbsUp : faFileSignature} />
                        <Vspace></Vspace>
                        <Vspace></Vspace>
                    </IconContainer>
                    <BigText>{title}</BigText>
                </PlainInfoCard>

                <Vspace qty={2}></Vspace>
                {mainSentence}
                <Vspace qty={2}></Vspace>


                {routePath === PATHS.END_PROCESS_CAI_PAPER_PAGE && <Section>
                    <InfoCard>
                        <CardIconContainer>
                            <FontAwesomeIcon icon={faPhone} />
                        </CardIconContainer>
                        <TextLightB>
                            Verrai ricontattato dalla tua agenzia nel minor tempo possibile. Puoi velocizzare le prossime fasi della denuncia rivolgendoti alla tua agenzia al numero <strong>{agencyPhone}</strong>.
                        </TextLightB>
                    </InfoCard>
                </Section>}
                <Vspace></Vspace>

                {route?.hasFooter && <FooterCmp hasDownloadButtons={route?.hasFooterDownloadButtons} />}
            </Section>
        </>
    )
}

export default EndProcessSuccessCmp