import styled from 'styled-components'

export const LoaderContainer = styled.div`
    z-index: 1;

    &.cover {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
    }
    &.cai-preview {
        z-index: 100;
        background-color: rgba(255,255,255,0.7);
    }
`

export const LoaderItem = styled.div`
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-left-color: #294862;
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    animation: loader-spin 1.2s infinite linear;

    @keyframes loader-spin {
        0% {
            transform: translate(-50%, -50%) rotate(0deg);
        }
        
        100% {
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }
`

