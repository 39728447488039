import { HListContainer } from '../styled/Containers.style'
import CaiPreviewItemCmp from './CaiPreviewItemCmp'

interface CaiPreviewsCmpProps {
    previewUrlPage_1: string,
    previewUrlPage_2: string,
    isCaiSecondPageVisible: boolean,
}

const CaiPreviewsCmp = (props: CaiPreviewsCmpProps) => {
    const { previewUrlPage_1, previewUrlPage_2, isCaiSecondPageVisible } = props

    return (
        <HListContainer>
            <CaiPreviewItemCmp
                imgSrc={previewUrlPage_1} />

            {isCaiSecondPageVisible && <CaiPreviewItemCmp
                imgSrc={previewUrlPage_2} />}
        </HListContainer>
    )
}

export default CaiPreviewsCmp