import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { CenteredContainer, devices } from './Common.style'

export const CardIconContainer = styled(CenteredContainer)`
    position: absolute;
    top: 0;
    right: 2rem;
    width: 48px;
    height: 48px;
    transform: translateY(-50%) rotate(45deg);
    border: 1px solid #3079c9;
    border-radius: 50%;
    background-color: #e4eef8;
    border-bottom-color: transparent;
    border-right-color: transparent;

    svg {
        color: #3079c9;
        font-size: 24px;
        transform: rotate(-45deg);
    }
`


export const IconContainer = styled(CenteredContainer)`
    svg {
        width: 48px;
        height: 48px;
        color: #3079c9;
    }

    /*display: none;
    @media ${devices.mobileL} {
        display: block;
    }*/
    display: block;
`

export const InfoIcon = styled(FontAwesomeIcon)`
    color: #3079c9;
`

