
import { useNavigate, useParams } from "react-router-dom";
import { Section } from "../components/styled/Containers.style";
import { selectCommunicationData } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { PageTitle, TextDefault } from "../components/styled/Text.style";
import { Vspace } from '../components/styled/Separators.style'
import { composeUrl } from "../utils/utils";
import { useState } from "react";
import { PATHS } from "../routes/CaiExperienceRouter";
import { saveCommunicationAct } from "../store/communication-data/actions";
import { CommunicationType } from "../types/communication/communicationDataType";
import PageContainerCmp from "./PageContainerCmp";
import InputCmp from "../components/input/InputCmp";
import { getUtcTimeFromCurrentTimeAndHour, localTimeHour } from "../utils/time.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { MapAddressFinder } from "../components/map/MapAddressFinder";

  /* istanbul ignore next */
const OraELuogoPage = () => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let { token } = useParams();

    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData

    const [inc_ora, setIncOra] = useState<string>(localTimeHour(communication.inc_utc_time))
    const [inc_luogo, setIncLuogo] = useState<string>(communication.inc_luogo)

    const goToNextStepHandler = () => {
        let _pathname = composeUrl(token, PATHS.BACH_BAREME_DYNAMIC)
        if (communication.disable_step_10 === true) {
            _pathname = composeUrl(token, PATHS.Q1)
        }
        // recompute utc time with respect to inc_data and inc_ora
        const computedUtcTime = getUtcTimeFromCurrentTimeAndHour(communication.inc_utc_time, inc_ora)
        /* istanbul ignore next */
        dispatch(saveCommunicationAct({
            data: new CommunicationType({
                ...communication,
                inc_utc_time: computedUtcTime,
                inc_ora,
                inc_luogo
            }),
            callback: () => navigate(_pathname)
        })
        )
    }

    const editPlaceHandler = () => {
        const computedUtcTime = getUtcTimeFromCurrentTimeAndHour(communication.inc_utc_time, inc_ora)
        /* istanbul ignore next */
        dispatch(saveCommunicationAct({
            data: new CommunicationType({
                ...communication,
                inc_utc_time: computedUtcTime,
                inc_ora
            }),
            callback: () => navigate(composeUrl(token, PATHS.MODIFICA_LUOGO_INCIDENTE))
        })
        )
    }

    const displayOnlyMap = <MapAddressFinder
        fixedMarkerDropMap={true}
        withSearch={false}
        maxDistance={1500}
        hasProximityWarning={true}
        displayOnly={true}
        classes={"modify-position-map input-el"}
        manageBtnPresent={false}
    />

    return (
        <>
            <PageContainerCmp
                nextStepHandler={goToNextStepHandler}
                routePath={PATHS.ORA_E_LUOGO}
            >
                <Section>
                    <PageTitle>Ora e luogo
                    </PageTitle>
                    <Vspace></Vspace>
                    <TextDefault centered>L’urto risulta rilevato il <strong>{communication.inc_data}</strong> all'ora e nel luogo riportati di seguito.</TextDefault>
                    <Vspace></Vspace>
                    <TextDefault centered>Se non ti sembrano corretti puoi modificarli: eventuali inesattezze sono dovute a scarsa copertura del GPS.</TextDefault>
                    <Vspace></Vspace>
                </Section>

                <Section>
                    <InputCmp
                        value={inc_ora}
                        setValue={(val: string) => setIncOra(val)}
                        name={"ora_incidente"}
                        className={"left-align"}
                        inputIcon={<FontAwesomeIcon icon={faClock} />} />
                </Section>


                {(!communication.inc_latitude || !communication.inc_longitude) ? <InputCmp
                    value={inc_luogo}
                    setValue={setIncLuogo}
                    name={'address'}
                    label={`Inserisci l'indirizzo in cui è avvenuto il sinistro`}
                    labelClassName="marked"
                    className={`contact-info small addressFinder`}
                /> : <Section onClick={editPlaceHandler}>
                    <InputCmp
                        value={inc_luogo}
                        setValue={setIncLuogo}
                        name={"luogo_incidente"}
                        className={"left-align small ellipsed"}
                        inputIcon={<FontAwesomeIcon icon={faMapMarkerAlt} />}
                        additionalElement={<>
                            {displayOnlyMap}
                        </>} />
                </Section>}

            </PageContainerCmp>
        </>

    )
}

export default OraELuogoPage