import { action } from 'typesafe-actions'
import { CommunicationDatatActionTypes } from '../../types/actions/communication-data-action'
import { DispatchWithCallbackType } from '../../types/commonTypes'
import { CaiValidationInfoType, CommunicationType, EventCommType } from '../../types/communication/communicationDataType'

export const executePollingAct = (params?: any) => action(CommunicationDatatActionTypes.EXECUTE_POLLING, params)
export const loadCommunicationAct = (params?: any) => action(CommunicationDatatActionTypes.LOAD_COMMUNICATION, params)
export const loadCommunicationSuccessAct = (resp?: any) => action(CommunicationDatatActionTypes.LOAD_COMMUNICATION_SUCCESS, resp)
export const loadCommunicationErrorAct = (err?: string) => action(CommunicationDatatActionTypes.LOAD_COMMUNICATION_ERROR, err)

export const loadAniaAct = (params?: any) => action(CommunicationDatatActionTypes.LOAD_ANIA, params)
export const loadAniaSuccessAct = (resp: any) => action(CommunicationDatatActionTypes.LOAD_ANIA_SUCCESS, resp)
export const loadAniaErrorAct = (err?: string) => action(CommunicationDatatActionTypes.LOAD_ANIA_ERROR, err)

export const saveCommunicationAct = (communication?: DispatchWithCallbackType<CommunicationType, EventCommType[]>) => action(CommunicationDatatActionTypes.SAVE_COMMUNICATION, communication)
export const saveCommunicationInBackgroundAct = (communication?: DispatchWithCallbackType<CommunicationType, EventCommType[]>) => action(CommunicationDatatActionTypes.SAVE_COMMUNICATION_IN_BACKGROUND, communication)
export const saveCommunicationSuccessAct = (resp: any) => action(CommunicationDatatActionTypes.SAVE_COMMUNICATION_SUCCESS, resp)
export const saveCommunicationEndAct = () => action(CommunicationDatatActionTypes.SAVE_COMMUNICATION_END)
export const saveCommunicationErrorAct = (err?: string) => action(CommunicationDatatActionTypes.SAVE_COMMUNICATION_ERROR, err)

export const invokeSignatureAct = (params?: DispatchWithCallbackType<null, null>) => action(CommunicationDatatActionTypes.INVOKE_SIGNATURE, params)
export const invokeSignatureSuccessAct = (resp: any) => action(CommunicationDatatActionTypes.INVOKE_SIGNATURE_SUCCESS, resp)
export const invokeSignatureEndAct = () => action(CommunicationDatatActionTypes.INVOKE_SIGNATURE_END)

export const caiValidationUpdateAct = (validationObj?: Partial<CaiValidationInfoType>) => action(CommunicationDatatActionTypes.CAI_VALIDATION_UPDATE, validationObj)
export const captchaChallengeAct = (value: any) => action(CommunicationDatatActionTypes.CAPTCHA_CHALLENGE, value)
