import { useEffect, useState } from 'react'
import LoaderCmp from '../loader/LoaderCmp'
import { BaremeImg } from '../styled/Bareme.style'

interface BaremeImgCmpProps {
    src: string
}

const BaremeImgCmp = (props: BaremeImgCmpProps) => {
    const { src } = props
    const [imgIsLoading, setImgIsLoading] = useState(false);

    useEffect(() => {
        setImgIsLoading(true);
    }, [])

    const handleImageLoaded = () => {
        setImgIsLoading(false);
    }

    const handleImageErrored = () => {
        setImgIsLoading(false);
    }
    return (
        <>
            {imgIsLoading && <LoaderCmp />}
            <BaremeImg
                className={imgIsLoading ? 'is-loading' : 'is-loaded'}
                onLoad={handleImageLoaded}
                onError={handleImageErrored}
                src={src}
                transparent />
        </>
    )
}

export default BaremeImgCmp