import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";
import { Section } from "../components/styled/Containers.style";
import { selectCommunicationData } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { PageTitle, TextLightB } from "../components/styled/Text.style";
import { List, ListItem } from "../components/styled/List.style";
import { InfoCard } from "../components/styled/Card.style";
import { CardIconContainer } from "../components/styled/Icon.style";
import { composeUrl } from "../utils/utils";
import { IncidentBanner } from "../components/styled/page-custom/CosaTiChiediamoPage.style";
import { PATHS } from "../routes/CaiExperienceRouter";
import PageContainerCmp from "./PageContainerCmp";
import { CommunicationType, EventCommType, EventValueEnum } from "../types/communication/communicationDataType";
import { saveCommunicationAct } from "../store/communication-data/actions";
import { Vspace } from "../components/styled/Separators.style";

const CosaTiChiediamoPage = () => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let { token } = useParams();
    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData

    const goToNextStepHandler = () => {
        const newEvent = new EventCommType({ value: EventValueEnum.CAI_FILLING_START })
        const updCommunication = new CommunicationType(communication)
        dispatch(saveCommunicationAct({
            data: updCommunication,
            events: [newEvent],
            callback: () => {
                navigate(composeUrl(token, PATHS.VEICOLI_E_PASSEGGERI))
            }
        })
        )
    }

    return (
        <>
            <PageContainerCmp
                nextStepHandler={goToNextStepHandler}
                routePath={PATHS.COSA_TI_CHIEDIAMO}
                nextTitle="Ho capito"
            >
                <IncidentBanner>
                </IncidentBanner>

                <Section>
                    <PageTitle>Cosa ti chiediamo</PageTitle>
                    <List>
                        <ListItem>I dati dell'incidente</ListItem>
                        <ListItem>Le foto ai danni del tuo veicolo e le foto al luogo dell'incidente, strade e segnaletica</ListItem>
                    </List>
                </Section>

                <Vspace></Vspace>

                <Section>
                    <InfoCard>
                        <CardIconContainer>
                            <FontAwesomeIcon icon={faUserFriends} />
                        </CardIconContainer>
                        <TextLightB>
                            Non preoccuparti <strong>se non trovi un accordo</strong> con la controparte: potrai comunque denunciare il sinistro grazie alla CAI digitale.

                        </TextLightB>
                    </InfoCard>
                </Section>

            </PageContainerCmp>
        </>

    )
}

export default CosaTiChiediamoPage