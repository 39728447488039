import { Input, InputCmpContainer, InputContainer, InputErrorMessage, InputIcon, Label } from "../styled/Input.style";

interface InputCmpProps {
    label?: string;
    value: string;
    name: any;
    setValue: Function;
    type?: string;
    className?: string;
    containerClassName?: string;
    inputIcon?: any;
    additionalElement?: any;
    labelClassName?: string;
    register?: any,
    errors?: any
}

const InputCmp = (props: InputCmpProps) => {
    const { value, type, setValue, name, label, className, inputIcon,
        additionalElement, labelClassName, containerClassName
        , register, errors
    } = props

    const registerEl = register && register(name)

    return (
        <InputContainer className={containerClassName}>
            {label && <Label className={`${labelClassName} first-capitalize`}>{label}</Label>}
            <InputCmpContainer>
                {inputIcon && <InputIcon>{inputIcon}</InputIcon>}

                <Input
                    {...(registerEl || {})}
                    onChange={(e) => {
                        registerEl && registerEl.onChange(e); // method from hook form register
                        setValue(e.target.value); // your method
                    }}
                    type={type || 'text'}
                    className={`${className} ${errors && errors[name] ? 'error' : ''}`}
                    value={value}
                />
            </InputCmpContainer>
            {<InputErrorMessage>{errors && errors[name]?.message?.toString()}</InputErrorMessage>}
            {additionalElement}
        </InputContainer>
    )
}

export default InputCmp