import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { selectCommunicationData } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { composeUrl } from "../utils/utils";
import { PATHS } from "../routes/CaiExperienceRouter";
import { saveCommunicationAct } from "../store/communication-data/actions";
import { BaremeUserChoiseEnum, CommunicationType } from "../types/communication/communicationDataType";
import PageContainerCmp from "./PageContainerCmp";
import { Section } from "../components/styled/Containers.style";
import { PageTitle, TextDefault } from "../components/styled/Text.style";
import { Vspace } from "../components/styled/Separators.style";
import { TextArea } from "../components/styled/TextArea.style";

const BaremeFreeDescriptionPage = () => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let { token } = useParams();

    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData

    const [freeDescription, setFreeDescription] = useState<string>(communication.baremeFreeDescription)

    const goToNextStepHandler = () => {
        dispatch(saveCommunicationAct({
            data: new CommunicationType({
                ...communication,
                baremeFreeDescription: freeDescription,
                baremeUserChoise: BaremeUserChoiseEnum.BAREME_FREE_DESCRIPTION
            }),
            callback: () => navigate(composeUrl(token, PATHS.FREE_SELEZIONA_CIRCOSTANZA))
        })
        )
    }

    return (
        <>
            <PageContainerCmp
                nextStepHandler={goToNextStepHandler}
                routePath={PATHS.FREE_INCIDENT_DESCRIPTION}
            >
                <>
                    <Section>
                        <PageTitle>Descrivi la dinamica dell'evento</PageTitle>
                        <Vspace></Vspace>
                        <TextDefault centered>Come è avvenuto l'incidente? Da quale parte proveniva la controparte? In quali parti dell'auto sono presenti gli urti? (*)</TextDefault>
                    </Section>

                    <Section>
                        <TextArea 
                        maxLength={150}
                        placeholder="Descrivi la dinamica..."
                        value={freeDescription}
                        onChange={(e) => setFreeDescription(e.target.value)} />
                    </Section>
                </>
            </PageContainerCmp>
        </>
    )
}

export default BaremeFreeDescriptionPage