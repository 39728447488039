import moment from 'moment';

let getUtcOffset = (date: any, offset: number) => {
    return moment(date)
        .add(
            offset,
            'minutes')
        .utc()
}

export const localDate = (timeStr: string, format?: string | null, additionalHours?: number) => {
    let localTimeOutput = timeStr;
    let _format = format || "DD/MM/YYYY"

    try {
        if (timeStr) {
            let utcoffset = moment().utcOffset();
            let localTime = getUtcOffset(timeStr, utcoffset);
            if (localTime.isValid()) {
                localTimeOutput = localTime.format(_format);
                localTimeOutput = localTime.add(additionalHours || 0, 'hours').format(_format);
            }
        }
    } catch (err) {
        return localTimeOutput;
    }

    return localTimeOutput;
}

export const getUtcTimeFromCurrentTimeAndHour = (utcTimeStr: string, inc_ora: string) => {

    const inc_ora_arr = inc_ora && inc_ora.split(':');
    if (!inc_ora_arr || !(inc_ora_arr.length > 1)) {
        return utcTimeStr
    }
    const hh = +inc_ora_arr[0]
    const mm = +inc_ora_arr[1]
    if (isNaN(hh) || isNaN(mm)) {
        return utcTimeStr
    }
    
    const retTime = new Date(utcTimeStr)
    retTime.setHours(hh, mm, 0)
    return retTime.toISOString()
}

export const localTimeHour = (timeStr: string) => {
    return localDate(timeStr, 'HH:mm')
}

export const caiExpireLocalDate = (textIn: string) => {
    const additionalHours = +((window.REACT_APP_CAI_COMMUNICATION_EXPIRATION_IN) || 48);
    return localDate(textIn, 'DD/MM/YYYY', additionalHours);
}

export const caiSignatureFeedbackExpireDate = (textIn: string) => {
    const additionalHours = +((window.REACT_APP_CAI_SIGNATURE_EXPIRATION_IN) || 48);
    return localDate(textIn, 'DD/MM/YYYY', additionalHours);
}

