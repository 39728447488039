import { useNavigate, useParams } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { ServerResponseType } from "../../utils/api";
import { Section } from "../../components/styled/Containers.style";
import { selectCommunicationData } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { PageTitle, TextDefault, TextDefaultSmall } from "../../components/styled/Text.style";
import { composeUrl, isPlateAlphanumericOnly, isPlateLengthCorrect, tqError } from "../../utils/utils";
import { IncidentBanner } from "../../components/styled/page-custom/CosaTiChiediamoPage.style";
import PageContainerCmp from "../PageContainerCmp";
import { CommunicationType, EventCommType, EventValueEnum, UserRoleEnum } from "../../types/communication/communicationDataType";
import { saveCommunicationAct } from "../../store/communication-data/actions";
import { Vspace } from "../../components/styled/Separators.style";
import InputCmp from "../../components/input/InputCmp";
import { useEffect, useMemo, useState } from "react";
import { CloseButton, NextButton, SecondChoiseButton } from "../../components/styled/Button.style";
import { AUTH_CONFIRM_BASE_ROUTH, AUTH_CONFIRM_PATHS } from "../../routes/AuthConfirmRouter";
import { ValidationContainer, ValidationItem } from "../../components/styled/Validation.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import AuthConfirmCancelProcedureModal from "../../components/cancel-authorize-confirm-claim-modal/AuthConfirmCancelProcedureModal";
import { localDate, localTimeHour } from "../../utils/time.utils";

/* istanbul ignore next */
const AuthConfirmClaimPage = () => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let { token } = useParams();
    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData
    const [veic_targa, setVeicTarga] = useState<string>('')
    const [formErrors, setFormErrors] = useState<any>()
    const [plateValidationError, setPlateValidationError] = useState<boolean>(false)
    const [plateValidationLoading, setPlateValidationLoading] = useState<boolean>(false)
    const [cancelModalOpened, setCancelModalOpened] = useState<boolean>(false)
    const [showValidationError, setShowValidationError] = useState<boolean>(true)

    const tomorrowDate = communication?.creation_date && new Date(new Date(communication.creation_date).getTime() + 60 * 60 * 24 * 1000);
    const timeHour = tomorrowDate && localTimeHour(tomorrowDate.toISOString())
    const date = tomorrowDate && localDate(tomorrowDate.toISOString())
    
    useEffect(() => {
        setPlateValidationError(false);
        /* istanbul ignore next */
        if (veic_targa) {
            if (!isPlateAlphanumericOnly(veic_targa))
                return setFormErrors({ targa_conferma_veicolo: { message: 'La targa deve contenere solo caratteri alfanumerici.' } })

            if (!isPlateLengthCorrect(veic_targa))
                return setFormErrors({ targa_conferma_veicolo: { message: 'La targa deve essere lunga almeno 6 cifre.' } })
        }
        setFormErrors(null)
    }, [veic_targa])

    useEffect(() => {
        const newEvent = new EventCommType({ value: EventValueEnum.FIRST_ACCESS });
        dispatch(saveCommunicationAct({
            events: [newEvent],
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const targaValid = useMemo(() => {
        /* istanbul ignore next */
        return !!veic_targa && veic_targa.length >= 5 && veic_targa.length <= 8
    }, [veic_targa])

    const shouldDisableButton = () => {
        const nextStepDisabled = !targaValid || formErrors || plateValidationLoading;
        return nextStepDisabled;
    }

    const goToNextStepHandler = () => {
        /* istanbul ignore next */
        const newEvent = new EventCommType({ value: EventValueEnum.AUTH_CONFIRM_CHECK_CTP_CORRECT_PLATE })
        const updCommunication = new CommunicationType(communication)
        updCommunication.auth_confirm_input_veic_targa = veic_targa
        const nextStepRouteName = updCommunication.userRole === UserRoleEnum.USER_1 ?
            AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_CTP_NUMBER :
            AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_CAI_PREVIEW;
        /* istanbul ignore next */
        dispatch(saveCommunicationAct({
            data: updCommunication,
            events: [newEvent],
            callback: () => {
                navigate(composeUrl(token, nextStepRouteName, AUTH_CONFIRM_BASE_ROUTH))
            }
        })
        )
    }

    const validatePlate = () => {
        setPlateValidationLoading(true)
        setPlateValidationError(false)
        setShowValidationError(true)
        let requestURL = `${window.REACT_APP_VTV_API_ENDPOINT}/api/realtime/communication/${token}/auth-confirm-validation`;
        axios.post(requestURL, { userInput_veic_targa: veic_targa })
            .then((res: AxiosResponse<ServerResponseType<any>>) => {
                setPlateValidationError(false)
                // go to next step
                goToNextStepHandler()
            })
            .catch((err: AxiosResponse<ServerResponseType<any>>) => {
                setPlateValidationError(true)
                tqError({
                    errors_elements: [window?.location?.href],
                    errors_id: ['cai:errore in fase di inserimento targa incidente controparte'],
                    errors_messages: ['Errore in fase di inserimento targa nel flusso di riconoscimento controparte'],
                    errors_type: ['user']
                })
            })
            .finally(() => setPlateValidationLoading(false));
    }

    return (
        <>
            <AuthConfirmCancelProcedureModal
                modalOpened={cancelModalOpened}
                onCloseHandler={() => setCancelModalOpened(false)} />
            <PageContainerCmp
                nextStepHandler={goToNextStepHandler}
                routePath={AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_CLAIM}
                nextTitle="Avanti"
                removeNextBtn
            >
                <IncidentBanner>
                </IncidentBanner>

                <Section>
                    <PageTitle>Incidente rilevato</PageTitle>
                    <Vspace></Vspace>
                    <TextDefault centered>{`Per confermare che sei la persona coinvolta nel sinistro ti chiediamo di inserire la tua targa, entro le ore ${timeHour} del giorno ${date}.`}</TextDefault>
                    <Vspace></Vspace>
                    <TextDefaultSmall>I campi contrassegnati da * sono obbligatori.</TextDefaultSmall>
                </Section>

                <Vspace></Vspace>

                <Section>
                    <InputCmp
                        value={veic_targa}
                        setValue={(val: string) => setVeicTarga(val.toUpperCase())}
                        label={"La tua targa (*)"}
                        labelClassName="marked"
                        name={"targa_conferma_veicolo"}
                        errors={formErrors}
                        className={"big-and-spaced"} />
                </Section>

                {plateValidationError && showValidationError &&
                    <>
                        <Section>
                            <ValidationContainer>
                                <CloseButton onClick={() => setShowValidationError(false)}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </CloseButton>
                                <ValidationItem>
                                    Non ci risulta che tu sia coinvolto in questo processo. Inserisci la targa corretta per proseguire.
                                </ValidationItem>
                            </ValidationContainer>
                        </Section>
                    </>
                }
                <Vspace qty={2}></Vspace>
                <Section>
                    <NextButton disabled={shouldDisableButton()} onClick={() => validatePlate()}>PROCEDI</NextButton>
                    <Vspace></Vspace>
                    <SecondChoiseButton onClick={() => setCancelModalOpened(true)}>Annulla</SecondChoiseButton>
                </Section>

            </PageContainerCmp>
        </>

    )
}

export default AuthConfirmClaimPage