import '../App.scss';
import { ConfirmAuthBreadcrumbTitles, RouteType } from "../types/commonTypes";
import AuthConfirmClaimPage from "../pages/auth-confirm/AuthConfirmClaimPage";
import AuthConfirmEndCancelPage from "../pages/auth-confirm/AuthConfirmEndCancelPage";
import AuthConfirmCaiPreviewPage from '../pages/auth-confirm/AuthConfirmCaiPreviewPage';
import AuthConfirmUploadDocPage from '../pages/auth-confirm/AuthConfirmUploadDocPage';
import AuthConfirmEndProcessSuccessPage from '../pages/auth-confirm/AuthConfirmEndProcessSuccessPage';
import AuthConfirmEndTokenExpiredPage from '../pages/auth-confirm/AuthConfirmEndTokenExpiredPage';
import AuthConfirmCtpNumberPage from '../pages/auth-confirm/AuthConfirmCtpNumberPage';

export const AUTH_CONFIRM_BASE_ROUTH = 'auth-confirm'

export const AUTH_CONFIRM_PATHS = {
  AUTHORIZE_CONFIRM_CLAIM: "authorize-confirm-claim",
  AUTHORIZE_CONFIRM_CTP_NUMBER: "authorize-confirm-ctp-number",
  AUTHORIZE_CONFIRM_END_CANCEL: "authorize-confirm-end_cancel",
  AUTHORIZE_CONFIRM_CAI_PREVIEW: "authorize-confirm-cai-preview",
  AUTHORIZE_CONFIRM_UPLOAD_DOC: "authorize-confirm-upload-doc",
  AUTHORIZE_CONFIRM_END_PROCESS_SUCCESS: "authorize-confirm-end-process-success",
  AUTHORIZE_CONFIRM_TOKEN_EXPIRED: "authorize-confirm-token-expired"
}

export const AUTH_CONFIRM_ROUTES: RouteType[] = [{
  path: AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_CLAIM,
  element: <AuthConfirmClaimPage />,
  tqPageName: 'cai:incidente rilevato controparte'
},
{
  path: AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_END_CANCEL,
  element: <AuthConfirmEndCancelPage />,
  tqPageName: 'cai:procedura interrotta'
},{
  path: AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_CTP_NUMBER,
  element: <AuthConfirmCtpNumberPage />,
  hasBreadcrumb: false,
  breadcrumbIndex: 1,
  breadcrumbTitles: ConfirmAuthBreadcrumbTitles,
  defaultBackPath: AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_CLAIM,
  backBaseRoute: AUTH_CONFIRM_BASE_ROUTH
},
{
  path: AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_CAI_PREVIEW,
  element: <AuthConfirmCaiPreviewPage />,
  hasBreadcrumb: true,
  breadcrumbIndex: 1,
  breadcrumbTitles: ConfirmAuthBreadcrumbTitles,
  defaultBackPath: AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_CLAIM,
  backBaseRoute: AUTH_CONFIRM_BASE_ROUTH,
  tqPageName: 'cai:cai:constatazione amichevole controparte'
}, {
  path: AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_UPLOAD_DOC,
  element: <AuthConfirmUploadDocPage />,
  hasBreadcrumb: true,
  breadcrumbIndex: 2,
  breadcrumbTitles: ConfirmAuthBreadcrumbTitles,
  defaultBackPath: AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_CAI_PREVIEW,
  backBaseRoute: AUTH_CONFIRM_BASE_ROUTH,
  tqPageName: 'cai:cai:documento di riconoscimento'
}, {
  path: AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_END_PROCESS_SUCCESS,
  element: <AuthConfirmEndProcessSuccessPage />,
  tqPageName: 'cai:cai:conclusione ok'
}, {
  path: AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_TOKEN_EXPIRED,
  element: <AuthConfirmEndTokenExpiredPage />,
  tqPageName: 'cai:comunicazione scaduta'
}]

