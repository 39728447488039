import styled from 'styled-components'
import { CenteredContainer, devices } from './Common.style'

export const RouteWrapper = styled.div`
  position: relative;
  padding: 1rem;
  padding-top: calc(50px + 1rem);

  &.in-app {
    padding-top: 1rem;
  }
`

interface PageContainerProps {
  hasBreadcrumb?: boolean;
}

export const PageContainer = styled.div<PageContainerProps>`
  position: relative;
  width: 100%;
  min-height: calc(100% - 100px); /*min-height: 100vh;*/
  margin-bottom: 74px;
  ${(props: PageContainerProps) => props.hasBreadcrumb &&
    'margin-top: 50px;'
  }
  `
export interface SectionProps {
  hAlign?: string;
}
export const Section = styled(CenteredContainer) <SectionProps>`
  flex-direction: column;
  position: relative;
  width: 100%;
  margin: auto;
  
  ${(props: SectionProps) => props.hAlign &&
    'align-items: flex-start;'
  }

  padding: 0.5rem 0;
  max-width: calc(100% - 4px);
  @media ${devices.mobileL} {
    max-width: calc(100% - 18px);
  }
`

export const DivW = styled.div`
  width: 100%;
`

export const CaiSection = styled(Section)`
  flex-direction: column;
  align-items: flex-start;
`

export const IncrementalInputContainer = styled(CenteredContainer)`
  position: relative;
  gap: 1rem;

  button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    width: 34px;
    height: 34px;
    font-size: 20px;
    border: none;
    color: #fff;
    background-color: #3079c9;
    padding: 0;
    border-radius: 50%;
    cursor: pointer;
  }

  .val {
    font-size: 35px;
  }
`

export const ConfirmContainer = styled(CenteredContainer)`
  gap: 2rem;
  z-index: 9999;
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%)
`

export const HListContainer = styled(CenteredContainer)`
  gap: 3rem;
`

