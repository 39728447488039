import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { FileActionFunction, FileItemType } from '../../types/communication/communicationDataType';
import { useParams } from 'react-router-dom';
import { ButtonFileInput, FileCard, FileInput, FileInputs } from '../styled/File.style';
import { getBackendData, hasBackendResponseErrors } from '../../utils/api';

export interface FileUploadCmpProps {
    onFileAdded: FileActionFunction;
    onFileRemoved: FileActionFunction;
    showSupportedFilesInfo?: boolean;
    className?: string;
    fileType: string;
    textLabel?: string;
    files?: any[];
}
const FileUploadCmp = (props: FileUploadCmpProps) => {
    const { onFileAdded, onFileRemoved, showSupportedFilesInfo,
        className, fileType, textLabel, files } = props;
    let { token } = useParams();

    const uploadHandler = (event: any) => {
        const file = event.target.files[0];
        event.target.value = null;  //clear input cache
        if (!file) return;
        file.filename = file.name
        const _file = new FileItemType(file)
        _file.metadata.isLoading = true;
        onFileAdded(_file)
        // upload file
        const formData = new FormData();
        formData.append(
            "newFile",
            file,
            file.name
        )
        // formData.append("widget_id", this.props.id);
        formData.append("name", file.name);
        if (typeof file.type !== "undefined") {
            formData.append("file_type", fileType);
        }
        let requestURL = `${window.REACT_APP_VTV_API_ENDPOINT}/api/realtime/communication/${token}/upload-attachment`;
        axios.post(requestURL, formData)
            .then((res) => {
                if (hasBackendResponseErrors(res)) {
                    throw new Error(`Error uploading file ${file.name}`)
                } else {
                    const upF =  getBackendData(res);
                    const uploadedFile = new FileItemType(upF)
                    uploadedFile.metadata.isLoading = false;
                    onFileAdded(uploadedFile)
                }
            })
            .catch((err) => {
                // inform the user
                console.error(err)
                onFileRemoved(_file)
            });
    }

    const customLabel = (filesLength?: number) => {
        if (filesLength && filesLength !== 0) return "";
        return textLabel;
    }

    return (
        <>
            <FileCard className={className}>

                <FileInputs>
                    <FileInput type="file" onChange={uploadHandler} />
                    <ButtonFileInput>
                        <i>
                            <FontAwesomeIcon icon={faCamera} />
                        </i>
                        {customLabel(files?.length)}
                    </ButtonFileInput>
                </FileInputs>

                {showSupportedFilesInfo && <>
                    <p className="main">Supported files</p>
                    <p className="info">PDF, JPG, PNG</p>
                </>}

            </FileCard>
        </>
    )
}

export default FileUploadCmp
