import { useEffect } from "react";
import { SplashScreen } from "../components/styled/Splash.styles";
import { tqPageView } from "../utils/utils";

interface SplashScreenPageProps {
  className?: string
}
export default function SplashScreenPage({className}: SplashScreenPageProps) {

  useEffect(() => {
    // Analytics
    tqPageView('cai:supporto immediato:step0')
  }, [])
  return (
    <>
      <SplashScreen className={className}></SplashScreen>
    </>
  );
}
