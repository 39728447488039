import { useNavigate, useParams } from "react-router-dom";
import { Section } from "../../components/styled/Containers.style";
import { selectCommunicationData } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { PageTitle, TextDefault, TextDefaultSmall } from "../../components/styled/Text.style";
import { composeUrl, isPhoneNumberFormat, isPhoneNumberLengthCorrect } from "../../utils/utils";
import PageContainerCmp from "../PageContainerCmp";
import { CommunicationType } from "../../types/communication/communicationDataType";
import { saveCommunicationAct } from "../../store/communication-data/actions";
import { Vspace } from "../../components/styled/Separators.style";
import InputCmp from "../../components/input/InputCmp";
import { useEffect, useMemo, useState } from "react";
import { NextButton, SecondChoiseButton } from "../../components/styled/Button.style";
import { AUTH_CONFIRM_BASE_ROUTH, AUTH_CONFIRM_PATHS } from "../../routes/AuthConfirmRouter";
import AuthConfirmCancelProcedureModal from "../../components/cancel-authorize-confirm-claim-modal/AuthConfirmCancelProcedureModal";
import { IncidentBanner } from "../../components/styled/page-custom/CosaTiChiediamoPage.style";

    /* istanbul ignore next */
const AuthConfirmCtpNumberPage = () => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let { token } = useParams();
    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData
    const [phoneCtp, setPhoneCtp] = useState<string>(communication?.phoneCtp)
    const [formErrors, setFormErrors] = useState<any>()
    const [cancelModalOpened, setCancelModalOpened] = useState<boolean>(false)

    useEffect(() => {
        if (phoneCtp) {
            if (!isPhoneNumberFormat(phoneCtp))
                return setFormErrors({ ctp_phonenumber_conferma_veicolo: { message: 'Il formato del numero di telefono non risulta corretto.' } })

            if (!isPhoneNumberLengthCorrect(phoneCtp))
                return setFormErrors({ ctp_phonenumber_conferma_veicolo: { message: 'Il numero deve essere lungo da 6 a 16 caratteri.' } })
        }
        setFormErrors(null)
    }, [phoneCtp])


    const phoneCtpValid = useMemo(() => {
        return phoneCtp?.length >= 5 && phoneCtp?.length <= 16
    }, [phoneCtp])

    const shouldDisableButton = () => {
        const nextStepDisabled = !phoneCtpValid || formErrors;
        return nextStepDisabled;
    }

    const goToNextStepHandler = () => {
        const updCommunication = new CommunicationType(communication)
        updCommunication.phoneCtp = phoneCtp
        dispatch(saveCommunicationAct({
            data: updCommunication,
            callback: () => {
                navigate(composeUrl(token, AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_CAI_PREVIEW, AUTH_CONFIRM_BASE_ROUTH))
            }
        })
        )
    }

    return (
        <>
            <AuthConfirmCancelProcedureModal
                modalOpened={cancelModalOpened}
                onCloseHandler={() => setCancelModalOpened(false)} />
            <PageContainerCmp
                nextStepHandler={goToNextStepHandler}
                routePath={AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_CTP_NUMBER}
                nextTitle="Avanti"
                removeNextBtn
            >

                <IncidentBanner>
                </IncidentBanner>

                <Section>
                    <PageTitle>Dati di controparte</PageTitle>
                    <Vspace></Vspace>
                    <TextDefault centered>{`Ti chiediamo di inserire o confermare il numero di cellulare della controparte per coinvolgerla nel processo di firma della Constatazione Amichevole digitale.`}</TextDefault>
                    <Vspace></Vspace>
                    <TextDefaultSmall>I campi contrassegnati da * sono obbligatori.</TextDefaultSmall>
                </Section>

                <Vspace></Vspace>

                <Section>
                    <InputCmp
                        value={phoneCtp}
                        setValue={(val: string) => setPhoneCtp(val.toUpperCase())}
                        label={"Numero di telefono della controparte (*)"}
                        labelClassName="marked"
                        name={"ctp_phonenumber_conferma_veicolo"}
                        errors={formErrors}
                        className={"big-and-spaced"} />
                </Section>

                <Vspace qty={2}></Vspace>
                <Section>
                    <NextButton 
                    disabled={shouldDisableButton()}
                    onClick={goToNextStepHandler}>PROCEDI</NextButton>
                    <Vspace></Vspace>
                    <SecondChoiseButton onClick={() => setCancelModalOpened(true)}>Annulla</SecondChoiseButton>
                </Section>

            </PageContainerCmp>
        </>

    )
}

export default AuthConfirmCtpNumberPage