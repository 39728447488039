import styled from 'styled-components'

interface TextProps {
    centered?: any;
    sm?: any;
    xsm?: any;
    intM?: any;
    intL?: any;
}

export const TextDefault = styled.p<TextProps> `
    position: relative;
    font-family: "Book";
    font-size: ${(props: TextProps) => props.sm ? "16px" :
        (props: TextProps) => props.xsm ? "14px" : "17px"};
    color: #003b5c;
    line-height: ${(props: TextProps) => props.intL ? "28px" : 
    (props: TextProps) => props.intM ? "25px" : "22px"};
    text-align: ${(props: TextProps) => props.centered ? "center" : "left"};
    max-width: 100%;
    strong {
        font-family: 'Bold';
        font-weight: bold;
    }
`

export const TextDefaultItalic = styled(TextDefault)`
    font-family: "Medium";
    font-style: italic;
`

export const TextDefaultSmall = styled(TextDefault)`
    font-size: 15px;
    font-family: "Unipol-Book";
    line-height: 23px;
    text-align: center;
    color: #003b5c;
`

export const TextLight = styled(TextDefault)`
    font-size: 16px;
    color: #3079c9;
`

export const TextLightB = styled(TextLight)`
    font-weight: bold;
`

export const BigText = styled(TextLight)`
    font-family: "Bold";
    color: #3079c9;
    font-size: 19px;
    line-height: 25px;
    text-align: center;
`

export const WhiteText = styled(TextDefault)`
    color: #fff;
`

export const PageTitleStandard = styled(TextLightB)`
    font-family: "Bold";
    line-height: 29px;
    font-size: 20px;
    text-align: center;
    color: #003b5c;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
`

export const PageTitle = styled(PageTitleStandard)`
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100px;
        height: 2px;
        background-color: #0f3250;
    }
`

export const TextB = styled.p<TextProps> `
    font-family: "Medium";
    text-align: center;
    color: #3079c9;
    line-height: 22px;
    font-size: 17px;
    max-width: 100%;
`
