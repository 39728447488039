import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate, useParams } from "react-router-dom"
import { PATHS } from "../../routes/CaiExperienceRouter"
import { ModalContentProps } from "../../types/commonTypes"
import { composeUrl } from "../../utils/utils"
import { SecondChoiseButton } from "../styled/Button.style"
import { Section } from "../styled/Containers.style"
import { IconContainer } from "../styled/Icon.style"
import { ModalConfirmButton } from "../styled/Modal.style"
import { Vspace } from "../styled/Separators.style"
import { TextDefaultSmall } from "../styled/Text.style"

export const UnknownCtoDataModal = ({ close }: ModalContentProps<null>) => {
    const { token } = useParams();
    const navigate = useNavigate();

    const endProcessHandler = () => {
        const _pathname = composeUrl(token, PATHS.CAI_PAPER)
        navigate(_pathname)
    }

    /*return (
        <>
            <Section>
                <IconContainer>
                    <FontAwesomeIcon icon={faComments} />
                </IconContainer>
            </Section>
            <Section>
                <TextDefaultSmall>Ti consigliamo di confrontarti con la controparte per richiederle i suoi dati e firmare la <strong>CAI digitale.</strong></TextDefaultSmall>
            </Section>

            <Section>
                <TextDefaultSmall>Senza i dati necessari puoi comunque procedere con il caricamento della cai cartacea</TextDefaultSmall>
            </Section>

            <Section>
                <ModalConfirmButton onClick={close}>HO OTTENUTO I DATI MANCANTI</ModalConfirmButton>
                <Vspace></Vspace>
                <SecondChoiseButton
                    onClick={endProcessHandler}
                >Non posso recuperare altre informazioni</SecondChoiseButton>
            </Section>

        </>
    )*/

    return (
        <>
            <Section>
                <IconContainer>
                    <FontAwesomeIcon icon={faInfoCircle} />
                </IconContainer>
            </Section>

            <Section>
                <TextDefaultSmall>Le informazioni richieste sono necessarie per procedere con la Constatazione Amichevole digitale, se riesci a recuperarle puoi tornare indietro e inserirle.<br />In alternativa potrai scattare una foto della tua Constatazione Amichevole in formato cartaceo<br /></TextDefaultSmall>
            </Section>

            <Vspace qty={2}></Vspace>

            <Section>
                <ModalConfirmButton onClick={close}>TORNO INDIETRO</ModalConfirmButton>
                <Vspace></Vspace>
                <SecondChoiseButton
                    onClick={endProcessHandler}
                >Procedi con la Constatazione Amichevole Cartacea</SecondChoiseButton>
            </Section>

        </>
    )

}
