import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CaiInfoModal } from "../components/cai-info-modal/CaiInfoModal";
import CaiControparteCmp from "../components/driver/CaiControparteCmp";
import { RealtimeModal } from "../components/modal/RealtimeModal";
import { NextButton, SecondChoiseButton } from "../components/styled/Button.style";
import { Section } from "../components/styled/Containers.style";
import { InfoIcon } from "../components/styled/Icon.style";
import { CaiWizardPointBadge } from "../components/styled/page-custom/Cai.style";
import { VPercentageSeparator, Vspace } from '../components/styled/Separators.style';
import { PageTitle, TextDefault } from "../components/styled/Text.style";
import { UnknownCtoDataModal } from "../components/unknown-ctp-data/UnknownCtpDataModal";
import { PATHS } from "../routes/CaiExperienceRouter";
import { selectCommunicationData } from "../store";
import { saveCommunicationAct } from "../store/communication-data/actions";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { BoolStringEnum } from "../types/commonTypes";
import { CommunicationType, ControparteInfo } from "../types/communication/communicationDataType";
import { composeUrl, isIpernaturale, tqPageView } from "../utils/utils";
import PageContainerCmp from "./PageContainerCmp";

  /* istanbul ignore next */
const CaiDatiContropartePage = () => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let { token } = useParams();

    const communicationData = useAppSelector(selectCommunicationData)
    const { communication, caiValidationInfo } = communicationData

    const [controparteInfo, setControparteInfo] = useState<ControparteInfo>(communication.controparteInfo)
    const [openUnknownCtpDataModal, setOpenUnknownCtpDataModal] = useState(false)
    const [openCaiModal, setOpenCaiModal] = useState(false)

    const isCaiStepValid = (
        (controparteInfo?.alsoOwner === BoolStringEnum.Y && caiValidationInfo.y_ctp_contactInfo === true) ||
        (controparteInfo?.alsoOwner === BoolStringEnum.N && caiValidationInfo.n_ctp_contactInfo === true) ||
        (controparteInfo?.alsoOwner === BoolStringEnum.DN && caiValidationInfo.dn_ctp_contactInfo === true)
    )

    const goToNextStepHandler = () => {
        const updCommunication = new CommunicationType({
            ...communication,
            controparteInfo: new ControparteInfo(controparteInfo),
        })
        dispatch(saveCommunicationAct({
            data: updCommunication,
            callback: () => navigate(composeUrl(token, PATHS.CAI_PREVIEW))
        })
        )
    }

    const showButtonSecondChoice = () => {
        if (controparteInfo?.alsoOwner === "Y" && controparteInfo?.y_ctp_contactInfo.name && controparteInfo?.y_ctp_contactInfo.surname) return false;
        if (controparteInfo?.alsoOwner === "N" && controparteInfo?.n_ctp_contactInfo.name && controparteInfo?.n_ctp_contactInfo.surname && controparteInfo?.n_ctp_contactInfo.phoneNumber) return false;
        return true;
    }

    useEffect(() => {
        // Analytics
        const tealiumPageLabel = isIpernaturale(communication) ?
            'cai:cai:constatazione amichevole:step4:ipernaturale' :
            'cai:cai:constatazione amichevole:step4:non ipernaturale'
        tqPageView(tealiumPageLabel)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <RealtimeModal
                modalOpened={openUnknownCtpDataModal}
                hTitle="Informazioni Mancanti" //prima era: "dati controparte"
                hCloseButton={true}
                onCloseHandler={() => setOpenUnknownCtpDataModal(false)}
                onOpenHandler={() => {
                    // Analytics
                     /* istanbul ignore next */
                    tqPageView('cai:cai:constatazione amichevole:info mancante')
                }}>
                <UnknownCtoDataModal />
            </RealtimeModal>

            <RealtimeModal
                modalOpened={openCaiModal}
                hTitle="Constatazione amichevole"
                hCloseButton={true}
                onCloseHandler={() => setOpenCaiModal(false)}>
                <CaiInfoModal />
            </RealtimeModal>

            <PageContainerCmp
                routePath={PATHS.CAI_DATI_CONTROPARTE}
                removeNextBtn
                hasPaddingX
            >
                <>
                    <Section>
                        <PageTitle>Constatazione amichevole <InfoIcon data-testid="info-icon" icon={faCircleInfo} onClick={() => setOpenCaiModal(true)} /></PageTitle>
                        <CaiWizardPointBadge>3/3</CaiWizardPointBadge>
                        <Vspace></Vspace>
                        <TextDefault centered sm>Infine ti chiediamo di aiutarci a completare alcune informazioni sulla controparte.</TextDefault>
                        <VPercentageSeparator></VPercentageSeparator>
                    </Section>

                    <CaiControparteCmp
                        initValues={controparteInfo}
                        onChange={(newInfo: Partial<ControparteInfo>) => setControparteInfo(currInfo => ({
                            ...currInfo,
                            ...newInfo
                        }))}
                    />
                </>
                <Section>
                    <NextButton
                        onClick={goToNextStepHandler} disabled={!isCaiStepValid}>Avanti</NextButton>
                    <Vspace></Vspace>
                    {showButtonSecondChoice() && <SecondChoiseButton onClick={() => setOpenUnknownCtpDataModal(true)}>Non conosco i dati mancanti</SecondChoiseButton>}
                </Section>
            </PageContainerCmp>

        </>

    )
}

export default CaiDatiContropartePage