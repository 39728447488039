import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/assets/css/leaflet.css";
import L from "leaflet";
import LF from "leaflet-geometryutil";
import { Map as MapProp } from "leaflet";
import { Marker, MapContainer, useMapEvents } from "react-leaflet";
import "leaflet-providers";
import axios from "axios";
import { ChangeEvent, KeyboardEvent, SyntheticEvent, useEffect, useMemo, useReducer, useState } from "react";
import { mapAddressFinderReducer, MapStateActions } from "./MapAddressFinderReducer";
import MapMarkerImg from './../../assets/img/map-marker.png'
import { useAppSelector } from "../../store/hooks";
import { selectCommunicationData } from "../../store";
import { isPositionPresent } from "../../utils/utils";
import { MapPageContainer } from "../styled/Map.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faTimes } from "@fortawesome/free-solid-svg-icons";
import ConfirmCancelBtn from "../ActionsBtn/ConfirmCancelBtn";
interface MapCmpProps {
    displayOnly: boolean;
    fixedMarkerDropMap: boolean;
    withSearch: boolean;
    hasProximityWarning: boolean;
    maxDistance?: number;
    zoom?: number;
    classes?: string;
    manageBtnPresent?: boolean;
    onCancelHandler?: any;
    onConfirmHandler?: any;
}

/* istanbul ignore next */
const MAX_DISTANCE = 1500;

export const MapAddressFinder = (props: MapCmpProps) => {
    /* istanbul ignore next */
    const { displayOnly, fixedMarkerDropMap, withSearch, hasProximityWarning, maxDistance, zoom, classes, manageBtnPresent, onCancelHandler, onConfirmHandler } = props;
    /* istanbul ignore next */
    const [mapRef, setMap] = useState<MapProp | null>(null);
    /* istanbul ignore next */
    const communicationData = useAppSelector(selectCommunicationData)
    /* istanbul ignore next */
    const { communication } = communicationData;

    const stateInitValues = {
        targetPosition: null,
        address: '', // inc_luogo
        suggestions: [],
        initAddress: null,
        centerMap: null,
        timeout: null,
        proximityWarning: false,
        markerMap: null
    }

    const [state, dispatch] = useReducer(mapAddressFinderReducer, {
        ...stateInitValues,
        touchZoom: !displayOnly,
        doubleClickZoom: !displayOnly,
        scrollWheelZoom: !displayOnly,
        dragging: !displayOnly,
        center: { lat: parseFloat(communication.inc_latitude), lon: parseFloat(communication.inc_longitude) }
    });
    const centerPresentInState = typeof communication.inc_latitude === "number"
    const positionPresent = isPositionPresent(communication) && centerPresentInState

    useEffect(() => {
        if (!isPositionPresent(communication)) {
            return;
        }
        /* istanbul ignore next */
        if (!mapRef) return;

        const _layer: any = L.tileLayer

        const _layerProvider: any = _layer.provider("HERE.terrainDay", {
            app_id: window.REACT_APP_MAP_APP_ID,
            app_code: window.REACT_APP_MAP_APP_CODE,
            maxZoom: 20,
        })
        _layerProvider.addTo(mapRef);

        const _center: any = { lat: communication.inc_latitude, lng: communication.inc_longitude }
        const newState = {
            initialMap: [communication.inc_latitude, communication.inc_longitude, communication.inc_luogo],
            initAddress: communication.inc_luogo,
            targetPosition: { lat: communication.inc_latitude, lng: communication.inc_longitude },
            centerMap: { lat: communication.inc_latitude, lng: communication.inc_longitude },
            address: communication.inc_luogo,
            center: _center,
            markerMap: displayOnly ? <Marker position={_center} icon={iconCrash}></Marker> : null
        }

        dispatch({ type: MapStateActions.UPDATE_STATE, payload: newState })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [communication?.inc_luogo, mapRef])

    /* istanbul ignore next */
    const getLatLonFromJsonP = (jsonPStrRespose: string) => {
        let cleandeStr = jsonPStrRespose.replace("H.service.jsonp.handleResponse(6)(", "");
        cleandeStr = cleandeStr.slice(0, -1);
        let jResponse = JSON.parse(cleandeStr);
        let viewRespObj = jResponse && jResponse.Response && jResponse.Response.View && jResponse.Response.View.length > 0 && jResponse.Response.View[0];
        let firstResObj = viewRespObj && viewRespObj.Result && viewRespObj.Result.length > 0 && viewRespObj.Result[0];
        let locationObj = firstResObj && firstResObj.Location;
        return {
            Latitude: locationObj && locationObj.DisplayPosition && locationObj.DisplayPosition.Latitude,
            Longitude: locationObj && locationObj.DisplayPosition && locationObj.DisplayPosition.Longitude,
            AddressLabel: locationObj && locationObj.Address && locationObj.Address.Label,
        };
    }

    const selectedPosition = (suggestion: any, userSelectedSuggestion?: boolean) => {
        // If user has clicked enter and there were not suggestions, then update only the label (the map will not move)
        if (!userSelectedSuggestion && !suggestion) {
            dispatch({
                type: MapStateActions.UPDATE_STATE, payload: {
                    suggestions: [],
                }
            })
            /* istanbul ignore next */
            mapRef && mapRef.setView({ lat: state.targetPosition.lat, lng: state.targetPosition.lng });
        } else {
            // Else, if a suggestion was present, then execute reverse geocoding
            /* istanbul ignore next */
            axios({
                url:
                    "https://geocoder.api.here.com/6.2/geocode.json?xnlp=CL_JSMv3.0.17.0&app_id=" +
                    window.REACT_APP_MAP_APP_ID +
                    "&app_code=" +
                    window.REACT_APP_MAP_APP_CODE +
                    "&locationId=" +
                    suggestion.locationId +
                    "&jsoncallback=H.service.jsonp.handleResponse(6)",
                method: "get",
            })
                .then((response) => {
                    let { Latitude, Longitude, AddressLabel } = getLatLonFromJsonP(response.data);

                    // If the user has clicked Enter and there were a suggestion (or if the user selected the suggestion), the label will be set to what the user typed
                    // the lat-lon will be set to the reverse-geocoding value and the map will go to the first suggestion
                    let addressLabel = userSelectedSuggestion ? AddressLabel : state.address;

                    dispatch({
                        type: MapStateActions.UPDATE_STATE, payload: {
                            suggestions: [],
                            targetPosition: { lat: Latitude, lng: Longitude },
                            address: addressLabel,
                            proximityWarning: false
                        }
                    })
                    /* istanbul ignore next */
                    mapRef && mapRef.setView({ lat: Latitude, lng: Longitude });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const submitPosition = (event: SyntheticEvent) => {
        event.preventDefault();
        selectedPosition(state.suggestions && state.suggestions[0]);
    }

    const onEnterPress = (e: KeyboardEvent) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            submitPosition(e);
        }
    };

    const hanldeAddress = (addr: any, state: string) => {
        /* istanbul ignore next */
        if (state === "reverse") {
            let organizeAddress = addr.Street + " " + addr.HouseNumber + ", " + addr.PostalCode + " " + addr.City + " " + addr.County;
            if (addr.Street === "undefined") {
                organizeAddress = addr.PostalCode + " " + addr.City + " " + addr.County;
            }
            return organizeAddress;
        }
        if (state === "geosearch") {
            let organizeAddress = addr.street + " " + addr.houseNumber + ", " + addr.postalCode + " " + addr.city + " " + addr.county;
            if (!addr.houseNumber) {
                organizeAddress = addr.street + " " + addr.postalCode + " " + addr.city + " " + addr.county;
            }
            return organizeAddress;
        }
    }

    //se digito il testo mi restituisce una serie di suggerimenti possibili
    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { timeout } = state;
        const proximity = communication.inc_latitude + "," + communication.inc_longitude + "," + (maxDistance || MAX_DISTANCE)
        clearTimeout(timeout);
        dispatch({ type: MapStateActions.UPDATE_STATE, payload: { address: event.target.value } })

        if (state.address) {
            let elaborateQuery = state.address.replace(" ", "+");
            dispatch({
                type: MapStateActions.UPDATE_STATE, payload: {
                    timeout: setTimeout(() => {
                        axios({
                            url:
                                "https://autocomplete.geocoder.api.here.com/6.2/suggest.json?app_id=" +
                                window.REACT_APP_MAP_APP_ID +
                                "&app_code=" +
                                window.REACT_APP_MAP_APP_CODE +
                                "&query=" +
                                elaborateQuery +
                                "&maxresults=10&prox=" +
                                proximity,
                            method: "get",
                        })
                            .then((response) => {
                                let sugg = response.data.suggestions;
                                let elencoSugg: any[] = [];
                                sugg.forEach((res: any) => {
                                    if (res.distance < (maxDistance || MAX_DISTANCE)) {
                                        res.elaboratedLabel = hanldeAddress(res.address, "geosearch");
                                        elencoSugg.push(res);
                                    }
                                });
                                dispatch({
                                    type: MapStateActions.UPDATE_STATE, payload: { suggestions: elencoSugg }
                                });
                            })
                            .catch((error) => {
                                /* istanbul ignore next */
                                console.log(error);
                            });
                    }, 500),
                }
            })

        } else {
            dispatch({
                type: MapStateActions.UPDATE_STATE, payload: { suggestions: [] }
            });
        }
        dispatch({
            type: MapStateActions.UPDATE_STATE, payload: { proximityWarning: true }
        });

    }

    /* istanbul ignore next */
    const hideWarning = () => {
        dispatch({
            type: MapStateActions.UPDATE_STATE, payload: { proximityWarning: false }
        });
    }

    const resetPosition = () => {
        dispatch({
            type: MapStateActions.UPDATE_STATE, payload: {
                suggestions: [],
                targetPosition: { lat: communication.inc_latitude, lng: communication.inc_longitude },
                address: state.initAddress,
            }
        })
        /* istanbul ignore next */
        mapRef && mapRef.setView({ lat: parseFloat(communication.inc_latitude), lng: parseFloat(communication.inc_longitude) }, zoom);
    }

    /* istanbul ignore next */
    const dragEndHandler = (e: any) => {
        const _eTarget: any = (e as any).target;
        if (mapRef && _eTarget.getCenter() !== communication.inc_luogo) {
            const targetPosition = _eTarget.getCenter();
            dispatch({
                type: MapStateActions.UPDATE_STATE, payload: {
                    /* istanbul ignore next */
                    targetPosition: { lat: targetPosition.lat, lng: targetPosition.lng },
                }
            })
            /* istanbul ignore next */
            let _distance = LF.distance(mapRef, state.targetPosition, state.centerMap);
            if (_distance <= (maxDistance || MAX_DISTANCE)) {
                axios({
                    url:
                        "https://reverse.geocoder.api.here.com/6.2/reversegeocode.json?app_id=" +
                        window.REACT_APP_MAP_APP_ID +
                        "&app_code=" +
                        window.REACT_APP_MAP_APP_CODE +
                        "&prox=" +
                        targetPosition.lat +
                        "," +
                        targetPosition.lng +
                        ",50&mode=retrieveAddresses&pos=" +
                        targetPosition.lat +
                        "," +
                        targetPosition.lng,
                    method: "get",
                })
                    .then((response) => {
                        /* istanbul ignore next */
                        let probablyAddress = response.data.Response.View.length ? response.data.Response.View[0].Result[0].Location.Address : null;
                        let organizeAddress = probablyAddress.Label;

                        dispatch({
                            type: MapStateActions.UPDATE_STATE, payload: { address: organizeAddress }
                        })
                    })
                    .catch((error) => {
                        /* istanbul ignore next */
                        console.log(error);
                    });
            } else {
                resetPosition();
            }
        }
    }

    const MapEvents = () => {
        /* istanbul ignore next */
        useMapEvents({
            dragend: (e: any) => { dragEndHandler(e) }, zoomend: (e: any) => { dragEndHandler(e) }
        });
        return null;
    }

    const componentMap = (
        <div className={displayOnly ? "parent-stop-events" : "parent-map"}>

            {positionPresent && <MapContainer
                ref={setMap}
                touchZoom={state.touchZoom}
                scrollWheelZoom={state.scrollWheelZoom}
                dragging={state.dragging}
                doubleClickZoom={state.doubleClickZoom}
                zoomControl={false}
                attributionControl={false}
                center={state.center}
                zoom={zoom || 18}
                maxZoom={18}
                minZoom={15}
            >
                {state.markerMap}
                <MapEvents />
            </MapContainer>}
        </div>
    );

    const resumeButton = <div
        className="buttonMap"
        onClick={(e) => {
            resetPosition();
        }}
    >
        <FontAwesomeIcon icon={faTimes} />
    </div>
    const marker: any = <><img className="marker-map-centered" src={MapMarkerImg} alt="map marker" /> </>
    const fakeMarker = fixedMarkerDropMap ? <img className="marker-map-centered fake-marker" src={MapMarkerImg} alt="fake map marker" /> : null;

    const iconCrash = useMemo(() => new L.Icon({
        iconUrl: marker,
        iconSize: new L.Point(60, 60),
        className: "div-icon",
        iconAnchor: [30, 55],
        popupAnchor: [0, -60],
        // eslint-disable-next-line react-hooks/exhaustive-deps
    } as any), [])
    const formSearch = (
        <form onSubmit={submitPosition} className="address-form">
            <div className="form-class">
                <textarea onKeyDown={onEnterPress} placeholder="Inserisci un indirizzo"
                    rows={2} value={state.address} onChange={handleChange} />
                {state.address.length ? (
                    <div className="suggest-list">
                        {state.suggestions.map((suggestion: any, i: number) => (
                            <div className="text-suggestion" key={i} onClick={() => selectedPosition(suggestion, true)}>
                                {suggestion.elaboratedLabel}
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
            {hasProximityWarning && state.proximityWarning && (
                <div className="proximity-warning-container" onClick={hideWarning}>
                    <div className="little-cube"></div>
                    <FontAwesomeIcon icon={faTimes} />
                    <div>
                        <FontAwesomeIcon icon={faCircleExclamation} />
                        <p>Puoi cercare un indirizzo solo in prossimità del luogo rilevato.</p>
                    </div>
                </div>
            )}
        </form>
    );
    const labelMap = displayOnly ? (
        <div className="label-map">
            L'incidente risulta avvenuto nei pressi di <strong>{communication.inc_luogo}</strong>?
        </div>) : null

    const _onCancelHandler = () => {
        onCancelHandler()
    }

    const _onConfirmHandler = () => {
        onConfirmHandler({
            latitude: state.targetPosition.lat,
            longitude: state.targetPosition.lng,
            luogo: state.address
        })
    }

    return (
        <>
            <MapPageContainer className={classes} >
                {labelMap}
                {fakeMarker}
                {withSearch && <>
                    {resumeButton}
                    {formSearch}
                </>}
                {componentMap}
                {manageBtnPresent && <ConfirmCancelBtn
                    cancelPresent={true}
                    confirmPresent={true}
                    cancelCallback={() => _onCancelHandler()}
                    confirmCallback={() => _onConfirmHandler()}
                    className="map-modify-confirm" />}
            </MapPageContainer></>
    )
}

