import styled from 'styled-components'

export const IncidentBanner = styled.div `
    position: relative;
    width: 100vw;
    margin: -1rem -1rem 0;
    min-height: 130px;
    max-height: 200px;
    background: url("/banner_incident.png") no-repeat;
    background-position: center;
    background-size: cover;
`