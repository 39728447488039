import { useNavigate, useParams } from "react-router-dom"

import { selectCommunicationData } from "../../store"
import { useAppSelector } from "../../store/hooks"
import { ModalContentProps } from "../../types/commonTypes"
import { localTimeHour, caiExpireLocalDate } from "../../utils/time.utils"
import { composeUrl, tqPageView } from "../../utils/utils"
import { setLocalStorage_forwardState } from "../../utils/utils.store"
import { SecondChoiseButton } from "../styled/Button.style"
import { Section } from "../styled/Containers.style"
import { ModalConfirmButton } from "../styled/Modal.style"
import { Vspace } from "../styled/Separators.style"
import { TextDefaultSmall } from "../styled/Text.style"
import { PATHS } from "../../routes/CaiExperienceRouter"

/* istanbul ignore next */
export const ShareCommunicationModal = ({ close }: ModalContentProps<null>) => {
    let navigate = useNavigate();
    let { token } = useParams();
    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData

    const shareCommCb = () => /* istanbul ignore next */ {
        const _pathname = composeUrl(token, PATHS.END_COMMUNICATION_FORWARDED)
        setLocalStorage_forwardState(true, _pathname)
        navigate(_pathname)
    }

    const onShareHandler = () => {
        if (navigator.share) {
            // Analytics
            tqPageView('cai:inoltra comunicazione:condivisione')

            const title = "SMS Sinistro inoltrato"
            const text = `Abbiamo rilevato un possibile incidente sul tuo veicolo targato ${communication.veic_targa}. Richiedi assistenza entro le ${localTimeHour(communication.creation_date)} del ${caiExpireLocalDate(communication.creation_date)} qui `
            const url = `${window.REACT_APP_VTV_API_ENDPOINT}/${communication.token}`
            navigator.share({
                title,
                text,
                url
            }).then(() => {
                // Manage forward happened here
                // post events FORWARD_COMMUNICATION
                shareCommCb()
            }).catch(() => {
                console.log('Inoltro annullato');
            });
        } else {
            alert('Il browser non supporta la funzionalità richiesta. Si prega di provvedere con un inoltro manuale dell\'SMS');
        }
    }

    return (
        <>
            <Section>
                <TextDefaultSmall>Se non eri alla guida del tuo veicolo al momento dell’incidente puoi inoltrare questa comunicazione al conducente.</TextDefaultSmall>
            </Section>
            <Section>
                <TextDefaultSmall>Ti ricordiamo che in questo caso <strong>sarà lui a compilare i dati relativi all’incidente</strong> e tu non avrai più accesso a questa pagina.</TextDefaultSmall>
            </Section>

            <Section>
                <ModalConfirmButton
                    onClick={onShareHandler}>Inoltro la comunicazione</ModalConfirmButton>
                <Vspace></Vspace>
                <SecondChoiseButton
                    onClick={close}
                >Non voglio inoltrare la comunicazione</SecondChoiseButton>
            </Section>

        </>
    )
}
