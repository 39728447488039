
export enum MapStateActions {
  UPDATE_STATE = 'UPDATE_STATE'
}

export interface MapStateAction {
  type: MapStateActions;
  payload: any;
}

export const mapAddressFinderReducer = (state: any, action: MapStateAction) => {
  if (action.type === MapStateActions.UPDATE_STATE) {
    return {
      ...state,
      ...action.payload
    };
  }
  throw Error('Unknown action.');
}