import { AUTH_CONFIRM_BASE_ROUTH, AUTH_CONFIRM_PATHS, AUTH_CONFIRM_ROUTES } from "../routes/AuthConfirmRouter"
import { PATHS, ROUTES } from "../routes/CaiExperienceRouter"
import { BoolStringEnum, RouteType, TemplateTypeEnum, UNIPOLSAI_SUBSTRING } from "../types/commonTypes"
import { CommunicationType, EventValueEnum, UserTypeEnum } from "../types/communication/communicationDataType"
import { TqType } from "../types/communication/sign-type.ts"

export const IN_APP = 'inapp'
export const IN_APP_CLASS = 'in-app'

export const isInApp = () => {
    const isInApp = hasQueryParam(IN_APP)
    return !!isInApp
}

export const getIsInAppClass = () => {
    const _inApp = isInApp()
    return _inApp ? IN_APP_CLASS : ''
}

export const hasQueryParam = (paramKey: string): boolean => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return !!urlSearchParams.has(paramKey)
}

export const getQueryParamsString = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const qs = urlSearchParams.toString()
    if (qs) {
        return `?${qs}`
    }
    return ''
}

export const hasAnyQueryParams = (url: string): boolean => {
    return !!url?.includes('?');
}

export const addQueryParamsToUrl = (url: string): string => {
    // add query params if not present
    if (!hasAnyQueryParams(url)) {
        return `${url}${getQueryParamsString()}`
    }
    return url
}

/* istanbul ignore next */
export const getExpiredPathFromComm = (comm: CommunicationType) => {
    let newUrl = composeUrl(comm.token, PATHS.TOKEN_EXPIRED)
    if (comm.type === TemplateTypeEnum.CONFIRM_AND_AUTHORIZE_USER) {
        newUrl = composeUrl(comm.token, AUTH_CONFIRM_PATHS.AUTHORIZE_CONFIRM_TOKEN_EXPIRED, AUTH_CONFIRM_BASE_ROUTH)
    }
    return newUrl;
}

/* istanbul ignore next */
export const composeUrl = (token: string = '', stepUrl: string, baseRoute?: string): string => {
    let nextUrl = `/${token}/${stepUrl}`
    if (baseRoute) {
        nextUrl = `/${baseRoute}${nextUrl}`
    }
    const completeUrl = addQueryParamsToUrl(nextUrl)
    return completeUrl
}

export const navigateToUrl = (navigate: any, url: string) => {
    const completeUrl = addQueryParamsToUrl(url)
    // check if we are not on that page yet
    if (window.location.pathname.indexOf(completeUrl) < 0) {
        navigate && navigate(completeUrl)
    }
}

export const getCurrentRoute = (_path: string): RouteType | undefined => {
    return ROUTES.find(r => r.path === _path) || AUTH_CONFIRM_ROUTES.find(r => r.path === _path)
}

export const isPositionPresent = (communication: CommunicationType) => {
    return !!(communication.inc_luogo || communication.inc_latitude || communication.inc_longitude)
}

/* istanbul ignore next */
export const isPhoneNumberLengthCorrect = (plate: string) => {
    if (plate.length > 5 && plate.length < 16) return true;
    return false;
}

const isControparteUnipolSai = (communication: CommunicationType): boolean => {
    if (communication?.cp_compagnia_assic?.indexOf(UNIPOLSAI_SUBSTRING) >= 0) {
        return true
    }
    return false
}

const isPlateOriginal = (communication: CommunicationType): boolean => {
    if (communication?.original_cp_veic_targa === communication?.cp_veic_targa) {
        return true
    }
    return false
}

/* istanbul ignore next */
export const isPlateLengthCorrect = (plate: string) => {
    if (plate?.length > 5) return true;
    return false;
}

/* istanbul ignore next */
export const isPlateAlphanumericOnly = (plate: string) => {
    const regex = /^[a-zA-Z0-9]*$/;
    return plate.match(regex);
}

export const isPhoneNumberFormat = (strIn: string) => {
    //eslint-disable-next-line
    const regex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g
    return strIn.match(regex)
}
export const getUserType = (communication?: CommunicationType): UserTypeEnum => {
    if (!communication || !communication?.token) {
        return UserTypeEnum.NON_NATURALE
    }

    if (communication?.type?.indexOf(TemplateTypeEnum.SIN_INAT) >= 0) {
        if (isControparteUnipolSai(communication)) {
            if (isPlateOriginal(communication))
                return UserTypeEnum.IPER_NATURALE
            else
                return UserTypeEnum.SUPER_NATURALE  // stessa agenzia Unipol ma targa modificata
        }
    } else {
        if (isControparteUnipolSai(communication)) {
            return UserTypeEnum.SUPER_NATURALE
        }
    }

    return UserTypeEnum.NON_NATURALE
}

export const isIpernaturale = (communication: CommunicationType) => {
    const userType = getUserType(communication)
    return userType === UserTypeEnum.IPER_NATURALE
}

export enum ERROR_CODES {
    TOKEN_EXPIRED = 'TOKEN_EXPIRED'
}

export const hasCaiSecondPageData = (communication: CommunicationType) => {
    const { feritiInfo, testimoniInfo, oggettiDiversiInfo, altriVeicoliInfo, baremeFreeDescription } = communication
    if (feritiInfo?.isPresent === BoolStringEnum.Y && feritiInfo?.contactList?.length > 0) {
        return true
    }
    if (testimoniInfo?.isPresent === BoolStringEnum.Y && testimoniInfo?.contactList?.length > 0) {
        return true
    }
    if (oggettiDiversiInfo?.isPresent === BoolStringEnum.Y && oggettiDiversiInfo?.contactList?.length > 0) {
        return true
    }
    if (altriVeicoliInfo?.isPresent === BoolStringEnum.Y && altriVeicoliInfo?.contactList?.length > 0) {
        return true
    }

    if (baremeFreeDescription.length) {
        return true
    }

    return false;
}


/* istanbul ignore next */
export const hasCommSignatureEvent = (comm: CommunicationType): boolean => {
    const evFound = comm?.events?.find((ev => ev.value === EventValueEnum.SIGNATURE_FOLDER_OUTCOME))
    return !!evFound
}

/* istanbul ignore next */
export const isCounterProposal = (comm: CommunicationType): boolean => {
    const retVal = (comm?.type?.indexOf(TemplateTypeEnum.SIN_INAT) >= 0) && hasCommSignatureEvent(comm)
    return !!retVal
}

export const tqPageView = (value: string) => {
    // // Do not og analytics in prod, until final deploy
    // if ((window as any).REACT_APP_TEALIUM_ENV === 'prod') {
    //     return
    // }

    window.utag && window.utag.view({
        page_name: value
    });
}

/* istanbul ignore next */
export const tqError = (tqType: TqType) => {
    // Do not og analytics in prod, until final deploy
    if ((window as any).REACT_APP_TEALIUM_ENV === 'prod') {
        return
    }

    const { errors_elements,
        errors_id,
        errors_messages,
        errors_type } = tqType

    window.utag && window.utag.link && window.utag.link({
        errors_elements,
        errors_id,
        errors_messages,
        errors_type
    });
}