import { ModalBody, StyledPopup } from "../styled/Modal.style";
import 'reactjs-popup/dist/index.css';
import { ModalHeaderCmp, ModalHeaderCmpProps } from "./ModalHeaderCmp";
import { cloneElement } from "react";

interface RealtimeModalProps extends Partial<ModalHeaderCmpProps> {
  modalOpened: boolean;
  onCloseHandler?: (event?: React.SyntheticEvent | KeyboardEvent | TouchEvent | MouseEvent) => void;
  onOpenHandler?: (event?: React.SyntheticEvent | KeyboardEvent | TouchEvent | MouseEvent) => void;
  children: any;
  onConfirmHandler?: (returnData: any) => void
  bodyClassName?: string;
}

export const RealtimeModal = (props: RealtimeModalProps) => {
  const { children, modalOpened, onCloseHandler, onOpenHandler, onConfirmHandler, bodyClassName } = props

  const ModalContainer: any = (close: () => void) => {

    const onConfirmAndCloseHandler = (retData: any) => {
      close()
      onConfirmHandler && onConfirmHandler(retData)
    }

    return (
      <>
        <ModalHeaderCmp {...props} close={close}></ModalHeaderCmp>
        <ModalBody className={bodyClassName}>
          {cloneElement(children, { onConfirmAndCloseHandler, close })}
        </ModalBody>
      </>
    )
  }

  return (
    <StyledPopup
      open={modalOpened}
      modal
      nested
      className='realtime-modal'
      onClose={onCloseHandler}
      onOpen={onOpenHandler}
    >
      {ModalContainer}
    </StyledPopup>
  )
}
