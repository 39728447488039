import styled from 'styled-components'


export const List = styled.ul `
    list-style: none;
    padding: 0 1rem;
`

export const ListItem = styled.li `
    font-family: "Book";
    position: relative;
    padding-left: 24px;
    line-height: 22px;
    margin-top: 24px;
    font-size: 17px;
    text-align: left;
    color: #003b5c;

    &:before {
        content: "";
        position: absolute;
        top: 3px;
        left: 0;
        width: 12px;
        height: 12px;
        background-color: #003b5c;
    }

    .hilighted {
        font-family: "Unipol-Medium";
        font-weight: bold;
    }
    
`