import styled from 'styled-components'
import { CenteredContainer } from './Common.style';
import { Img } from './Img.style';

interface LegendItemIndicatorProps {
    ctp?: boolean;
}

export const LegendItemContainer = styled(CenteredContainer)`
    &:nth-of-type(2){
        margin-left: 2rem;
    }
`
export const BaremeImg = styled(Img)`
    min-height: 100px;
    width: 80%;
    visibility: visible;
    opacity: 1;
    transition: opacity 0.4s ease-in-out;    
    &.is-loading {
        visibility: hidden;
        opacity: 0;     
    }
`


export const LegendItemIndicator = styled.div<LegendItemIndicatorProps>`
    background: #3377fe;
    width: 18px;
    height: 18px;
    border: 1px solid #444;
    ${props => props.ctp && 'background: #ffe900;'}
`

export const LegendItemLabel = styled.p`
    font-family: "Book";
    font-size: 16px;
    color: #444;
    text-transform: uppercase;
    margin-left: 0.5rem;
    margin-top: 0.15rem;
`

export const BaremeValueContainer = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.2rem 2rem;
    background-color: #f5f5f5;
    width: 100%;
    margin: 8px auto;
    border: 1px solid #cecece;

    svg {
        font-size: 18px;
    }
`

export const BaremeLabel = styled.p`
    flex: 1;
    font-style: italic;
    font-size: 15px;
    text-transform: initial;
    color: #0f3250;
    font-family: "book";
    white-space: break-spaces;
`