
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { PageContainer, Section } from "../components/styled/Containers.style";
import { selectCommunicationData } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { NextButton } from './../components/styled/Button.style'
import { getCurrentRoute, isInApp, tqPageView } from "../utils/utils";
import { Breadcrumb } from "../components/breadcrumb/Breadcrumb";
import { saveCommunicationInBackgroundAct } from "../store/communication-data/actions";
import { CommunicationType, EventCommType, EventValueEnum } from "../types/communication/communicationDataType";
import FooterCmp from "../components/footer/Footer";
import CaptchaComponent from "../components/captcha/CaptchaComponent";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface PageContainerCmpProps {
    nextStepHandler?: any,
    backStepHandler?: Function,
    backPath?: string;
    children: any;
    routePath: string;
    removeNextBtn?: boolean;
    nextDisabled?: boolean;
    nextTitle?: string;
    hasPaddingX?: boolean;
    isForceLastPathname?: boolean;
    eventsOnLoad?: Array<EventValueEnum>;
    doNotRegisterLastPathname?: boolean;
}

/* istanbul ignore next */
const PageContainerCmp = (props: PageContainerCmpProps) => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { nextTitle, nextStepHandler, backPath, children, routePath,
        removeNextBtn, nextDisabled, backStepHandler, hasPaddingX,
        isForceLastPathname, eventsOnLoad, doNotRegisterLastPathname } = props
    const route = useMemo(() => getCurrentRoute(routePath), [routePath])

    const [activateCaptcha, setActivateCaptcha] = useState(false);

    const communicationData = useAppSelector(selectCommunicationData);
    const { saveCommunicationMetadata, communication } = communicationData
    const dbUpdateLoading = !!saveCommunicationMetadata?.isLoading || nextDisabled

    useEffect(() => {

        // tealium page view
        route?.tqPageName && tqPageView(route?.tqPageName)

        window.scrollTo(0, 0);

        if (window.REACT_APP_SKIP_CAPTCHA === "false" && activateCaptcha === false) {
            setActivateCaptcha(true);
        }
        let _locationPathname = location.pathname
        if (!location?.pathname) {
            return
        }
        // saved to db last visited pathname
        let _forceLastPathname;
        if (isForceLastPathname) {
            _forceLastPathname = _locationPathname
        }
        const newEvents: EventCommType[] = []
        if (eventsOnLoad?.length) {
            eventsOnLoad.forEach((ev: EventValueEnum) => newEvents.push(new EventCommType({ value: ev })))
        }
        if (doNotRegisterLastPathname) {
            _locationPathname = ''
        }
        newEvents.push(new EventCommType({ value: _locationPathname }))

        dispatch(saveCommunicationInBackgroundAct({
            data: new CommunicationType({
                ...communication,
                lastPathname: _locationPathname,
                forceLastPathname: _forceLastPathname,
                inApp: isInApp()
            }),
            events: newEvents
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {activateCaptcha && <CaptchaComponent />}

            <ToastContainer />

            {route?.hasBreadcrumb && <Breadcrumb
                backHandler={backStepHandler}
                backPath={backPath || route?.defaultBackPath || ''}
                breadcrumbIndex={route?.breadcrumbIndex || 1}
                breadcrumbTitles={route?.breadcrumbTitles}
                backBaseRoute={route?.backBaseRoute}
            />}

            <PageContainer
                hasBreadcrumb={route?.hasBreadcrumb}
                className={`page-container with-breadcrum ${hasPaddingX && 'page-container-padding-x'}`}>

                {children}

                {!removeNextBtn && <Section>
                    <NextButton
                        disabled={dbUpdateLoading}
                        onClick={nextStepHandler}>{nextTitle || 'Avanti'}</NextButton>
                </Section>}

            </PageContainer>

            {route?.hasFooter && <FooterCmp hasDownloadButtons={route?.hasFooterDownloadButtons} />}
        </>

    )
}

export default PageContainerCmp