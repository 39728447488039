export enum CommunicationDatatActionTypes {
    CAPTCHA_CHALLENGE = '@@communication/CAPTCHA_CHALLENGE',
    LOAD_COMMUNICATION = '@@communication/LOAD_COMMUNICATION',
    LOAD_COMMUNICATION_SUCCESS = '@@communication/LOAD_COMMUNICATION_SUCCESS',
    LOAD_COMMUNICATION_ERROR = '@@communication/LOAD_COMMUNICATION_ERROR',
    SAVE_COMMUNICATION = '@@communication/SAVE_COMMUNICATION',
    SAVE_COMMUNICATION_IN_BACKGROUND = '@@communication/SAVE_COMMUNICATION_IN_BACKGROUND',
    SAVE_COMMUNICATION_SUCCESS = '@@communication/SAVE_COMMUNICATION_SUCCESS',
    SAVE_COMMUNICATION_END = '@@communication/SAVE_COMMUNICATION_END',
    SAVE_COMMUNICATION_ERROR = '@@communication/SAVE_COMMUNICATION_ERROR',
    INVOKE_SIGNATURE = '@@communication/INVOKE_SIGNATURE',
    INVOKE_SIGNATURE_SUCCESS = '@@communication/INVOKE_SIGNATURE_SUCCESS',
    INVOKE_SIGNATURE_END = '@@communication/INVOKE_SIGNATURE_END',
    INVOKE_SIGNATURE_ERROR = '@@communication/INVOKE_SIGNATURE_ERROR',
    CAI_VALIDATION_UPDATE = '@@communication/CAI_VALIDATION_UPDATE',
    LOAD_ANIA = '@@communication/LOAD_ANIA',
    LOAD_ANIA_SUCCESS = '@@communication/LOAD_ANIA_SUCCESS',
    LOAD_ANIA_ERROR = '@@communication/LOAD_ANIA_ERROR',
    EXECUTE_POLLING = '@@communication/EXECUTE_POLLING'
}