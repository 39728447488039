import { useEffect, useReducer } from "react";
import { caiValidationUpdateAct } from "../../store/communication-data/actions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { booleanRadioOptions, BoolStringEnum, CommonInfoCmpProps, getValueLabelOptions, ValueLabelOption } from "../../types/commonTypes";
import { ConducenteInfo, conducente_contactInfoItems, conducente_noIntestatario_contactInfoItems, contactCompleteInfoSchema, ContactInfo, contactInfoSchema } from "../../types/communication/communicationDataType";
import RadioOptionsCmp from "../radio/RadioOptionsCmp";
import { Section } from "../styled/Containers.style";
import ContactInfoCmp from "../contact/ContactInfoCmp";
import { genericStateReducer } from "../contact/genericStateReducer";
import { selectCommunicationData } from "../../store";

export const ConducenteInfoStateAction = {
    UPDATE_STATE: '@@conducenteInfo/UPDATE_STATE'
}

const CaiConducenteCmp = (props: CommonInfoCmpProps<ConducenteInfo>) => {
    const communicationData = useAppSelector(selectCommunicationData)
    const { communication } = communicationData
    const dispatch = useAppDispatch();
    const { initValues, onChange } = props
    const [conducenteState, conducenteDispatch] = useReducer(genericStateReducer<ConducenteInfo>(ConducenteInfoStateAction), initValues);

    useEffect(() => {
        onChange && onChange(conducenteState)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conducenteState])

    useEffect(() => {
        // init conducente info with assicurato info
        const initConducenteAssicInfo = conducenteState.y_contactInfo
        initConducenteAssicInfo.name = initConducenteAssicInfo.name || communication.assic_nome
        initConducenteAssicInfo.surname = initConducenteAssicInfo.surname || communication.assic_cognome
        initConducenteAssicInfo.phoneNumber = initConducenteAssicInfo.phoneNumber || communication.phone_number
        initConducenteAssicInfo.email = initConducenteAssicInfo.email || communication.assic_email
        conducenteDispatch({
            type: ConducenteInfoStateAction.UPDATE_STATE, payload: {
                y_contactInfo: new ContactInfo(initConducenteAssicInfo)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Section>
                <RadioOptionsCmp
                    horizontal
                    otpionsLabel="Sei anche l'intestatario della polizza? (*)"
                    options={booleanRadioOptions}
                    value={getValueLabelOptions(booleanRadioOptions, conducenteState.alsoOwner)}
                    className={'cai conducente-anche-intestatario'}
                    onChange={(newChoise: ValueLabelOption<BoolStringEnum>) =>
                        conducenteDispatch({ type: ConducenteInfoStateAction.UPDATE_STATE, payload: { alsoOwner: newChoise.value } })}
                />
            </Section>

            {conducenteState?.alsoOwner === BoolStringEnum.Y &&
                <ContactInfoCmp
                    initValues={conducenteState?.y_contactInfo}
                    contactItems={conducente_contactInfoItems}
                    onContactChange={(newContactInfo: Partial<ContactInfo>) =>
                        conducenteDispatch({
                            type: ConducenteInfoStateAction.UPDATE_STATE, payload: {
                                y_contactInfo: new ContactInfo({
                                    ...conducenteState,
                                    ...newContactInfo
                                })
                            }
                        })}
                    validationSchema={contactInfoSchema}
                    onValiddationStateChange={(formState: any) =>
                        dispatch(caiValidationUpdateAct({ y_contactInfo: formState?.isValid }))
                    }
                />}

            {conducenteState?.alsoOwner === BoolStringEnum.N &&
                <ContactInfoCmp
                    initValues={conducenteState?.n_contactInfo}
                    contactItems={conducente_noIntestatario_contactInfoItems}
                    onContactChange={(newContactInfo: Partial<ContactInfo>) =>
                        conducenteDispatch({
                            type: ConducenteInfoStateAction.UPDATE_STATE, payload: {
                                n_contactInfo: new ContactInfo({
                                    ...conducenteState,
                                    ...newContactInfo
                                })
                            }
                        })}
                    validationSchema={contactCompleteInfoSchema}
                    onValiddationStateChange={(formState: any) =>
                        dispatch(caiValidationUpdateAct({ n_contactInfo: formState?.isValid }))
                    }
                />}
        </>
    )
}

export default CaiConducenteCmp