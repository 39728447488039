import styled from 'styled-components'
import { ImgHTMLAttributes } from 'react'
import { Button } from './Button.style';

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
    transparent?: boolean;
}

export const Img = styled.img<ImageProps>`
    width: 100%;
    max-width: 100%;
    ${({ transparent }: ImageProps) => transparent && `
            mix-blend-mode: multiply;
    `}
`

interface clickableProps {
    onClick?: any;
}

export const ZommableCmpContainer = styled.div<clickableProps> `
    position: relative;
    min-width: 90px;
    width: 90px;
    min-height: 120px;
    height: 120px;
    border: 4px solid #3079c9;
    cursor: pointer;
    

    &.is-loading {
        border: none;
    }

    img {
        width: 100%;
        max-width: 100%;
        height: 100%;
    }

    &.full-page-img {
        border: none;
        cursor: unset;
        position: fixed;
        top: 10vh;
        height: 80vh;
        width: 80vw;
        z-index: 99;

        img {
            position: relative;
            height: auto;
        }
    }
`

export const ZommableImageContainer = styled.div<clickableProps>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &.full-page-img {
        overflow: scroll;
        z-index: 99;
        padding: 32px 24px;
        background-color: #fff;
    }
`

export const FullPageOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.55);
    z-index: 10;
`

export const ImgZoomBtn = styled(Button)`
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(50%, 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #3079c9;
    font-size: 16px;
    color: white;
    cursor: pointer;
`

export const CloseImgZoomBtn = styled(ImgZoomBtn)`
    width: 40px;
    height: 40px;
    bottom: unset;
    top: 0;
    transform: translate(50%, -50%);
    font-size: 1.2rem;
    z-index: 100;
`
