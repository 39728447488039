import { allPossibleZoneUrto, FileItemType, ZoneUrtoType } from "../../types/communication/communicationDataType"
import { CarUrtoContainer, UrtoBkImg, UrtoTable } from "../styled/ZonaUrto.style"
import CarUrtoBkImg from './../../assets/img/car_urto_bk.png'
import UrtoTableCellCmp from "./UrtoTableCellCmp"

interface ZoneUrtoCmpProps {
    zoneUrto: ZoneUrtoType[];
    setZoneUrto: any;
}

const ZoneUrtoCmp = (props: ZoneUrtoCmpProps) => {
    const { zoneUrto, setZoneUrto } = props

    const zoneItemSelectedHandler = (zu: Partial<ZoneUrtoType>) => {
        setZoneUrto((zones: ZoneUrtoType[]) => {
            if (zones.find(z => z.position === zu.position)) {
                return [...zones]
            } else {
                return [...zones, new ZoneUrtoType({ position: zu.position, isSelected: true })]
            }
        })
    }

    const zoneFotoAdded = (zu: Partial<ZoneUrtoType>, file: FileItemType | undefined) => {
        setZoneUrto((zones: ZoneUrtoType[]) => {
            return zones.map((z: ZoneUrtoType) => {
                if (z.position === zu.position) {
                    return new ZoneUrtoType({ ...z, file: file })
                } else {
                    return z
                }
            })
        })
    }

    const removeZoneHandler = (zu: ZoneUrtoType) => {
        setZoneUrto((zones: ZoneUrtoType[]) => {
            return zones.filter(z => z.position !== zu.position)
        })
    }

    return (
        <>
            <CarUrtoContainer>
                <UrtoBkImg src={CarUrtoBkImg} alt="Disegno macchina" />
                <UrtoTable>
                    {allPossibleZoneUrto.map((zu: Partial<ZoneUrtoType>) => {
                        const zoneItem = zoneUrto.find(z => z.position === zu.position)
                        const currZoneItem = zoneItem || zu
                        return (
                            <UrtoTableCellCmp
                                preFileType={"urto-"}
                                key={currZoneItem.position}
                                zoneItem={currZoneItem}
                                zoneItemSelected={() => zoneItemSelectedHandler(currZoneItem)}
                                zoneFotoAdded={(file: FileItemType) => zoneFotoAdded(currZoneItem, file)}
                                zoneFotoRemove={() => zoneFotoAdded(currZoneItem, undefined)}
                                removeZone={() => removeZoneHandler(currZoneItem)} />
                        )
                    }
                    )}

                </UrtoTable>
            </CarUrtoContainer>
        </>
    )
}

export default ZoneUrtoCmp