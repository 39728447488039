import { faComments } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate, useParams } from "react-router-dom"
import { PATHS } from "../../routes/CaiExperienceRouter"
import { ModalContentProps } from "../../types/commonTypes"
import { composeUrl } from "../../utils/utils"
import { SecondChoiseButton } from "../styled/Button.style"
import { Section } from "../styled/Containers.style"
import { IconContainer } from "../styled/Icon.style"
import { ModalConfirmButton } from "../styled/Modal.style"
import { Vspace } from "../styled/Separators.style"
import { TextDefaultSmall } from "../styled/Text.style"

export const UnknownPlateModal = ({ close }: ModalContentProps<null>) => {
    const { token } = useParams();
    const navigate = useNavigate();
    
    /* istanbul ignore next */
    const endProcessHandler = () => {
        const _pathname = composeUrl(token, PATHS.PROCEDURE_CANCELLED)
        navigate(_pathname)
    }

    return (
        <>
            <Section>
                <IconContainer>
                    <FontAwesomeIcon icon={faComments} />
                </IconContainer>
            </Section>
            <Section>
                <TextDefaultSmall>Ti è possibile recuperare la targa della controparte?</TextDefaultSmall>
            </Section>

            <Section>
                <TextDefaultSmall>Senza questa informazione ti ricordiamo che la pratica verrà avviata in ogni caso ma <strong>questa procedura verrà interrotta.</strong></TextDefaultSmall>
            </Section>

            <Vspace qty={2}></Vspace>

            <Section>
                <ModalConfirmButton onClick={close}>HO OTTENUTO I DATI MANCANTI</ModalConfirmButton>
                <Vspace></Vspace>
                <SecondChoiseButton
                    onClick={endProcessHandler}
                >Non posso recuperare altre informazioni</SecondChoiseButton>
            </Section>

        </>
    )
}
