import { BreadcrumbStepItem } from "../styled/Breadcrumb.style";

interface BreadcrumbStepItemCmpProps {
    activeIndex: number;
    label: number; 
}

export const BreadcrumbStepItemCmp = (props: BreadcrumbStepItemCmpProps) => {
  const {activeIndex, label} = props
    const activeClass = (activeIndex === label) ? 'active' : ''

  return (
        <BreadcrumbStepItem className={activeClass}><span>{label}</span></BreadcrumbStepItem>
  )
}
