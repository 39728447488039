import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { mapBaremeIdLabel } from "../../utils/bareme.utils";
import { RealtimeModal } from "../modal/RealtimeModal";
import { BaremeLabel, BaremeValueContainer } from "../styled/Bareme.style";
import { Label } from "../styled/Input.style";
import { BaremeSelectionModal } from "./BaremeSelectionModal";

interface BaremeSelectorCmpProps {
  value: string | undefined;
  OnChange: Function;
  labelTitle?: string;
}


const BaremeSelectorCmp = (props: BaremeSelectorCmpProps) => {
  const { labelTitle, value, OnChange } = props
  const [openBaremeSelectionModal, setOpenBaremeSelectionModal] = useState(false)
  const baremeIdLabel = value && mapBaremeIdLabel[value]

  const openBaremeModal = () => {
    setOpenBaremeSelectionModal(true)
  }

  const baremeSelectedHandler = (baremeId: string | undefined) => {
    OnChange(baremeId)
  }

  return (
    <>
      <RealtimeModal
        modalOpened={openBaremeSelectionModal}
        hTitle="Circostanza incidente"
        hCloseButton={true}
        onCloseHandler={() => setOpenBaremeSelectionModal(false)}
        onConfirmHandler={baremeSelectedHandler}
        bodyClassName="with-container">
        <BaremeSelectionModal initValue={value}/>
      </RealtimeModal>

      {labelTitle && <Label>{labelTitle}</Label>}
      <BaremeValueContainer
      onClick={openBaremeModal}>
        <BaremeLabel>{baremeIdLabel || 'Seleziona circostanza adatta'}</BaremeLabel>
        <FontAwesomeIcon
          icon={faAngleDown} />
      </BaremeValueContainer>
    </>
  )
}

export default BaremeSelectorCmp