import { ButtonHTMLAttributes } from 'react'
import styled from 'styled-components'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
}

export const Button = styled.button<ButtonProps>`
    cursor: pointer;
    position: relative;
    outline: none;
    border: none;
    text-align: center;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #003b5c;
    color: #fff;
    border-radius: 0px;
    text-transform: uppercase;
    font-family: "Medium";
    font-size: 17px;
    height: 49px;

    &:disabled {
        cursor: not-allowed;
        pointer-events: none;
        background-color: #cecece;
    }

    &.right-element {
        position: absolute;
        right: 0;
        top: 0;
        width: 42px;
        height:100%;
        background-color: transparent;
        z-index: 2;

        &.sm {
            height: 50px;
        }
    }
`

export const PaddedButton = styled(Button)`
    padding-left: 2.5rem;
    padding-right: 2.5rem;
`

export const PageActionButton = styled(PaddedButton)`
    min-width: 130px;
`

export const CaiButton = styled(PaddedButton) `
    font-family: "Bold";
    font-size: 13px;
    text-align: center;
    background-color: #fff;
    color: #3079c9;
    border: 1px solid #3079c9;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: 37px;
    min-height: 37px;
`

export const NextButton = styled(PageActionButton)`
`

export const ShareButton = styled(Button)`
    position: absolute;
    bottom: 0;
    right: 2rem;
    background-color: #3079c9;
    height: 60px;
    width: 60px;
    transform: rotate(45deg);

    svg {
        font-size: 30px;
        transform: rotate(-45deg);
    }
`

export const BackButton = styled(Button)`
    position: relative;
    width: 50px;
    height: 52px;
    background-color: transparent;
    border-right: 1px solid #cecece;

    svg {
        z-index: 101;
        font-size: 20px;
        color: #0f3250;
    }
`

export const CloseButton = styled(Button)`
    position: absolute;
    top: 8px;
    right: 0px;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: 0px;

    svg {
        z-index: 101;
        font-size: 20px;
        color: #0f3250;
    }
`


export const SecondChoiseButton = styled.button`
    cursor: pointer;
    font-family: "Medium";
    color: #535353;
    font-size: 14px;
    position: relative;
    outline: none;
    border: none;
    text-align: center;
    padding-bottom: 0.05rem;
    background: none;
    line-height: 18px;
    /*&::after {
    content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #535353;
    }*/
    text-decoration: underline;
    display: inline-block;
`

export const TransparentButton = styled(Button)`
    background-color: transparent;
    color: #003b5c;
`

export const TransparentButtonB = styled(TransparentButton)`
    color: #3079c9;
`
