import { useEffect, useReducer, useState } from "react"
import { booleanWithDnRadioOptions, BoolStringEnum, getValueLabelOptions, OtherContactCaiInfoCmpProps, ValueLabelOption } from "../../types/commonTypes"
import { OtherContactInfo } from "../../types/communication/communicationDataType"
import { genericStateReducer } from "../contact/genericStateReducer"
import { RealtimeModal } from "../modal/RealtimeModal"
import RadioOptionsCmp from "../radio/RadioOptionsCmp"
import { CaiButton } from "../styled/Button.style"
import { CaiSection } from "../styled/Containers.style"
import OtherContactModalCmp from "./OtherContactModalCmp"
import { tqPageView } from "../../utils/utils"

export const FeritiStateAction = {
    UPDATE_STATE: '@@feriti/UPDATE_STATE'
}

const OtherContactCmp = (props: OtherContactCaiInfoCmpProps<OtherContactInfo>) => {
    const { initValues, onChange, questionLabel, contactListPropItems, contactLabel } = props
    const [otherPeopleState, otherPeopleDispatch] = useReducer(genericStateReducer<OtherContactInfo>(FeritiStateAction), initValues);

    const [otherPeopleModalOpened, setOtherPeopleModalOpened] = useState<boolean>(false)
    useEffect(() => {
        onChange && onChange(otherPeopleState)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otherPeopleState])

    const otherContactModalConfirm = (retData: Partial<OtherContactInfo>) => {
        otherPeopleDispatch({
            type: FeritiStateAction.UPDATE_STATE,
            payload: { ...retData }
        })
    }

    let customTextButton: string | JSX.Element = contactListPropItems.some(e => e.propName === 'targa') ? "+ Aggiungi informazioni *" : "+ Aggiungi informazioni";
    customTextButton = initValues?.contactList.length ? <span>&#10003;  Verifica Dettagli</span> : customTextButton;

    return (
        <>
            <CaiSection className="cai-section">
                <RadioOptionsCmp
                    horizontal
                    otpionsLabel={questionLabel || 'Altri contatti presenti?'}
                    options={booleanWithDnRadioOptions}
                    value={getValueLabelOptions(booleanWithDnRadioOptions, otherPeopleState.isPresent)}
                    className={'cai anche-other-contact'}
                    onChange={(newChoise: ValueLabelOption<BoolStringEnum>) =>
                        otherPeopleDispatch({ type: FeritiStateAction.UPDATE_STATE, payload: { isPresent: newChoise.value } })}
                />

                {otherPeopleState?.isPresent === BoolStringEnum.Y && <CaiButton
                    onClick={() => setOtherPeopleModalOpened(true)}
                >{customTextButton}</CaiButton>}

                <RealtimeModal
                    modalOpened={otherPeopleModalOpened}
                    hTitle="Aggiungi informazioni"
                    hCloseButton={true}
                    onConfirmHandler={otherContactModalConfirm}
                    onCloseHandler={() => setOtherPeopleModalOpened(false)}
                    onOpenHandler={() => {
                        // Analytics
                        const analyticsContactLabel = contactLabel?.toLocaleLowerCase() || 'altri_contatti'
                        tqPageView(`cai:cai:aggiungi informazioni:${analyticsContactLabel}`)
                    }}>
                    <OtherContactModalCmp 
                        initValues={initValues?.contactList}
                        contactListPropItems={contactListPropItems}
                        contactLabel={contactLabel}
                        />
                </RealtimeModal>
            </CaiSection>
        </>
    )
}

export default OtherContactCmp