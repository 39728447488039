import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { selectCommunicationData } from "../store";
import { loadCommunicationAct, captchaChallengeAct, executePollingAct } from "../store/communication-data/actions";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import SplashScreenPage from "./SplashScreenPage";

export default function AppWrapper() {
  const [pollingInterval, setPollingInterval] = useState(null);
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  let { token } = useParams();
  const communicationData = useAppSelector(selectCommunicationData)
  const { communication } = communicationData

  const communicationLoaded = !!communication?.token
  const disappearClass = communicationLoaded ? 'make-disappear' : ''

  useEffect(() => {
    // call backend for polling
    const _pollingInterval: any = setInterval(() => {
      dispatch(executePollingAct({
        token,
        navigate
      }))
    }, window.REACT_APP_POLLING_INTERVAL_IN_MS);
    setPollingInterval(_pollingInterval);

    return () => {
      pollingInterval && clearInterval(pollingInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(loadCommunicationAct({
      token,
      navigate
    }));
    dispatch(captchaChallengeAct(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* all the other elements */}
      <div id="realtime-app-wrapper">
        {communicationLoaded && <Outlet />}
        {<SplashScreenPage className={disappearClass} />}
      </div>
    </>
  );
}
