import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate, useParams } from 'react-router-dom'
import { composeUrl, getIsInAppClass } from '../../utils/utils'
import { BreadcrumbContainer, BreadcrumbStepsContainer, BreadcrumbTitle } from '../styled/Breadcrumb.style'
import { BackButton } from '../styled/Button.style'
import { BreadcrumbStepItemCmp } from './BreadcrumbStepItem'
import { RealtimeBreadcrumbTitles } from '../../types/commonTypes'

interface BreadcrumbProps {
  backPath: string;
  backHandler?: Function;
  breadcrumbIndex: number;
  breadcrumbTitles?: string[]
  backBaseRoute?: string;
}

export const Breadcrumb = (props: BreadcrumbProps) => {
  const { backPath, breadcrumbIndex, backHandler, breadcrumbTitles, backBaseRoute } = props
  let navigate = useNavigate();
  let { token } = useParams();
  const _bTitles = breadcrumbTitles || RealtimeBreadcrumbTitles
  const title = _bTitles[breadcrumbIndex - 1];

  const goBackHandler = () => {
    /* istanbul ignore next */
    backHandler && backHandler()
    navigate(composeUrl(token, backPath, backBaseRoute));
  }

  const isInAppClass = getIsInAppClass()

  return (
    <BreadcrumbContainer className={isInAppClass}>
      <BackButton onClick={goBackHandler}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </BackButton>
      <BreadcrumbStepsContainer>
        {_bTitles.map((bt: any, index: number) => <BreadcrumbStepItemCmp
          activeIndex={breadcrumbIndex}
          label={index + 1}
          key={index} />)}
        {title && <BreadcrumbTitle activeIndex={breadcrumbIndex}>{title}</BreadcrumbTitle>}
      </BreadcrumbStepsContainer>
    </BreadcrumbContainer>
  )
}
